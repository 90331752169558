import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomCheckbox from '../../CustomCheckbox'; // Adjust the import path as necessary

const CandidateEnglishFluency = ({ formData, handleChange }) => {
  const [checkedItems, setCheckedItems] = useState({
    basicEnglish: false,
    goodEnglish: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          English Fluency
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Basic English"
              checked={checkedItems.basicEnglish}
              onChange={handleCheckboxChange}
              name="basicEnglish"
              extraText="(Basic proficiency)"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Good English"
              checked={checkedItems.goodEnglish}
              onChange={handleCheckboxChange}
              name="goodEnglish"
              extraText="(Good proficiency)"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateEnglishFluency;
