import React, { useState} from 'react';
import { Link } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Grid, Divider, IconButton, useMediaQuery } from '@mui/material';
import { Star, LocationOn, BusinessCenter, BookmarkBorder, Bookmark } from '@mui/icons-material';
import companieslogo from '../../../../assets/icons/logos/vacanzi_30*40.png';
import baseURL from '../../../../api/baseUrl';
import CustomSnackbar from '../../../AdvancedComponents/CustomSnackbar';
import { jwtDecode } from 'jwt-decode'; // Import jwtDecode if it's not already imported

const JobListComponent = ({ jobs,onJobUpdate }) => {
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  const [consoledToken, setConsoledToken] = useState();

  const isMobile = useMediaQuery('(max-width:600px)');
  

  const handleBookmark = async (jobId, isSaved) => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) throw new Error('User not authenticated');

      const decodedToken = jwtDecode(authToken);
      const userId = decodedToken.id;

      setConsoledToken(decodedToken);

      let response;

      if (isSaved) {
        // Remove bookmark (delete saved job)
        response = await fetch(`${baseURL}/jobs/savedJobs/${userId}/${jobId}`, {
          method: 'DELETE',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to delete saved job');
        setSnackbarMessage('Job Unsaved Succesfully');
      } else {
        // Add bookmark (save job)
        response = await fetch(`${baseURL}/jobs/${jobId}/save`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${authToken}`,
            'Content-Type': 'application/json',
          },
        });
        if (!response.ok) throw new Error('Failed to bookmark the job');

        const data = await response.json();
        setSnackbarMessage(data.message);
        setConsoledToken(decodedToken);

      }

      // Update the job list to reflect the change immediately
      if (onJobUpdate) onJobUpdate(jobId, !isSaved);

      setSnackbarType('success');
      setSnackbarOpen(true);
    } catch (error) {
      setSnackbarMessage(`Error: ${error.message}`);
      setSnackbarType('error');
      setSnackbarOpen(true);
      
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const truncateText = (text, maxLength) => {
    if (text?.length > maxLength) {
      return `${text.substring(0, maxLength)}...`;
    }
    return text;
  };

  if (!jobs?.length) return <Typography variant="h6">No jobs available</Typography>;

  console.log('consoledToken',consoledToken);
  

  return (
    <Box sx={{ p: 2 }}>
      {jobs.map((job) => (
        <Card key={job._id} sx={{ mb: 2 }}>
          <CardContent>
            <Grid container spacing={isMobile ? 1 : 2}>
              <Grid item xs={12} sm={12}>
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="flex-start">
                  <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                    <Typography sx={{ fontWeight: 'bold' }}>
                      <Link to={`/JobSeekerFilter/job/${job._id}`} style={{ textDecoration: 'none', color: 'inherit' }}>
                        {job.jobTitle}
                      </Link>
                    </Typography>
                    <Box display="flex" alignItems="center" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                      <Typography variant="subtitle2" gutterBottom>
                        {job.hiringFor}
                      </Typography>
                      <Star sx={{ ml: 1, mr: 0.5, fontSize: '15px' }} color="warning" />
                      <Typography variant="body2" color="textSecondary">
                        {/* Add rating and reviews if available */}
                      </Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                    <img height={isMobile ? 30 : 40} width={isMobile ? 30 : 40} src={companieslogo} alt="company logo" />
                  </Box>
                </Box>
                <Divider sx={{ my: isMobile ? 1 : 2 }} />
                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                  <BusinessCenter sx={{ mr: 0.5, color: 'grey' }} />
                  <Typography variant="body2" color="textSecondary">
                    {job.experienceType} ({job.minExperience} - {job.totalExpRequired})
                  </Typography>
                  <Typography variant="body2" color="textSecondary" sx={{ ml: isMobile ? 0 : 2 }}>
                    | ₹ {job.minFixedSalary} - {job.maxFixedSalary}
                  </Typography>
                  <LocationOn sx={{ ml: isMobile ? 1 : 2, mr: 0.5, color: 'grey' }} />
                  <Typography variant="body2" color="textSecondary">
                    {job.jobCity}
                  </Typography>
                </Box>
                <Typography variant="subtitle2" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                  {truncateText(job.jobDescription, 80)}
                </Typography>
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                  {job.skillPreferences.map((skill, index) => (
                    <Typography key={index} variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
                      {skill}{index < job.skillPreferences?.length - 1 && ' · '}
                    </Typography>
                  ))}
                </Box>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Typography variant="body2" color="textSecondary">
                    {new Date(job.postedDate).toDateString()}
                  </Typography>
                  <IconButton onClick={() => handleBookmark(job._id, job.isSaved)}>
                    {job.isSaved ? <Bookmark color="warning" /> : <BookmarkBorder />}
                  </IconButton>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      ))}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        handleClose={handleCloseSnackbar}
      />
    </Box>
  );
};

export default JobListComponent;
