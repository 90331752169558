import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomCheckbox from '../../CustomCheckbox'; // Adjust the import path as necessary

const CandidateShow = ({ formData, handleChange }) => {
  const [checkedItems, setCheckedItems] = useState({
    cvAttached: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Show only candidates who
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <CustomCheckbox
              label="CV attached"
              checked={checkedItems.cvAttached}
              onChange={handleCheckboxChange}
              name="cvAttached"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateShow;
