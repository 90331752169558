import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomMultiSelect from '../../CustomMultiSelect';

const options = [
  'Engineering',
  'Design',
  'Marketing',
  'Sales',
  'Finance',
  'Human Resources',
  'Product Management',
  'Customer Support',
  'Operations',
  'Legal',
];

const CandidateIndustries = () => {
  const [selectedIndustries, setSelectedIndustries] = useState([]);

  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Industries</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomMultiSelect
          placeholder="Enter keywords such as job title, skills etc"
          options={options}
          selectedOptions={selectedIndustries}
          setSelectedOptions={setSelectedIndustries}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateIndustries;
