import React, { useState, useEffect } from 'react';
import { Typography, IconButton, Box, Paper, Button, Menu, MenuItem, Container, Grid } from '@mui/material';
import EmployerDrawer from '../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import SaveIcon from '@mui/icons-material/Save';
import { useNavigate, useLocation } from 'react-router-dom';
import CandidateProfileCard from '../../../components/AdvancedComponents/CandidateSearchLists/CandidateProfileCard';
import CandidateSearchFilters from '../../../components/AdvancedComponents/CandidateSearchLists/CandidateSearchFilter';
import CustomPagination from '../../../components/AdvancedComponents/CustomPagination';
import baseURL from '../../../api/baseUrl';



const CandidateSearchList = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [profiles, setProfiles] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);
    const totalPages = Math.ceil(profiles.length / itemsPerPage);

    const query = new URLSearchParams(location.search);
    const experienceType = query.get('experienceType') || 'Both';
    const keywords = query.get('keywords') || '';
    const currentCity = query.get('currentCity') ? query.get('currentCity').split(',') : [];
    const minExperience = query.get('minExperience') || '';
    const maxExperience = query.get('maxExperience') || '';
    const minSalary = query.get('minSalary') || '';
    const maxSalary = query.get('maxSalary') || '';

    const [formData, setFormData] = useState({
        minimumEducation: '',
        activeIn: '',
        minExperience: minExperience,
        maxExperience: maxExperience,
        minSalary: minSalary,
        maxSalary: maxSalary,
        currentCity: currentCity,
    });

    useEffect(() => {
        const fetchProfiles = async () => {
            try {
                const response = await fetch(`${baseURL}/search?experienceType=${experienceType}&keywords=${keywords}&currentCity=${formData.currentCity.join(',')}&minExperience=${formData.minExperience}&maxExperience=${formData.maxExperience}&minSalary=${formData.minSalary}&maxSalary=${formData.maxSalary}`);
                const data = await response.json();
                const transformedProfiles = data?.jobseekers?.map(profile => ({
                    id: profile._id,
                    name: profile.user.fullName,
                    phone: profile.user.phone,
                    email: profile.user.email,
                    avatar: '/path/to/default/avatar.jpg',
                    experience: profile.totalExp,
                    location: profile.appliedJobLocation,
                    workExperience: [
                        {
                            position: profile.currentDesignation,
                            company: profile.currentCompany,
                            duration: profile.uploadDate ? new Date(profile.uploadDate).toLocaleDateString() : 'N/A',
                        },
                    ],
                    industries: [],
                    departments: [],
                    education: [],
                    skills: profile.skills || [],
                    preferredLocations: [profile.appliedJobLocation],
                    candidatePreference: '',
                    languages: [],
                    isSelected: false,
                }));
                setProfiles(transformedProfiles);
            } catch (error) {
                console.error('Error fetching profiles:', error);
            }
        };
        fetchProfiles();
    }, [experienceType, keywords, formData]);

    const handleBack = () => {
        navigate(-1);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleViewDetails = (id) => {
        navigate(`/candidate-profile/${id}`);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleItemsPerPageChange = (items) => {
        setItemsPerPage(items);
        setCurrentPage(1);
    };

    const open = Boolean(anchorEl);

    const profilesToShow = profiles.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
    );

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
    };

    return (
        <EmployerDrawer>
            <Container>
                <Paper sx={{ padding: 2, backgroundColor: '#ffffff', boxShadow: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                            <IconButton onClick={handleBack}>
                                <ArrowBackIcon />
                            </IconButton>
                            <Typography variant="body2">
                                <b>{profiles.length}</b> profiles found for <b>{experienceType}</b>

                                <Typography
                                    component="span"
                                    sx={{ color: 'green', fontWeight: 'bold', marginLeft: 1, display: 'inline' }}
                                >
                                    View details
                                </Typography>
                                <IconButton onClick={handleClick} sx={{ ml: 1 }}>
                                    <ArrowDropDownIcon />
                                </IconButton>
                            </Typography>
                        </Box>
                        <Box sx={{ display: 'flex', gap: 2 }}>
                            <Typography
                                component="span"
                                sx={{ color: 'green', fontWeight: 'bold', display: 'inline', mt: 1 }}
                            >
                                Modify search
                            </Typography>
                            <Button
                                startIcon={<SaveIcon />}
                                sx={{ color: 'black', border: '1px solid black', fontWeight: 'bold', textTransform: 'none' }}
                            >
                                Save search
                            </Button>
                        </Box>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem onClick={handleClose}>Show results for: <b>{experienceType}</b></MenuItem>
                        <MenuItem onClick={handleClose}>Keywords: <b>{keywords}</b></MenuItem>
                        <MenuItem onClick={handleClose}>Locations: <b>{formData.currentCity}</b></MenuItem>
                        <MenuItem onClick={handleClose}>Min. Experience: <b>{formData.minExperience}</b></MenuItem>
                        <MenuItem onClick={handleClose}>Max. Experience: <b>{formData.maxExperience}</b></MenuItem>
                        <MenuItem onClick={handleClose}>Min. Salary: <b>{formData.minSalary}</b></MenuItem>
                        <MenuItem onClick={handleClose}>Max. Salary: <b>{formData.maxSalary}</b></MenuItem>
                    </Menu>
                </Paper>
                <Grid container spacing={2}>
                    <Grid item xs={12} md={4}>
                        <Typography variant="h6" fontWeight='bold' sx={{ padding: 3 }}>Filters(2)</Typography>
                        <CandidateSearchFilters formData={formData} handleChange={handleChange} setFormData={setFormData} />
                    </Grid>
                    <Grid item xs={12} md={8}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: 2 }}>
                            <Typography component="legend" sx={{ fontWeight: 'bold', fontSize: '20px', display: 'flex', gap: 2, alignItems: 'center' }}>
                                {profiles.length} candidates

                            </Typography>

                            <Box sx={{ display: 'flex', gap: 1, alignItems: 'flex-end' }}>
                                <CustomPagination
                                    currentPage={currentPage}
                                    totalPages={totalPages}
                                    onPageChange={handlePageChange}
                                    itemsPerPage={itemsPerPage}
                                    onItemsPerPageChange={handleItemsPerPageChange}
                                />
                            </Box>
                        </Box>
                        {profilesToShow.map(profile => (
                            <React.Fragment key={profile.id}>
                                <CandidateProfileCard profile={profile} onViewDetails={handleViewDetails} />
                            </React.Fragment>
                        ))}
                    </Grid>
                </Grid>
            </Container>
        </EmployerDrawer>
    );
};

export default CandidateSearchList;
