import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import JobDetailComponent from '../../../components/JobSeekerComponents/JobSeekerFilters/JobDetailsComponent/JobDetailComponent';
import baseURL from '../../../api/baseUrl';

const JobSeekerJobDetails = () => {
  const { id } = useParams();
  const [job, setJob] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchJob = async () => {
      const token = localStorage.getItem('authToken'); // Retrieve the token from localStorage

      try {
        const response = await fetch(`${baseURL}/jobs/${id}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}` 
          }
        });
        
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const data = await response.json();
        setJob(data.job);
      } catch (error) {
        console.error('Error fetching job:', error);
        setError(error.message); // Handle the error and set an error message
      }
    };

    fetchJob();
  }, [id]);

  return (
    <div>
      {error && <p>Error: {error}</p>} {/* Display error message if there is an error */}
      {job ? <JobDetailComponent job={job} /> : <p>Job not found</p>}
    </div>
  );
};

export default JobSeekerJobDetails;
