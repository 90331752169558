import React from 'react';
import { Button, Grid, Typography } from '@mui/material';

const JobSeekerCaseOne = ({ formData, handleExperienceChange }) => {


  return (
    <Grid container spacing={2} sx={{ marginTop: 1, backgroundColor: '#ffffff', padding: 2, borderRadius: 4 }}>
      <Grid item xs={12}>
        <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
          Do you have work experience?
        </Typography>
        <Grid container justifyContent="left" spacing={2}>
          <Grid item>
            <Button
              variant={formData.hasWorkExperience === true ? 'contained' : 'outlined'}
              onClick={() => handleExperienceChange(true)}
              sx={{
                borderRadius: 6,
                backgroundColor: formData.hasWorkExperience === true ? '#423BFA' : 'transparent',
                border: '1px solid #ccc',
                color: formData.hasWorkExperience === true ? '#fff' : '#000',
              }}
            >
              <Typography fontWeight="bold" fontSize="12px">Yes</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={formData.hasWorkExperience === false ? 'contained' : 'outlined'}
              onClick={() => handleExperienceChange(false)}
              sx={{
                borderRadius: 6,
                backgroundColor: formData.hasWorkExperience === false ? '#423BFA' : 'transparent',
                border: '1px solid #ccc',
                color: formData.hasWorkExperience === false ? '#fff' : '#000',
              }}
            >
              <Typography fontWeight="bold" fontSize="12px">No</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default JobSeekerCaseOne;
