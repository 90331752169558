import React, { useState } from 'react';
import { Drawer, Box, Typography, Avatar, IconButton, Button, Chip, Stack, Divider } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PhoneIcon from '@mui/icons-material/Phone';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import LanguageIcon from '@mui/icons-material/Language';
import DescriptionIcon from '@mui/icons-material/Description';
import PlaceIcon from '@mui/icons-material/Place';
import CopyAll from '@mui/icons-material/CopyAll';
import CopyToClipboard from 'react-copy-to-clipboard';
import avatarmen from '../../../assets/images/avatarmen.jpg';

const CandidateProfileDrawer = ({ profile, open, onClose }) => {
  const [showContactDetails, setShowContactDetails] = useState(false);

  const handleViewContactDetails = () => {
    setShowContactDetails(!showContactDetails);
  };

  const handleCopy = (message) => {
    alert(message);
  };

  return (
    <Drawer anchor="right" open={open} onClose={onClose}>
      <Box
        sx={{
          width: 800,
          p: 15,
          background: 'linear-gradient(135deg, #e0e0e0 30%, #ffffff 90%)',
          borderRadius: '10px',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
        }}
      >
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box display="flex" alignItems="center">
            <Avatar src={avatarmen} sx={{ mr: 2, width: 80, height: 80, boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }} />
            <Box>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>{profile.name}</Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>{profile.experience}</Typography>
              <Typography variant="body1" sx={{ color: 'text.secondary' }}>{profile.location}</Typography>
            </Box>
          </Box>
          <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box mt={2}>
          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <WorkIcon sx={{ mr: 1 }} /> Work Experience
          </Typography>
          {profile.workExperience.map((exp, index) => (
            <Typography key={index} variant="body2" component="div" sx={{ mb: 1 }}>
              {exp.position} at {exp.company} ({exp.duration})
            </Typography>
          ))}
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <DescriptionIcon sx={{ mr: 1 }} /> Industries
          </Typography>
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mt: 1 }}>
            {profile.industries.map((industry, index) => (
              <Chip key={index} label={industry} sx={{ mb: 1 }} />
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <DescriptionIcon sx={{ mr: 1 }} /> Departments
          </Typography>
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mt: 1 }}>
            {profile.departments.map((department, index) => (
              <Chip key={index} label={department} sx={{ mb: 1 }} />
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <SchoolIcon sx={{ mr: 1 }} /> Education
          </Typography>
          {profile.education.map((edu, index) => (
            <Typography key={index} variant="body2" component="div" sx={{ mb: 1 }}>
              {edu.degree} from {edu.institution}
            </Typography>
          ))}
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <LanguageIcon sx={{ mr: 1 }} /> Skills
          </Typography>
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mt: 1 }}>
            {profile.skills.map((skill, index) => (
              <Chip key={index} label={skill} sx={{ mb: 1 }} />
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <PlaceIcon sx={{ mr: 1 }} /> Preferred Locations
          </Typography>
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mt: 1 }}>
            {profile.preferredLocations.map((location, index) => (
              <Chip key={index} label={location} sx={{ mb: 1 }} />
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <PhoneIcon sx={{ mr: 1 }} /> Contact Details
          </Typography>
          {showContactDetails && (
            <Box sx={{ mt: 2 }}>
              <Typography sx={{ color: 'green', fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
                {profile.phone}
                <CopyToClipboard text={profile.phone} onCopy={() => handleCopy('Phone number copied')}>
                  <IconButton sx={{ ml: 1 }}>
                    <CopyAll />
                  </IconButton>
                </CopyToClipboard>
              </Typography>
              <Typography sx={{ color: 'green', fontWeight: 'bold', display: 'flex', alignItems: 'center', mt: 1 }}>
                {profile.email}
                <CopyToClipboard text={profile.email} onCopy={() => handleCopy('Email id copied')}>
                  <IconButton sx={{ ml: 1 }}>
                    <CopyAll />
                  </IconButton>
                </CopyToClipboard>
              </Typography>
            </Box>
          )}
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <LanguageIcon sx={{ mr: 1 }} /> Languages
          </Typography>
          <Stack direction="row" spacing={1} sx={{ flexWrap: 'wrap', mt: 1 }}>
            {profile.languages.map((language, index) => (
              <Chip key={index} label={language} sx={{ mb: 1 }} />
            ))}
          </Stack>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <DescriptionIcon sx={{ mr: 1 }} /> Assets & Documents
          </Typography>
          <Typography variant="body2" sx={{ mb: 2 }}>{profile.assetsAndDocuments}</Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="h6" sx={{ fontWeight: 'bold', display: 'flex', alignItems: 'center', mb: 2 }}>
            <DescriptionIcon sx={{ mr: 1 }} /> Personal Details
          </Typography>
          <Typography variant="body2">Gender: {profile.gender}</Typography>
          <Typography variant="body2">Age: {profile.age}</Typography>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="space-between" mt={4}>
          <Button
            variant="contained"
            color="primary"
            sx={{ mt: 2, px: 4, boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)', borderRadius: '20px', background: 'green' }}
            startIcon={<PhoneIcon />}
            onClick={handleViewContactDetails}
          >
            {showContactDetails ? 'Hide Contact Details' : 'View Contact Details'}
          </Button>
          <IconButton onClick={onClose} sx={{ color: 'text.secondary' }}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
    </Drawer>
  );
};

export default CandidateProfileDrawer;
