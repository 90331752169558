import React from 'react';
import { Box, Grid, Typography, FormControlLabel, Checkbox, Divider, IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';

const EmployerVerifyDetails = ({ formData, handleEditCompanyDetails, handleChange }) => {
    return (
        <>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                    Verify Account Details
                </Typography>
            </Box>
            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Username:</strong> {formData.name}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Email:</strong> {formData.email}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Phone Number:</strong> {formData.contactNumber}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Role:</strong> {formData.role}
                    </Typography>
                </Grid>
            </Grid>

            <Divider sx={{ my: 2 }} />

            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }} gutterBottom>
                    Company Details
                </Typography>
                <IconButton onClick={handleEditCompanyDetails}>
                    <EditIcon />
                </IconButton>
            </Box>
            <Divider sx={{ my: 2 }} />

            <Grid container spacing={2}>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Company Name:</strong> {formData.organizationName}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Industry Type:</strong> {formData.industryType}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Contact Person:</strong> {formData.contactPersonName}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Contact Number:</strong> {formData.contactPersonNumber}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Company Address:</strong> {formData.organizationAddress}
                    </Typography>
                </Grid>
                <Grid item xs={12} md={6}>
                    <Typography variant="body1">
                        <strong>Pincode:</strong> {formData.orgPinCode}
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formData.receiveUpdates}
                                onChange={handleChange}
                                name="receiveUpdates"
                            />
                        }
                        label={
                            <Typography variant="body1">
                                <strong>I agree to receive important updates by Whatsapp, SMS, email or other services.</strong>
                            </Typography>
                        }
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default EmployerVerifyDetails;
