import React from 'react';
import { Autocomplete, TextField, Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const cities = [ 'Mumbai',
  'Delhi',
  'Bangalore',
  'Hyderabad',
  'Chennai',
  'Kolkata',
  'Pune',
  'Ahmedabad',
  'Jaipur',
  'Chandigarh',
  'Gurgaon',
  'Noida',
  'Kochi',
  'Kolkata',
  'Surat',
  'Vadodara',
  'Indore',
  'Bhopal',
  'Lucknow',
  'Nagpur',
  'Patna',];

const CandidateCity = ({ formData, setFormData }) => {
  const handleCityChange = (event, value) => {
    setFormData(prevFormData => ({
      ...prevFormData,
      currentCity: value,
    }));
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Current City/Area</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Autocomplete
          multiple
          options={cities}
          value={formData.currentCity}
          onChange={handleCityChange}
          renderInput={(params) => <TextField {...params} placeholder="Select City" variant="outlined" />}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateCity;
