import React from 'react';
import { Box, Avatar, Typography, IconButton, Container } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import EmailIcon from '@mui/icons-material/Email';
import WorkIcon from '@mui/icons-material/Work';
import LocationOnIcon from '@mui/icons-material/LocationOn';

const UpdateJSProfile = ({ toggleDrawer, profileData }) => {
  const user = profileData?.user || {};
  const experiences = profileData?.experiences || [];
  const currentExperience = experiences[0] || {};

  return (
    <Container sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', p: { xs: 2, md: 3 } }}>
      <Box
        sx={{
          position: 'relative',
          width: { xs: '100%', sm: 300, md: 300 },
          bgcolor: 'background.paper',
          borderRadius: 2,
          boxShadow: 3,
          textAlign: 'center',
          p: 3,
          mb: 2,
        }}
      >
        <IconButton
          onClick={() => toggleDrawer(true)}
          sx={{
            position: 'absolute',
            top: 8,
            right: 8,
            bgcolor: 'background.paper',
            borderRadius: '50%',
            boxShadow: 2,
          }}
        >
          <EditIcon />
        </IconButton>
        <Avatar sx={{ width: 80, height: 80, margin: 'auto' }}>
          {user.fullName ? user.fullName.charAt(0).toUpperCase() : 'P'}
        </Avatar>
        <Typography variant="h6" sx={{ mt: 2 }}>
          {user.fullName || 'Full Name'}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {user.phone || 'Phone Number'}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
          <EmailIcon sx={{ mr: 1 }} />
          <Typography>{user.email || 'Email Address'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <WorkIcon sx={{ mr: 1 }} />
          <Typography>{currentExperience.designation || 'Designation'}</Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
          <LocationOnIcon sx={{ mr: 1 }} />
          <Typography>{currentExperience.location || 'Bengaluru'}</Typography>
        </Box>
      </Box>
    </Container>
  );
};

export default UpdateJSProfile;
