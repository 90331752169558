import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomMultiSelect from '../../CustomMultiSelect';

// Define options for languages
const languageOptions = [
  'English',
  'Hindi',
  'Mandarin',
  'Spanish',
  'French',
  'German',
  'Japanese',
  'Korean',
  'Russian',
  'Portuguese',
  'Arabic',
  'Bengali',
  'Turkish',
  'Italian',
  'Dutch',
  'Swedish',
  'Danish',
  'Norwegian',
  'Finnish',
  'Polish',
  'Hebrew',
];

const CandidateLanguages = () => {
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Languages</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomMultiSelect
          placeholder="Select languages"
          options={languageOptions}
          selectedOptions={selectedLanguages}
          setSelectedOptions={setSelectedLanguages}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateLanguages;
