import React from 'react';
import { Box, Paper, Typography, Button, Divider } from '@mui/material';
import { WarningAmber } from '@mui/icons-material';

const JobCard = ({ job }) => {
  return (
    <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#ffffff' }}>
      <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', alignItems: 'center' }}>
        <Box sx={{ flexGrow: 1, mb: { xs: 2, sm: 0 } }}>
          <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{job.title}</Typography>
          <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: 'center', gap: 1, mt: 1 }}>
            <Typography variant="body2" color="textSecondary">{job.location}</Typography>
            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Typography variant="body2" color="textSecondary">Posted on: {job.postedOn}</Typography>
            <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />
            <Typography variant="body2" color="textSecondary">{job.postedBy}</Typography>
          </Box>
          {job.company && (
            <Typography variant="body2" color="textSecondary" sx={{ mt: 1 }}>For: {job.company}</Typography>
          )}
        </Box>

        <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, alignItems: { xs: 'flex-start', sm: 'center' }, gap: 2, mt: { xs: 2, sm: 0 } }}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography fontWeight="bold" variant="body2" color="textSecondary">{job.actionPending}</Typography>
            <Typography variant="body2" color="textSecondary">Action pending</Typography>
          </Box>
          <Divider orientation="vertical" flexItem sx={{ display: { xs: 'none', sm: 'block' } }} />

          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Typography fontWeight="bold" variant="body2" color="textSecondary">{job.allCandidates}</Typography>
            <Typography variant="body2" color="textSecondary">All candidates</Typography>
          </Box>
          {job.planRequired === 'unpaid' && (
            <Button variant="outlined" size="small" fontWeight="bold">Select Plan</Button>
          )}
        </Box>
      </Box>
      {job.planRequired === 'unpaid' && (
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 2, padding: 1, backgroundColor: '#fff8e1' }}>
          <WarningAmber sx={{ color: '#ff9800', marginRight: 1 }} />
          <Typography variant="body2" color="textSecondary">
            Select plan and finish job posting to start receiving candidates
          </Typography>
        </Box>
      )}
    </Paper>
  );
};

export default JobCard;
