import React, { useState } from 'react';
import { Container, Typography, Box, Button, Grid, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import LandingImage from '../../../assets/images/LandingImage.jpg';
import SearchBar from '../LandingSearchBar/SearchBar';

const popularSearches = ["Remote", "Hybrid", "Full Time", "Part Time", "MNC", "Startup", "Fresher"];

const JobSeekerJobSearch = () => {
  const [titleSearch, setTitleSearch] = useState('');
  const [locationSearch, setLocationSearch] = useState('');
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate(); // Initialize useNavigate

  const handleSearch = () => {
    // Navigate to JobSeekerFilter with search params
    navigate('/findJobs', {
      state: {
        jobTitle: titleSearch,
        jobCity: locationSearch,
      },
    });
  };

  return (
    <Box sx={{ backgroundColor: '#EDEDFC', padding: '3rem' }}>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Box sx={{ width: { xs: '100%', md: '60%' }, mb: { xs: 4, md: 2 } }}>
            <Typography
              variant={isSmallScreen ? 'h6' : 'h2'}
              component="h1"
              gutterBottom
              sx={{ color: '#000', fontWeight: 'bold' }}
            >
              Find the perfect job for you
            </Typography>
            <SearchBar
              title={titleSearch}
              location={locationSearch}
              onTitleChange={setTitleSearch}
              onLocationChange={setLocationSearch}
              onSearch={handleSearch}
            />
            <Typography variant="h6" gutterBottom sx={{ color: '#000', fontWeight: 'bold' }}>
              Popular Searches
            </Typography>
            <Grid container spacing={1}>
              {popularSearches.map((search) => (
                <Grid item xs={6} sm={4} md={3} lg={2} key={search}>
                  <Button variant="contained" sx={{ backgroundColor: '#423BFA', width: '100%', borderRadius: '20px' }}>
                    {search}
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Box>
          <Box sx={{ width: { xs: '100%', md: '40%' }, mt: { xs: 4, md: 0 }, ml: { xs: 0, md: 4 } }}>
            <img src={LandingImage} alt="Job search" style={{ width: '100%', borderRadius: '8px' }} />
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default JobSeekerJobSearch;
