import React, { useState, useEffect } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import {
  Box,
  CssBaseline,
  Typography,
  Divider,
  IconButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  AppBar as MuiAppBar,
  Drawer as MuiDrawer,
  Avatar,
  Menu,
  MenuItem,
  Button,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import VacanziLogo from '../../../../assets/icons/logos/vacanzi_30*40.png';
import { useNavigate } from 'react-router-dom';
import avatarmen from '../../../../assets/images/avatarmen.jpg';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import SupportIcon from '@mui/icons-material/Support';
import {jwtDecode }from 'jwt-decode';  // Import jwt-decode
import EmployerDrawerList from './EmployerDrawerList';

const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const EmployerDrawer = ({ children }) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [userData, setUserData] = useState(null); // State to store user data
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken); // Set user data from decoded token
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    } else {
      navigate('/employerSignIn'); // Redirect to login if no token found
    }
  }, [navigate]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  const handleNavigation = (path, index) => {
    setSelectedIndex(index);
    navigate(path);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleProfileClick = () => {
    handleMenuClose();
    navigate('/employerProfile');
  };

  const handleSignOutClick = () => {
    handleMenuClose();
    localStorage.clear();
    navigate('/');
  };

  return (
    <Box sx={{ display: 'flex', backgroundColor: '#f5f5f5' }}>
      <CssBaseline />
      <AppBar position="fixed" sx={{ backgroundColor: '#ffffff' }}>
        <Toolbar>
          <IconButton
            color='black'
            aria-label="toggle drawer"
            onClick={handleDrawerToggle}
            edge="start"
            sx={{ color: 'black' }}
          >
            <MenuIcon />
          </IconButton>
          <img style={{ height: 50, width: 50 }} src={VacanziLogo} alt="Vacanzi Logo" />
          <Typography
            variant="h4"
            noWrap
            component="a"
            href="/employerJobListings"
            sx={{
              mr: 1,
              display: { xs: "none", md: "flex" },
              fontFamily: "GlanceSansBold",
              color: "#423BFA",
              textDecoration: "none",
            }}
          >
            Vacanzi
          </Typography>
          <MenuItem onClick={() => handleNavigation('/jobseekerProfile')}>
            <Typography variant='body2' sx={{ color: '#423BFA' }} textAlign="center">JobSeeker Profiles</Typography>
          </MenuItem>

        
          <Box sx={{ flexGrow: 1 }} />
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            {userData?.role !== 'recruiter' && (
              <>
                <Button
                  sx={{
                    marginRight: 5,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'black',
                    textTransform: 'none',  // Prevents uppercase text transformation
                    minWidth: 'auto',        // Prevents button from having a default minimum width
                    padding: 0,              // Removes default padding
                    backgroundColor: 'transparent',  // Removes background color
                    '&:hover': {
                      backgroundColor: 'transparent',  // Ensures hover state has no background
                    }
                  }}
                  disableRipple  // Prevents the ripple effect on click
                >
                  <MonetizationOnIcon sx={{ marginRight: 0.5 }} />
                  Available Credits
                </Button>

                <Button
                  sx={{
                    marginRight: 2,
                    display: 'flex',
                    alignItems: 'center',
                    color: 'black',
                    textTransform: 'none',  // Prevents uppercase text transformation
                    minWidth: 'auto',        // Prevents button from having a default minimum width
                    padding: 0,              // Removes default padding
                    backgroundColor: 'transparent',  // Removes background color
                    '&:hover': {
                      backgroundColor: 'transparent',  // Ensures hover state has no background
                    }
                  }}
                  disableRipple  // Prevents the ripple effect on click
                >
                  <SupportIcon sx={{ marginRight: 0.5 }} />
                  Support
                </Button>
              </>
            )}
          </Box>
          <IconButton onClick={handleMenuOpen} color="inherit">
            <Avatar src={avatarmen} alt="User Avatar" />
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={openMenu}
            onClose={handleMenuClose}
          >
            <MenuItem>
              <Avatar src={avatarmen} alt="User Avatar" />
              <Typography variant="body1" sx={{ marginLeft: 2 }}>
                {userData?.fullName}
              </Typography>
            </MenuItem>
            <MenuItem>
              <Typography variant="body2" sx={{ marginLeft: 2 }}>
                Role: {userData?.role}
              </Typography>
            </MenuItem>
            <Divider />
            <MenuItem onClick={handleProfileClick}>
              <ListItemIcon>
                <AccountCircleIcon />
              </ListItemIcon>
              <ListItemText primary="View profile" />
            </MenuItem>
            <MenuItem onClick={handleSignOutClick}>
              <ListItemIcon>
                <ExitToAppIcon />
              </ListItemIcon>
              <ListItemText color="red" primary="Sign out" />
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <EmployerDrawerList Drawer={Drawer} userRole={userData?.role} theme={theme} DrawerHeader={DrawerHeader} open={open} selectedIndex={selectedIndex} handleDrawerToggle={handleDrawerToggle} handleNavigation={handleNavigation}/>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <DrawerHeader />
        {children}
      </Box>
    </Box>
  );
};

export default EmployerDrawer;
