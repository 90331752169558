import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel,
  FormGroup, TextField, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Grid, IconButton, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const companyTypes = [
  "Corporate", "Startup", "Indian MNC", "Foreign MNC", "MNC", "Others"
];

const CompanyTypeFilterComponent = () => {
  const [showMore, setShowMore] = useState(false);
  const [checkedCompanyTypes, setCheckedCompanyTypes] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogSearchQuery, setDialogSearchQuery] = useState('');
  const [tempCheckedCompanyTypes, setTempCheckedCompanyTypes] = useState([]);

  const handleShowMoreToggle = () => {
    setTempCheckedCompanyTypes([...checkedCompanyTypes]);
    setShowMore(!showMore);
  };

  const handleCheckboxChange = (event, isTemp = false) => {
    const companyType = event.target.name;
    if (isTemp) {
      setTempCheckedCompanyTypes(prev =>
        prev.includes(companyType)
          ? prev.filter(type => type !== companyType)
          : [...prev, companyType]
      );
    } else {
      setCheckedCompanyTypes(prev =>
        prev.includes(companyType)
          ? prev.filter(type => type !== companyType)
          : [...prev, companyType]
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDialogSearchChange = (event) => {
    setDialogSearchQuery(event.target.value);
  };

  const handleApply = () => {
    setCheckedCompanyTypes([...tempCheckedCompanyTypes]);
    setShowMore(false);
  };

  const filteredCompanyTypes = companyTypes.filter(type =>
    type.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredDialogCompanyTypes = companyTypes.filter(type =>
    type.toLowerCase().includes(dialogSearchQuery.toLowerCase())
  );

  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Company type</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          sx={{ backgroundColor: '#d3d3d3' }}
          placeholder='Search'
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <FormGroup>
          {filteredCompanyTypes.slice(0, 4).map((type, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={checkedCompanyTypes.includes(type)}
                  onChange={(e) => handleCheckboxChange(e, false)}
                  name={type}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedCompanyTypes.includes(type) ? 'bold' : 'normal', fontSize: '14px' }}>{type}</Typography>}
            />
          ))}
        </FormGroup>
        <Button onClick={handleShowMoreToggle}>
          View more
        </Button>
        <Dialog open={showMore} onClose={handleShowMoreToggle} maxWidth="xs" fullWidth>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              Company Types
              <IconButton onClick={handleShowMoreToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ height: '400px', overflowX: 'auto' }}>
            <TextField
              sx={{ backgroundColor: '#d3d3d3', marginBottom: '16px' }}
              placeholder="Search"
              variant="outlined"
              fullWidth
              value={dialogSearchQuery}
              onChange={handleDialogSearchChange}
            />
            <FormGroup>
              <Grid container spacing={1}>
                {filteredDialogCompanyTypes.map((type, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tempCheckedCompanyTypes.includes(type)}
                          onChange={(e) => handleCheckboxChange(e, true)}
                          name={type}
                          sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                        />
                      }
                      label={<Typography sx={{ fontWeight: tempCheckedCompanyTypes.includes(type) ? 'bold' : 'normal', fontSize: '12px' }}>{type}</Typography>}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApply} variant="contained" sx={{ backgroundColor: '#423BFA'}}>Apply</Button>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompanyTypeFilterComponent;
