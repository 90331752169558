import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container, Grid, Tabs, Tab, Box, TextField, Button, Typography, } from '@mui/material';
import signupVideo from '../../../assets/videos/videoplayback2.mp4';
import GoogleIconImg from '../../../assets/icons/logos/google-icon.png';
import JobSeekerOTPpage from './JobSeekerOTPpage';
import CustomSnackbar from '../../../components/AdvancedComponents/CustomSnackbar';
import baseURL from '../../../api/baseUrl';
import { getUserIdFromToken } from '../../../utils/userAuth';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          {children}
        </Box>
      )}
    </div>
  );
}

export default function SignInSignUp() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [formData, setFormData] = useState({
    loginPhone: '',
    signupEmail: '',
    signupName: '',
    signupPhone: '',
    otp: ''
  });
  const [errors, setErrors] = useState({});
  const [canResend, setCanResend] = useState(true);
  const [timer, setTimer] = useState(60);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState(''); // 'success' or 'error'

  const phoneNumberRegex = /^[6-9]\d{9}$/; // Regex for Indian phone numbers

  const validatePhoneNumber = (number) => phoneNumberRegex.test(number);

  useEffect(() => {
    if (canResend && timer > 0) {
      const interval = setInterval(() => {
        setTimer((prev) => prev - 1);
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [timer, canResend]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleFieldChange = (field, value) => {
    setFormData(prevState => {
      const newState = { ...prevState, [field]: value };

      // Clear error for loginPhone or signupPhone fields
      if (field === 'loginPhone' && validatePhoneNumber(value)) {
        setErrors(prevErrors => ({ ...prevErrors, loginPhone: '' }));
      }
      if (field === 'signupPhone' && validatePhoneNumber(value)) {
        setErrors(prevErrors => ({ ...prevErrors, signupPhone: '' }));
      }

      // Clear errors for signupEmail and signupName if they are valid
      if (field === 'signupEmail') {
        setErrors(prevErrors => ({ ...prevErrors, signupEmail: value ? '' : 'Email is required' }));
      }
      if (field === 'signupName') {
        setErrors(prevErrors => ({ ...prevErrors, signupName: value ? '' : 'Full Name is required' }));
      }

      return newState;
    });
  };

  const handleLoginSubmit = async () => {
    if (!validatePhoneNumber(formData.loginPhone)) {
      setErrors({ ...errors, loginPhone: 'Invalid phone number' });
      showSnackbar('Invalid phone number', 'error');
      return;
    }
    try {
      const response = await fetch(`${baseURL}/auth/checkjs_login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone: formData.loginPhone })
      });

      const result = await response.json();

      if (response.ok) {
        setShowOtpBox(true);
        showSnackbar('OTP sent successfully', 'success');
      } else {
        showSnackbar(result.message, 'error');
        if (result.message === 'User does not exist, please sign up') {
          setErrors({ ...errors, login: result.message });
          setTimeout(() => {
            setValue(1); // Switch to Sign-Up tab after 2 seconds
            setSnackbarOpen(false);

          }, 2000);
        } else {
          setErrors({ ...errors, login: result.message });
        }
      }
    } catch (error) {
      console.error('Error during login:', error);
      showSnackbar('Error during login', 'error');
    }
  };

  const handleSignupSubmit = async () => {
    if (!validatePhoneNumber(formData.signupPhone)) {
      setErrors({ ...errors, signupPhone: 'Invalid phone number' });
      showSnackbar('Invalid phone number', 'error');
      return;
    }
    if (!formData.signupEmail) {
      setErrors(prevErrors => ({ ...prevErrors, signupEmail: 'Email is required' }));
      showSnackbar('Email is required', 'error');
      return;
    }
    if (!formData.signupName) {
      setErrors(prevErrors => ({ ...prevErrors, signupName: 'Full Name is required' }));
      showSnackbar('Full Name is required', 'error');
      return;
    }
    try {
      const response = await fetch(`${baseURL}/auth/checkjs_register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fullName: formData.signupName,
          email: formData.signupEmail,
          phone: formData.signupPhone
        })
      });

      const result = await response.json();

      if (response.ok) {
        setShowOtpBox(true);
        showSnackbar('OTP sent successfully', 'success');
      } else {
        showSnackbar(result.message, 'error');
        if (result.message === 'User already exists') {
          setErrors({ ...errors, signup: result.message });
          setTimeout(() => {
            setValue(0); // Switch to Login tab after 2 seconds
            setSnackbarOpen(false);
          }, 2000);
        } else {
          setErrors({ ...errors, signup: result.message });
        }
      }
    } catch (error) {
      console.error('Error during registration:', error);
      showSnackbar('Error during registration', 'error');
    }
  };

  const handleResendOtp = () => {
    setTimer(60); // Reset timer
    setCanResend(false);
    // Handle resend OTP logic if needed
  };

  const showSnackbar = (message, type) => {
    setSnackbarMessage(message);
    setSnackbarType(type);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const handleOtpSubmit = async () => {
    const phone = value === 0 ? formData.loginPhone : formData.signupPhone;
    const url = value === 0 ? `${baseURL}/auth/validatejs_login` : `${baseURL}/auth/validatejs_register`;
  
    try {
      const response = await fetch(url, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ phone, otp: formData.otp })
      });
  
      const result = await response.json();
  
      if (response.ok) {
        localStorage.setItem('authToken', result.token); // Store token in localStorage
        getUserIdFromToken();
        showSnackbar('OTP validated successfully', 'success'); // Show success snackbar
  
        // Navigate based on user type
        if (value === 0) {
          navigate('/'); // Redirect logged-in users to home page
        } else {
          navigate('/jobSeekerRegestrationProfile', { 
            state: {
              signupEmail: formData.signupEmail,
              signupName: formData.signupName,
              signupPhone: formData.signupPhone
            }
          }); // Redirect new users to profile registration page with data
        }
      } else {
        showSnackbar(result.message || 'Invalid OTP', 'error'); // Show error snackbar
        setErrors({ ...errors, otp: 'Invalid OTP' }); // Set error state
      }
    } catch (error) {
      console.error('Error during OTP validation:', error);
      showSnackbar('Error during OTP validation', 'error'); // Show error snackbar
      setErrors({ ...errors, otp: 'Invalid OTP' }); // Set error state
    }
  };
  

  return (
    <Container maxWidth="md" sx={{ backgroundColor: '#ededfc', p: 6, boxShadow: 6 }}>
      {!showOtpBox ? (
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', mb: 3 }}>
              <Tabs value={value} onChange={handleChange} centered>
                <Tab label="Login" />
                <Tab label="Sign Up" />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <Typography variant="h4" gutterBottom>Hi, Welcome Back</Typography>
              <TextField
                fullWidth
                placeholder="Mobile"
                margin="normal"
                sx={{ backgroundColor: 'white' }}
                error={Boolean(errors.loginPhone)}
                helperText={errors.loginPhone}
                onChange={(e) => handleFieldChange('loginPhone', e.target.value)}
                required
              />
              <Button sx={{ mt: 2, backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold' }} fullWidth variant="contained" color="primary" onClick={handleLoginSubmit}>
                Login
              </Button>
              <Typography align="center" variant="body2" sx={{ mt: 2 }}>or continue with</Typography>
              <Button fullWidth color="primary" sx={{ mt: 1 }}>
                <img src={GoogleIconImg} alt="google-logo" />
              </Button>
              <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                Don't have an account? <Button onClick={() => setValue(1)}>Register now</Button>
              </Typography>            </TabPanel>
            <TabPanel value={value} index={1}>
              <Typography variant="h4" gutterBottom>Hi, New User</Typography>
              <TextField
                fullWidth
                placeholder="Full Name"
                margin="normal"
                sx={{ backgroundColor: 'white' }}
                error={Boolean(errors.signupName)}
                helperText={errors.signupName}
                onChange={(e) => handleFieldChange('signupName', e.target.value)}
                required
              />
              <TextField
                fullWidth
                placeholder="Email"
                margin="normal"
                sx={{ backgroundColor: 'white' }}
                error={Boolean(errors.signupEmail)}
                helperText={errors.signupEmail}
                onChange={(e) => handleFieldChange('signupEmail', e.target.value)}
                required
              />
              <TextField
                fullWidth
                placeholder="Mobile"
                margin="normal"
                sx={{ backgroundColor: 'white' }}
                error={Boolean(errors.signupPhone)}
                helperText={errors.signupPhone}
                onChange={(e) => handleFieldChange('signupPhone', e.target.value)}
                required
              />
           <Button sx={{ mt: 2, backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold' }} fullWidth variant="contained" color="primary" onClick={handleSignupSubmit}>
                Register
              </Button>
              <Typography align="center" variant="body2" sx={{ mt: 2 }}>or continue with</Typography>
              <Button fullWidth sx={{ mt: 1 }}>
                <img src={GoogleIconImg} alt="google-logo" />
              </Button>
              <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                Already have an account? <Button onClick={() => setValue(0)}>Login here</Button>
              </Typography>            </TabPanel>
          </Grid>
          <Grid item xs={12} md={6}>
            {!showOtpBox && (
              <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <video width="100%" height="100%" autoPlay muted style={{ objectFit: 'cover', borderRadius: '0.5rem' }}>
                  <source src={signupVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </Box>
            )}
          </Grid>
        </Grid>
      ) : (
        <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
          <JobSeekerOTPpage
            handleBack={() => setShowOtpBox(false)}
            phoneNumber={value === 0 ? formData.loginPhone : formData.signupPhone}
            signupEmail={formData.signupEmail}
            handleFieldChange={handleFieldChange}
            handleResendOtp={handleResendOtp}
            canResend={canResend}
            timer={timer}
            otp={formData.otp}
            errors={errors}
            handleOtpSubmit={handleOtpSubmit}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <video width="100%" height="100%" autoPlay muted style={{ objectFit: 'cover', borderRadius: '0.5rem' }}>
              <source src={signupVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Box>
        </Grid>
      </Grid>      )}
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        onClose={handleCloseSnackbar}
      />
    </Container>
  );
}
