import React from 'react';
import { Container, Typography, Box, Stepper, Step, StepLabel } from '@mui/material';

const steps = [
  {
    label: 'Register Account',
    description: 'First you need to make one account',
  },
  {
    label: 'Find Job',
    description: 'Second, search for the job you want',
  },
  {
    label: 'Apply Job',
    description: 'Apply to the company you want',
  },
];

const HowItWorks = () => {
  return (
    <Box sx={{ padding: { xs: '1rem', md: '2rem' }, backgroundColor: '#fff' }}>
      <Container maxWidth="xl">
        <Typography variant="h4" component="h2" gutterBottom sx={{ color: '#000', fontWeight: 'bold', textAlign: 'center' }}>
          How It Works
        </Typography>
        <Typography sx={{ textAlign: 'center', mb: 4 }}>Explore the following steps to help you find a job easily</Typography>
        <Stepper sx={{ flexDirection: { xs: 'column', md: 'row' }, justifyContent: 'space-between' }}>
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel sx={{ textAlign: { xs: 'center', md: 'left' } }}>
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>{step.label}</Typography>
                <Typography variant="body1" sx={{ color: '#666', mt: 1 }}>{step.description}</Typography>
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      </Container>
    </Box>
  );
};

export default HowItWorks;
