import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActionArea, Avatar, Container, useMediaQuery, useTheme } from '@mui/material';
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';
import ConstructionIcon from '@mui/icons-material/Construction';
import PeopleIcon from '@mui/icons-material/People';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import LocalHospitalIcon from '@mui/icons-material/LocalHospital';
import HeadsetMicIcon from '@mui/icons-material/HeadsetMic';
import CampaignIcon from '@mui/icons-material/Campaign';
import CodeIcon from '@mui/icons-material/Code';
import baseURL from '../../../api/baseUrl';

const iconMapping = {
    "Business Development": <BusinessCenterIcon />,
    "Engineering": <ConstructionIcon />,
    "Human Resources": <PeopleIcon />,
    "Finance": <AccountBalanceIcon />,
    "Health Care": <LocalHospitalIcon />,
    "Customer Care": <HeadsetMicIcon />,
    "Marketing": <CampaignIcon />,
    "Software Development": <CodeIcon />
};

const SearchCategories = () => {
    const [categories, setCategories] = useState([]);
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    

    useEffect(() => {
        // Fetch the categories from the API using fetch
        fetch(`${baseURL}/jobCategories`)
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                setCategories(data);
            })
            .catch(error => {
                console.error('Error fetching categories:', error);
            });
    }, []);
    

    return (
        <Box sx={{ backgroundColor: '#d6d6d6', py: 4 }}>
            <Container maxWidth="xl">
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', md: 'row' }}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Box sx={{ width: '100%', mb: { xs: 4, md: 2 } }}>
                        <Typography
                            variant={isSmallScreen ? 'h6' : 'h4'}
                            component="h1"
                            gutterBottom
                            sx={{ color: '#000', fontWeight: 'bold' }}
                        >
                            Search by Category
                        </Typography>

                        <Grid container spacing={2}>
                            {categories.map((category) => (
                                <Grid item xs={12} sm={6} md={3} lg={1.5} key={category._id}>
                                    <Card sx={{ width: { xs: '100%', sm: 150 }, height: 150, backgroundColor: '#EDEDFC', margin: 'auto' }}>
                                        <CardActionArea sx={{ height: '100%' }}>
                                            <CardContent sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                                                <Avatar sx={{ bgcolor: 'primary.main', mb: 2 }}>
                                                    {iconMapping[category.name] || <BusinessCenterIcon />} {/* Fallback icon if name doesn't match */}
                                                </Avatar>
                                                <Typography variant="body1" align="center">
                                                    {category.name}
                                                </Typography>
                                            </CardContent>
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default SearchCategories;
