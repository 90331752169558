import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import ListItemText from '@mui/material/ListItemText';

const cities = [
  { value: 'Mumbai', label: 'Mumbai' },
  { value: 'Delhi', label: 'Delhi' },
  { value: 'Bengaluru', label: 'Bengaluru' },
  { value: 'Kolkata', label: 'Kolkata' },
  { value: 'Chennai', label: 'Chennai' },
  { value: 'Hyderabad', label: 'Hyderabad' },
  { value: 'Ahmedabad', label: 'Ahmedabad' },
  { value: 'Pune', label: 'Pune' },
  { value: 'Jaipur', label: 'Jaipur' },
  { value: 'Lucknow', label: 'Lucknow' },
];

export default function CheckBoxSelect({ onSelectionChange }) {
  const [selectedCities, setSelectedCities] = React.useState([]);

  const handleChange = (event, value) => {
    setSelectedCities(value);
    onSelectionChange(value); // Notify parent component of the selection change
  };

  return (
    <Autocomplete
      multiple
      options={cities}
      getOptionLabel={(option) => option.label}
      value={selectedCities}
      onChange={handleChange}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          fullWidth
          placeholder="Type to search city"
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option.value}
        >
          <Checkbox
            checked={selectedCities.some((city) => city.value === option.value)}
          />
          <ListItemText primary={option.label} />
        </MenuItem>
      )}
    />
  );
}
