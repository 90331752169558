import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControl, FormGroup, FormControlLabel, Checkbox, Typography, useTheme, useMediaQuery } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const WorkTypeFilter = ({ workTypes = [], onWorkTypeChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [checkedWorkTypes, setCheckedWorkTypes] = useState(workTypes);

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    const newCheckedWorkTypes = checkedWorkTypes.includes(name)
      ? checkedWorkTypes.filter((type) => type !== name)
      : [...checkedWorkTypes, name];
    setCheckedWorkTypes(newCheckedWorkTypes);
    onWorkTypeChange(newCheckedWorkTypes);
  };

  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
       
      >
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Work Type</Typography>
      </AccordionSummary>
      <AccordionDetails >
        <FormControl component="fieldset" fullWidth>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="fullTime"
                  checked={checkedWorkTypes.includes('fullTime')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkTypes.includes('fullTime') ? 'bold' : 'normal', fontSize: isMobile ? '14px' : '16px' }}>Full time</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="partTime"
                  checked={checkedWorkTypes.includes('partTime')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkTypes.includes('partTime') ? 'bold' : 'normal', fontSize: isMobile ? '14px' : '16px' }}>Part time</Typography>}
            />
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default WorkTypeFilter;
