import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomMultiSelect from '../../CustomMultiSelect';

// Define options for departments
const departmentOptions = [
  'Engineering',
  'Marketing',
  'Sales',
  'Human Resources',
  'Finance',
  'Product Management',
  'Customer Support',
  'Operations',
  'Legal',
  'Design',
  'IT',
  'Quality Assurance',
  'Business Development',
  'Research and Development',
  'Administration',
  'Supply Chain',
  'Analytics',
  'Training',
  'Consulting',
  'Project Management',
];

const CandidateDepartments = () => {
  const [selectedDepartments, setSelectedDepartments] = useState([]);

  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Departments</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomMultiSelect
          placeholder="Select departments"
          options={departmentOptions}
          selectedOptions={selectedDepartments}
          setSelectedOptions={setSelectedDepartments}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateDepartments;
