import React, { useRef } from 'react';
import { Box, Button, Typography, IconButton, useMediaQuery } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';

const JobSeekerCaseFive = ({ formData, handleChange }) => {
  const videoInputRef = useRef('');
  const audioInputRef = useRef('');
  const pdfInputRef = useRef('');
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  

  const handleFileChange = (event) => {
    const { name, files } = event.target;
    const file = files[0];
    handleChange({ target: { name, value: file } });
  };

  const handleDelete = (type) => {
    handleChange({ target: { name: type, value: null } });
  };

  const handleEdit = (type) => {
    switch (type) {
      case 'videoCV':
        videoInputRef.current.click();
        break;
      case 'audioCV':
        audioInputRef.current.click();
        break;
      case 'resume':
        pdfInputRef.current.click();
        break;
      default:
        break;
    }
  };

  return (
    <Box sx={{ padding: 3, borderRadius: 4, backgroundColor: '#ffffff', margin: 'auto', maxWidth: 600 }}>
      <Typography sx={{ marginBottom: 2 }}>Choose CV Type</Typography>

      {/* Upload Video CV */}
      <Typography sx={{ fontWeight: 'bold', marginBottom: 2 }}>Video CV</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'center',
        marginBottom: 2
      }}>
        <Box sx={{
          width: isSmallScreen ? '100%' : '50%',
          height: 120,
          backgroundColor: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: isSmallScreen ? 0 : 2,
          marginBottom: isSmallScreen ? 2 : 0,
        }}>
          {formData.videoCV ? (
            <video
              src={URL.createObjectURL(formData.videoCV)}
              alt="Video CV"
              style={{ width: '100%', height: '100%' }}
              controls
            />
          ) : (
            <Button
              variant="contained"
              component="label"
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#EDEDFC',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#EDEDFC',
                  boxShadow: 'none',
                },
              }}
            >
              <AddIcon sx={{ fontSize: 40, color: 'green' }} />
              <input
                ref={videoInputRef}
                type="file"
                accept="video/*"
                name="videoCV"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          )}
        </Box>

        {formData.videoCV && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: isSmallScreen ? '100%' : '40%' }}>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {formData.videoCV.name}
            </Typography>
            <Box>
              <IconButton
                onClick={() => handleDelete('videoCV')}
                size="small"
                aria-label="delete"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => handleEdit('videoCV')}
                size="small"
                aria-label="edit"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>

      {/* Upload Audio CV */}
      <Typography sx={{ fontWeight: 'bold', marginBottom: 2 }}>Audio CV</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'center',
        marginBottom: 2
      }}>
        <Box sx={{
          width: isSmallScreen ? '100%' : '50%',
          height: 120,
          backgroundColor: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: isSmallScreen ? 0 : 2,
          marginBottom: isSmallScreen ? 2 : 0,
        }}>
          {formData.audioCV ? (
            <audio
              src={URL.createObjectURL(formData.audioCV)}
              alt="Audio CV"
              controls
              style={{ width: '100%', height: '100%' }}
            />
          ) : (
            <Button
              variant="contained"
              component="label"
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#EDEDFC',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#EDEDFC',
                  boxShadow: 'none',
                },
              }}
            >
              <AddIcon sx={{ fontSize: 40, color: 'green' }} />
              <input
                ref={audioInputRef}
                type="file"
                accept="audio/*"
                name="audioCV"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          )}
        </Box>

        {formData.audioCV && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: isSmallScreen ? '100%' : '40%' }}>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {formData.audioCV.name}
            </Typography>
            <Box>
              <IconButton
                onClick={() => handleDelete('audioCV')}
                size="small"
                aria-label="delete"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => handleEdit('audioCV')}
                size="small"
                aria-label="edit"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>

      {/* Upload Resume PDF */}
      <Typography sx={{ fontWeight: 'bold', marginBottom: 2 }}>Resume</Typography>
      <Box sx={{
        display: 'flex',
        flexDirection: isSmallScreen ? 'column' : 'row',
        alignItems: 'center',
        marginBottom: 2
      }}>
        <Box sx={{
          width: isSmallScreen ? '100%' : '50%',
          height: 120,
          backgroundColor: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginRight: isSmallScreen ? 0 : 2,
          marginBottom: isSmallScreen ? 2 : 0,
        }}>
          {formData.resume ? (
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '100%' }}>
              <PictureAsPdfIcon sx={{ fontSize: 60 }} />
            </Box>
          ) : (
            <Button
              variant="contained"
              component="label"
              sx={{
                width: '100%',
                height: '100%',
                backgroundColor: '#EDEDFC',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#EDEDFC',
                  boxShadow: 'none',
                },
              }}
            >
              <AddIcon sx={{ fontSize: 40, color: 'green' }} />
              <input
                ref={pdfInputRef}
                type="file"
                accept="application/pdf"
                name="resume"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          )}
        </Box>

        {formData.resume && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: isSmallScreen ? '100%' : '40%' }}>
            <Typography variant="body2" sx={{ flexGrow: 1 }}>
              {formData.resume.name}
            </Typography>
            <Box>
              <IconButton
                onClick={() => handleDelete('resume')}
                size="small"
                aria-label="delete"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={() => handleEdit('resume')}
                size="small"
                aria-label="edit"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <EditIcon />
              </IconButton>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default JobSeekerCaseFive;
