import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Button, Grid, TextField, Typography } from '@mui/material';
import './JobSeekerCaseZero.css'; // Ensure this CSS file contains necessary custom styles

const JobSeekerCaseZero = ({ formData, setFormData, handleGenderChange }) => {
  const location = useLocation();
  const { signupEmail, signupName, signupPhone } = location.state || {};

  // Use useEffect to set initial form data from location.state
  useEffect(() => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      email: signupEmail || prevFormData.email,
      fullName: signupName || prevFormData.fullName,
      phone: signupPhone || prevFormData.phone,
    }));
  }, [signupEmail, signupName, signupPhone, setFormData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };


  console.log('formData', formData);
  return (
    <Grid container spacing={2} sx={{ marginTop: 2 }}>
      <Grid item xs={12}>
        <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold' }}>
          Name
        </Typography>
        <TextField
          fullWidth
          placeholder="Enter full name"
          name="fullName"
          variant="outlined"
          value={formData.fullName || ''}
          onChange={handleChange}
          size="small"
          sx={{ marginBottom: 2, marginTop: 1 }}
          disabled={!!signupName}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold' }}>
          Date of Birth (DOB)
        </Typography>
        <TextField
          fullWidth
          type="date"
          name="dob"
          variant="outlined"
          value={formData.dob || ''}
          onChange={handleChange}
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
          sx={{ marginBottom: 2, marginTop: 1 }}
        />
      </Grid>
      <Grid item xs={12} sm={6} textAlign="center">
        <Typography component="legend" variant="bold" sx={{ marginBottom: 1.5, fontWeight: 'bold' }}>
          Gender
        </Typography>
        <Grid container justifyContent="center" spacing={2}>
          <Grid item>
            <Button
              variant={formData.gender === 'male' ? 'contained' : 'outlined'}
              onClick={() => handleGenderChange('male')}
              sx={{
                borderRadius: 6,
                backgroundColor: formData.gender === 'male' ? '#423BFA' : 'transparent',
                border: '1px solid #ccc',
                color: formData.gender === 'male' ? '#fff' : '#000',
              }}
            >
              <Typography fontWeight="bold" fontSize="12px">Male</Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant={formData.gender === 'female' ? 'contained' : 'outlined'}
              onClick={() => handleGenderChange('female')}
              sx={{
                borderRadius: 6,
                backgroundColor: formData.gender === 'female' ? '#423BFA' : 'transparent',
                border: '1px solid #ccc',
                color: formData.gender === 'female' ? '#fff' : '#000',
              }}
            >
              <Typography fontWeight="bold" fontSize="12px">Female</Typography>
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold' }}>
          Mobile
        </Typography>
        <TextField
          fullWidth
          type="tel"
          name="phone" // Ensure this matches the formData key
          variant="outlined"
          placeholder="Enter mobile number"
          value={formData.phone || ''} // Ensure this matches the formData key
          onChange={handleChange}
          size="small"
          sx={{ marginBottom: 2, marginTop: 1 }}
          disabled={!!signupPhone}
        />
      </Grid>
      <Grid item xs={12}>
        <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold' }}>
          Email
        </Typography>
        <TextField
          fullWidth
          type="email"
          name="email"
          variant="outlined"
          placeholder="Enter email"
          value={formData.email || ''}
          onChange={handleChange}
          size="small"
          sx={{ marginBottom: 2, marginTop: 1 }}
          disabled={!!signupEmail}
        />
      </Grid>
    </Grid>
  );
};

export default JobSeekerCaseZero;
