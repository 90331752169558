import React from 'react';
import { Box, Button, Typography, Grid, TextField } from '@mui/material';

const JobSeekerCaseThree = ({ formData, handleChange }) => {
  // Handler for updating pursuing education status
  const handlePursuingEducationChange = (value) => {
    handleChange({
      target: {
        name: 'pursuingEducation',
        value: value,
      },
    });
  };

  // Handler for updating currently pursuing degree
  const handleCurrentlyPursuingChange = (value) => {
    handleChange({
      target: {
        name: 'currentlyPursuing',
        value: value,
      },
    });
  };

  return (
    <Box sx={{ backgroundColor: '#ffffff', padding: 2, borderRadius: 4 }}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
            Are you still pursuing your education?
          </Typography>
          <Grid container spacing={2}>
            <Grid item>
              <Button
                variant={formData.pursuingEducation === true ? 'contained' : 'outlined'}
                onClick={() => handlePursuingEducationChange(true)}
                sx={{
                  borderRadius: 6,
                  backgroundColor: formData.pursuingEducation === true ? '#423BFA' : 'transparent',
                  border: '1px solid #ccc',
                  color: formData.pursuingEducation === true ? '#fff' : '#000',
                }}
              >
                <Typography fontWeight="bold" fontSize="12px">Yes</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant={formData.pursuingEducation === false ? 'contained' : 'outlined'}
                onClick={() => handlePursuingEducationChange(false)}
                sx={{
                  borderRadius: 6,
                  backgroundColor: formData.pursuingEducation === false ? '#423BFA' : 'transparent',
                  border: '1px solid #ccc',
                  color: formData.pursuingEducation === false ? '#fff' : '#000',
                }}
              >
                <Typography fontWeight="bold" fontSize="12px">No</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
        {formData.pursuingEducation === true ? (
          <Grid item xs={12}>
            <Typography component="legend" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
              What are you currently pursuing?
            </Typography>
            <Grid container spacing={1}>
              {['underGraduate', 'graduate', 'postGraduate'].map((option) => (
                <Grid item xs={6} key={option}>
                  <Button
                    variant={formData.currentlyPursuing === option ? 'contained' : 'outlined'}
                    onClick={() => handleCurrentlyPursuingChange(option)}
                    sx={{
                      width: '100%',
                      borderRadius: 4,
                      backgroundColor: formData.currentlyPursuing === option ? '#423BFA' : 'transparent',
                      borderColor: formData.currentlyPursuing === option ? '#423BFA' : '#ccc',
                      color: formData.currentlyPursuing === option ? '#fff' : '#000',
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">{option}</Typography>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        ) : (
          <>
            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Highest Qualification
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter highest qualification"
                name="highestQualification"
                variant="outlined"
                value={formData.highestQualification}
                onChange={handleChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Institution Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter institution name"
                name="institutionName"
                variant="outlined"
                value={formData.institutionName}
                onChange={handleChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Year of Graduation
              </Typography>
              <TextField
                fullWidth
                type="date"
                placeholder="Enter year of graduation"
                name="graduationYear"
                variant="outlined"
                value={formData.graduationYear}
                onChange={handleChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
          </>
        )}
      </Grid>
    </Box>
  );
};

export default JobSeekerCaseThree;
