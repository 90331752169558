import React from 'react';
import { Box, Typography, Grid, Container, useMediaQuery, useTheme } from '@mui/material';
import girlMobile from '../../../assets/images/VacanziMobile.png';

// const employers = [
//     { name: "Charlie Mark", role: "CEO", company: "ABC Corp" },
//     { name: "Chris Park", role: "HR Manager", company: "XYZ Ltd" },
// ];

const FeaturedEmployers = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ backgroundColor: '#fff', py: 4 }}>
            <Container maxWidth="xl">
                <Box mt={4}>
                    <Typography
                        variant={isSmallScreen ? 'h6' : 'h4'}
                        component="h1"
                        gutterBottom
                        sx={{ color: '#000', fontWeight: 'bold' }}
                    >
                        Featured Employers Actively Hiring
                    </Typography>
                    <Grid container spacing={4} alignItems="center">
                        <Grid item xs={12} md={6}>
                            <Box sx={{ textAlign: 'center' }}>
                                <img
                                    src={girlMobile}
                                    alt="mobile girl "
                                    style={{
                                        maxWidth: '100%',
                                        height: 'auto'
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h2"  sx={{}} component="h1" gutterBottom>
                                Most Amazing Featured Job listed
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.
                            </Typography>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default FeaturedEmployers;
