import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Button } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const CandidateNoticePeriod = ({ formData, handleChange }) => {
  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (value) => {
    setActiveButton(value);
    // Optionally, call handleChange if needed
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Notice Period
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Button
              variant={activeButton === '15Days' ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick('15Days')}
              sx={{
                borderRadius: 6,
                border: '1px solid #ccc',
                color: activeButton === '15Days' ? '#ffffff' : '#000',
                backgroundColor: activeButton === '15Days' ? '#4CAF50' : 'transparent',
                fontSize: '0.75rem', // Adjust font size
                padding: '4px 8px', // Adjust padding for smaller button
                textTransform: 'none',
                marginBottom: '4px', // Adjust spacing between buttons
              }}
              size="small"
            >
              Up to 15 Days
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={activeButton === '30Days' ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick('30Days')}
              sx={{
                borderRadius: 6,
                border: '1px solid #ccc',
                color: activeButton === '30Days' ? '#ffffff' : '#000',
                backgroundColor: activeButton === '30Days' ? '#4CAF50' : 'transparent',
                fontSize: '0.75rem', // Adjust font size
                padding: '4px 8px', // Adjust padding for smaller button
                textTransform: 'none',
                marginBottom: '4px', // Adjust spacing between buttons
              }}
              size="small"
            >
              Up to 30 Days
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={activeButton === '30Days' ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick('30Days')}
              sx={{
                borderRadius: 6,
                border: '1px solid #ccc',
                color: activeButton === '30Days' ? '#ffffff' : '#000',
                backgroundColor: activeButton === '30Days' ? '#4CAF50' : 'transparent',
                fontSize: '0.75rem', // Adjust font size
                padding: '4px 8px', // Adjust padding for smaller button
                textTransform: 'none',
                marginBottom: '4px', // Adjust spacing between buttons
              }}
              size="small"
            >
              Up to 60 Days
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant={activeButton === '3Months' ? 'contained' : 'outlined'}
              onClick={() => handleButtonClick('3Months')}
              sx={{
                borderRadius: 6,
                border: '1px solid #ccc',
                color: activeButton === '3Months' ? '#ffffff' : '#000',
                backgroundColor: activeButton === '3Months' ? '#4CAF50' : 'transparent',
                fontSize: '0.75rem', // Adjust font size
                padding: '4px 8px', // Adjust padding for smaller button
                textTransform: 'none',
              }}
              size="small"
            >
              Up to 3 Months
            </Button>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateNoticePeriod;
