import React from 'react';
import { Box, TextField, IconButton, useTheme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

const SearchBar = ({ title, location, onTitleChange, onLocationChange, onSearch }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box 
      display="flex" 
      backgroundColor="#ffffff"
      flexDirection={isSmallScreen ? 'column' : 'row'} 
      borderRadius="1rem"
      alignItems="center" 
      mt={2} 
      mb={4} 
      gap={isSmallScreen ? 2 : 0}
    >
      <TextField
        variant="outlined"
        placeholder="Search for Job Title"
        value={title}
        onChange={(e) => onTitleChange(e.target.value)}
        sx={{ flex: 1, mr: isSmallScreen ? 0 : 2, mb: isSmallScreen ? 2 : 0 }}
      />
      <TextField
        variant="outlined"
        placeholder="All Locations"
        value={location}
        onChange={(e) => onLocationChange(e.target.value)}
        sx={{ flex: 1, mr: isSmallScreen ? 0 : 2, mb: isSmallScreen ? 2 : 0 }}
      />
      <IconButton color="primary" onClick={onSearch}>
        <SearchIcon />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
