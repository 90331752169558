import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Grid, Divider, useMediaQuery } from '@mui/material';
import { Star, LocationOn, BusinessCenter} from '@mui/icons-material';
import vacanziLogo from '../../../assets/icons/logos/vacanzi_30*40.png';
import { jwtDecode } from 'jwt-decode';
import baseURL from '../../../api/baseUrl';

const JobsApplied = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const isMobile = useMediaQuery('(max-width:600px)');
    const navigate = useNavigate();

    useEffect(() => {
        const fetchAppliedJobs = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('User not authenticated');

                const decodedToken = jwtDecode(authToken);
                const userId = decodedToken.id;

                const response = await fetch(`${baseURL}/jobs/${userId}/applied`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setJobs(data.appliedJobs);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchAppliedJobs();
    }, []);

    if (loading) return <Typography variant="h6">Loading...</Typography>;
    if (error) return <Typography variant="h6">Error: {error}</Typography>;
    if (!jobs?.length) return <Typography variant="h6">No applied jobs available</Typography>;

    return (
        <Box sx={{ p: 2 }}>
            {jobs.map(({ jobId: job }) => (
                <Card key={job._id} sx={{ mb: 2 }}>
                    <CardContent>
                        <Grid container spacing={isMobile ? 1 : 2}>
                            <Grid item xs={12}>
                                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="flex-start">
                                    <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            <Link
                                                to={`/jobsApplied/${job._id}`}
                                                style={{ textDecoration: 'none', color: 'inherit' }}
                                                onClick={(e) => {
                                                    e.preventDefault();
                                                    navigate(`/jobsApplied/${job._id}`);
                                                }}
                                            > {job.jobTitle}
                                            </Link>
                                        </Typography>
                                        <Box display="flex" alignItems="center" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                                            <Typography variant="subtitle2" gutterBottom>
                                                {job.hiringFor}
                                            </Typography>
                                            {job.rating && (
                                                <>
                                                    <Star sx={{ ml: 1, mr: 0.5, fontSize: '15px' }} color="warning" />
                                                    <Typography variant="body2" color="textSecondary">
                                                        {job.rating} ({job.reviews} Reviews)
                                                    </Typography>
                                                </>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                                        <img height={isMobile ? 30 : 40} width={isMobile ? 30 : 40} src={vacanziLogo} alt="company logo" />
                                    </Box>
                                </Box>
                                <Divider sx={{ my: isMobile ? 1 : 2 }} />
                                <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} alignItems="center" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                                    <BusinessCenter sx={{ mr: 0.5, color: 'grey' }} />
                                    <Typography variant="body2" color="textSecondary">
                                        {job.experienceType} ({job.minExperience} - {job.totalExpRequired})
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" sx={{ ml: isMobile ? 0 : 2 }}>
                                        | ₹ {job.minFixedSalary} - {job.maxFixedSalary}
                                    </Typography>
                                    <LocationOn sx={{ ml: isMobile ? 1 : 2, mr: 0.5, color: 'grey' }} />
                                    <Typography variant="body2" color="textSecondary">
                                        {job.jobCity}
                                    </Typography>
                                </Box>
                                <Typography variant="subtitle2" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                                    {job.jobDescription}
                                </Typography>
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, mt: 1 }}>
                                    {job.skillPreferences?.map((skill, index) => (
                                        <Typography key={index} variant="body2" color="textSecondary" sx={{ fontSize: '12px' }}>
                                            {skill}{index < job.skillPreferences.length - 1 && ' · '}
                                        </Typography>
                                    ))}
                                </Box>
                                <Box display="flex" justifyContent="space-between" mt={2}>
                                    <Typography variant="body2" color="textSecondary">
                                        {new Date(job.postedDate).toDateString()}
                                    </Typography>
                                    
                                </Box>
                            </Grid>
                        </Grid>
                    </CardContent>
                </Card>
            ))}
        </Box>
    );
};

export default JobsApplied;
