import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid, Autocomplete, TextField
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

// Function to generate salary options
const generateSalaryOptions = () => {
  const salaryOptions = [];
  for (let i = 0; i <= 100; i += 1) {
    if (i === 0) {
      salaryOptions.push({ value: i, label: `${i} Lakhs` });
    } else if (i === 100) {
      salaryOptions.push({ value: i, label: `100+ Lakhs` });
    } else {
      salaryOptions.push({ value: i, label: `${i} Lakhs` });
    }
  }
  return salaryOptions;
};

const salaryOptions = generateSalaryOptions();

const CandidateAnnualSalary = ({ formData, handleChange }) => {
  // Find matching option for min and max salary
  const getOptionFromValue = (value) => {
    return salaryOptions.find(option => option.value === value) || null;
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Annual Salary</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <Autocomplete
              options={salaryOptions}
              getOptionLabel={(option) => option.label}
              value={getOptionFromValue(Number(formData?.minSalary))} // Ensure value is a number
              onChange={(event, newValue) => {
                handleChange({
                  target: { name: 'minSalary', value: newValue ? newValue.value : '' }
                });
              }}
              renderInput={(params) => <TextField {...params} placeholder="Min salary" />}
            />
          </Grid>
          <Grid item xs={6}>
            <Autocomplete
              options={salaryOptions}
              getOptionLabel={(option) => option.label}
              value={getOptionFromValue(Number(formData?.maxSalary))} // Ensure value is a number
              onChange={(event, newValue) => {
                handleChange({
                  target: { name: 'maxSalary', value: newValue ? newValue.value : '' }
                });
              }}
              renderInput={(params) => <TextField {...params} placeholder="Max salary" />}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateAnnualSalary;
