import { useEffect } from "react";
import "./index.css"; // Import the CSS file
import Routes from "./routes/routes";
import { messaging } from "./utils/firebaseConfig";
import { getToken } from "firebase/messaging";
import baseURL from "./api/baseUrl";

function App() {
  // Function to request notification permission and handle token
  async function requestPermission() {
    try {
      // Request notification permission
      const permission = await Notification.requestPermission();

      if (permission === "granted") {
        alert("Permission Granted");

        // Get FCM token
        const token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_FCM_SECRET,
        });

        if (token) {
          console.log("Token Generated:", token);

          // Get userId from localStorage
          const userId = localStorage.getItem('userId');
          
          if (userId) {
            // Send the token to your backend
            await sendTokenToBackend(userId, token);
          } else {
            console.log("No userId found in localStorage.");
          }
        } else {
          console.log("No registration token available.");
        }
      } else if (permission === "denied") {
        alert("User Denied the Notification Permission");
      }
    } catch (error) {
      console.error("Failed to request notification permission", error);
    }
  }

  // Function to send the token to your backend
  async function sendTokenToBackend(userId, token) {
    try {
      const response = await fetch(`${baseURL}/update-token`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ userId, fcmToken: token }), // Include userId and token in the request
      });

      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Failed to send token to backend: ${response.status} ${response.statusText} - ${errorText}`);
      }

      console.log('Token sent to backend successfully.');
    } catch (error) {
      console.error('Error sending token to backend:', error);
    }
  }

  // Using useEffect to request permission when the component mounts
  useEffect(() => {
    requestPermission();
  }, []);

  return (
    <div>
      <Routes />
    </div>
  );
}

export default App;
