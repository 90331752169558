import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getMessaging } from "firebase/messaging";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAKHZWjC7jFDqcDfwohdUJli_Fs-rq-vEw",
  authDomain: "vacanzi.firebaseapp.com",
  projectId: "vacanzi",
  storageBucket: "vacanzi.appspot.com",
  messagingSenderId: "826573670917",
  appId: "1:826573670917:web:1d1c7aab9595f7293b6a6c",
  measurementId: "G-FLM5YZ20D7"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app)

// Optionally, initialize Firebase Analytics if needed
const analytics = getAnalytics(app);

export { app, analytics, messaging};
