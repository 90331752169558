import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomCheckbox from '../../CustomCheckbox';

const CandidateHide = ({ formData, handleChange }) => {
  const [checkedItems, setCheckedItems] = useState({
    unlocked: false,
    downloadedExcel: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Hide Candidates that are
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0.5}> {/* Reduced spacing between items */}
          <Grid item xs={12}>
            <CustomCheckbox
              label="Already unlocked"
              checked={checkedItems.unlocked}
              onChange={handleCheckboxChange}
              name="unlocked"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Already Downloaded Excel"
              checked={checkedItems.downloadedExcel}
              onChange={handleCheckboxChange}
              name="downloadedExcel"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateHide;
