import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomMultiSelect from '../../CustomMultiSelect';

// Define options for specializations
const specializationOptions = [
  'Software Engineering',
  'Data Science',
  'Product Management',
  'Design',
  'Marketing',
  'Sales',
  'Finance',
  'Human Resources',
  'Customer Support',
  'Operations',
  'Legal',
  'Consulting',
  'Research & Development',
  'Project Management',
  'Quality Assurance',
  'Business Analysis',
  'IT Support',
  'Systems Administration',
  'Network Engineering',
  'Content Creation',
];

const CandidateSpecialization = () => {
  const [selectedSpecializations, setSelectedSpecializations] = useState([]);

  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Specializations</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomMultiSelect
          placeholder="Select specializations"
          options={specializationOptions}
          selectedOptions={selectedSpecializations}
          setSelectedOptions={setSelectedSpecializations}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateSpecialization;
