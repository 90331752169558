// RouteComponent.jsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import LoginPage from '../pages/JobSeekerPages/SignInSignUp/SignInSignUp';
import EmployerSignIn from '../pages/EmployerPages/EmployerLogin/EmployerSignIn';
import HomePage from '../pages/Home/Home';
import JobSeekerProfile from '../pages/EmployerPages/JobSeekerProfiles/JobSeekerProfiles';
import JobSeekerRegestrationProfile from '../pages/JobSeekerPages/JobseekerRegestrationProfile/jobSeekerRegestrationProfile';
import JobSeekerFilter from '../pages/JobSeekerPages/JobSeekerFilters/JobSeekerFilters';
import JobSeekerJobDetails from '../pages/JobSeekerPages/JobSeekerJobDetails/JobSeekerJobDetails'; // Import correct component
import EmployersRegistrationProfile from '../pages/EmployerPages/EmployersRegistrationProfile/EmployersRegistrationProfile';
import JobSeekerPersonalProfile from '../pages/JobSeekerPages/JobSeekerPersonalProfiles/JobSeekerPersonalProfile';
import EmployerJobListings from '../pages/EmployerPages/EmployerJobList/EmployerJobListings';
import SearchCandidate from '../pages/EmployerPages/EmployerDatabases/searchCandidate';
import UnlockCandidates from '../pages/EmployerPages/EmployerDatabases/unlockCandidates';
import SavedSearches from '../pages/EmployerPages/EmployerDatabases/savedSearches';
import CandidateSearchList from '../pages/EmployerPages/CandidateSearchList/CandidateSearchList';
import EmployerProfile from '../pages/EmployerPages/EmployerPersonalProfile/EmployerProfile';
import EmployerTeamMembers from '../pages/EmployerPages/EmployerTeamMemebers/EmployerTeamMembers';
import JobSeekerAppliedJobs from '../pages/JobSeekerPages/JobSeekerAppliedJobs/JobSeekerAppliedJobs';
import JobSeekerSavedJobs from '../pages/JobSeekerPages/JobSeekerSavedJobs/JobSeekerSavedJobs';
import EmployerRegistration from '../pages/EmployerPages/EmployerRegistration/EmployerRegistration';
import JobAppliedDetails from '../pages/JobSeekerPages/JobSeekerAppliedJobs/JobAppliedDetails';
import JobSavedDetails from '../pages/JobSeekerPages/JobSeekerSavedJobs/JobSavedDetails';


const RouteComponent = () => (
  <Router>
    <Routes>

      <Route path="/" element={<HomePage />} />

      {/* Employer Routes */}
      <Route path="/employerSignIn" element={<EmployerSignIn />} />
      <Route path="/jobseekerProfile" element={<JobSeekerProfile />} />
      <Route path="/employersRegistrationProfile" element={<EmployersRegistrationProfile />} />
      <Route path='/employerJobListings' element={<EmployerJobListings />} />
      <Route path="/search-candidates" element={<SearchCandidate />} />
      <Route path="saved-searches" element={<SavedSearches />} />
      <Route path="/unlock-candidates" element={<UnlockCandidates />} />
      <Route path='/candidate-searchList' element={<CandidateSearchList />} />
      <Route path='/employerProfile' element={<EmployerProfile />} />
      <Route path='/team-members' element={<EmployerTeamMembers />} />
      <Route path='/employerRegistration' element={<EmployerRegistration />} />


      {/* JobSeeker Routes */}
      <Route path="/signinsignup" element={<LoginPage />} />
      <Route path="/jobSeekerRegestrationProfile" element={<JobSeekerRegestrationProfile />} />
      <Route path="/JobSeekerFilter" element={<JobSeekerFilter />} />
      <Route path="/JobSeekerFilter/job/:id" element={<JobSeekerJobDetails />} />
      <Route path="/profile" element={<JobSeekerPersonalProfile />} />
      <Route path="/findJobs" element={<JobSeekerFilter />} />
      <Route path='/jobSeekerAppliedJobs' element={<JobSeekerAppliedJobs />} />
      <Route path='/jobSeekerSavedJobs' element={<JobSeekerSavedJobs />} />
      <Route path="/jobsApplied/:id" element={<JobAppliedDetails />} />
      <Route path="/jobsSaved/:id" element={<JobSavedDetails />} />



    </Routes>
  </Router>
);

export default RouteComponent;
