import * as React from 'react';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import Autocomplete from '@mui/material/Autocomplete';

// Function to generate experience options
const generateExperienceOptions = () => {
  const experienceOptions = [];
  for (let i = 0; i <= 30; i++) {
    if (i === 0) {
      experienceOptions.push({ value: i, label: `${i} months` });
    } else if (i === 1) {
      experienceOptions.push({ value: i, label: `${i} year` });
    } else if (i === 30) {
      experienceOptions.push({ value: i, label: `30+ years` });
    } else {
      experienceOptions.push({ value: i, label: `${i} years` });
    }
  }
  return experienceOptions;
};

// Function to generate salary options
const generateSalaryOptions = () => {
  const salaryOptions = [];
  for (let i = 0; i <= 100; i += 1) {
    if (i === 0) {
      salaryOptions.push({ value: i, label: `${i} Lakhs` });
    } else if (i === 100) {
      salaryOptions.push({ value: i, label: `100+ Lakhs` });
    } else {
      salaryOptions.push({ value: i, label: `${i} Lakhs` });
    }
  }
  return salaryOptions;
};

const experienceOptions = generateExperienceOptions();
const salaryOptions = generateSalaryOptions();

export default function SingleSelect({ formData, handleChange }) {
  const [experienceSearch, setExperienceSearch] = React.useState('');
  const [salarySearch, setSalarySearch] = React.useState('');

  const filteredExperienceOptions = React.useMemo(
    () => experienceOptions.filter(option =>
      option.label.toLowerCase().includes(experienceSearch.toLowerCase())
    ),
    [experienceSearch]
  );

  const filteredSalaryOptions = React.useMemo(
    () => salaryOptions.filter(option =>
      option.label.toLowerCase().includes(salarySearch.toLowerCase())
    ),
    [salarySearch]
  );

  return (
    <Grid container spacing={2}>
      <Grid item xs={6}>
        <Typography component="legend" sx={{ fontWeight: 'bold' }} gutterBottom>
          Minimum experience
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredExperienceOptions}
          getOptionLabel={(option) => option.label}
          value={formData.minExperience || null}
          onChange={(event, newValue) => handleChange({ target: { name: 'minExperience', value: newValue } })}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="minimum experience"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setExperienceSearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography component="legend" sx={{ fontWeight: 'bold' }} gutterBottom>
          Maximum experience
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredExperienceOptions}
          getOptionLabel={(option) => option.label}
          value={formData.maxExperience || null}
          onChange={(event, newValue) => handleChange({ target: { name: 'maxExperience', value: newValue } })}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="maximum experience"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setExperienceSearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography component="legend" sx={{ fontWeight: 'bold' }} gutterBottom>
          Min. salary in lakhs
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredSalaryOptions}
          getOptionLabel={(option) => option.label}
          value={formData.minSalary || null}
          onChange={(event, newValue) => handleChange({ target: { name: 'minSalary', value: newValue } })}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="minimum salary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSalarySearch(e.target.value)}
            />
          )}
        />
      </Grid>
      <Grid item xs={6}>
        <Typography component="legend" sx={{ fontWeight: 'bold' }} gutterBottom>
          Max. salary in lakhs
        </Typography>
        <Autocomplete
          size="small"
          fullWidth
          options={filteredSalaryOptions}
          getOptionLabel={(option) => option.label}
          value={formData.maxSalary || null}
          onChange={(event, newValue) => handleChange({ target: { name: 'maxSalary', value: newValue } })}
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder="maximum salary"
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => setSalarySearch(e.target.value)}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}
