import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControl,FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const WorkShiftFilter = ({ workShifts, onWorkShiftChange }) => {
  const [checkedWorkShifts, setCheckedWorkShifts] = useState(workShifts || []);

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    const newCheckedWorkShifts = checkedWorkShifts.includes(name)
      ? checkedWorkShifts.filter((shift) => shift !== name)
      : [...checkedWorkShifts, name];
    setCheckedWorkShifts(newCheckedWorkShifts);
    onWorkShiftChange(newCheckedWorkShifts);
  };

  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Work Shift</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" fullWidth>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="dayShift"
                  checked={checkedWorkShifts.includes('dayShift')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkShifts.includes('dayShift') ? 'bold' : 'normal', fontSize: '14px' }}>Day shift</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="nightShift"
                  checked={checkedWorkShifts.includes('nightShift')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkShifts.includes('nightShift') ? 'bold' : 'normal', fontSize: '14px' }}>Night shift</Typography>}
            />
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default WorkShiftFilter;
