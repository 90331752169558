import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomCheckbox from '../../CustomCheckbox'; // Adjust the import path as necessary

const CandidatePreference = ({ formData, handleChange }) => {
  const [checkedItems, setCheckedItems] = useState({
    fullTime: false,
    partTime: false,
    dayShift: false,
    nightShift: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Candidate Preferences
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0.5}>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Full Time"
              checked={checkedItems.fullTime}
              onChange={handleCheckboxChange}
              name="fullTime"
              extraText="(Full-time positions)"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Part Time"
              checked={checkedItems.partTime}
              onChange={handleCheckboxChange}
              name="partTime"
              extraText="(Part-time positions)"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Day Shift"
              checked={checkedItems.dayShift}
              onChange={handleCheckboxChange}
              name="dayShift"
              extraText="(Daytime hours)"
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Night Shift"
              checked={checkedItems.nightShift}
              onChange={handleCheckboxChange}
              name="nightShift"
              extraText="(Nighttime hours)"
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidatePreference;
