import React from 'react';
import { Box, IconButton, Select, MenuItem, Typography } from '@mui/material';
import { FirstPage, LastPage, ChevronLeft, ChevronRight } from '@mui/icons-material';
import FormControl from '@mui/material/FormControl';


const CustomPagination = ({ currentPage, totalPages, onPageChange, itemsPerPage, onItemsPerPageChange }) => {
  const handleFirstPageButtonClick = () => onPageChange(1);
  const handleBackButtonClick = () => onPageChange(currentPage - 1);
  const handleNextButtonClick = () => onPageChange(currentPage + 1);
  const handleLastPageButtonClick = () => onPageChange(totalPages);

  const iconButtonStyle = {
    borderRadius: '50%',
    backgroundColor: 'white',
    margin: '0 4px',
  };

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end" sx={{ width: '200px' }}>
      <Typography variant='body2'>Showing</Typography>
      <FormControl sx={{ m: 1, minWidth: 80 }} size="small">
      <Select
        value={itemsPerPage}
        onChange={(e) => onItemsPerPageChange(e.target.value)}
        sx={{ mx: 1, minWidth: 'fit-content' }}
      >
        {[10, 20, 30, 40, 50].map((number) => (
          <MenuItem key={number} value={number}>
            {number}
          </MenuItem>
        ))}
      </Select>
      </FormControl>
      <Typography variant='body2'sx={{  minWidth: 'fit-content' }}>per page</Typography>
      <IconButton onClick={handleFirstPageButtonClick} disabled={currentPage === 1} sx={iconButtonStyle}>
        <FirstPage sx={{ fontSize :'16px'}}/>
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={currentPage === 1} sx={iconButtonStyle}>
        <ChevronLeft sx={{ fontSize :'16px'}}/>
      </IconButton>
      <Typography sx={{  minWidth: 'fit-content', fontSize :'16px'}}>{`Page ${currentPage} of ${totalPages}`}</Typography>
      <IconButton onClick={handleNextButtonClick} disabled={currentPage === totalPages} sx={iconButtonStyle}>
        <ChevronRight sx={{ fontSize :'16px', 
        }}/>
      </IconButton>
      <IconButton onClick={handleLastPageButtonClick} disabled={currentPage === totalPages} sx={iconButtonStyle}>
        <LastPage sx={{ fontSize :'16px'}}/>
      </IconButton>
    </Box>
  );
};

export default CustomPagination;
