import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, FormControl, RadioGroup,
  FormControlLabel, Radio, Typography
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const SortByComponent = ({ sortBy, onSortByChange }) => {
  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Sort By</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup value={sortBy} onChange={onSortByChange}>
            <FormControlLabel
              value="relevant"
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: '14px', fontWeight: sortBy === 'relevant' ? 'bold' : 'normal' }}>
                  Relevant
                </Typography>
              }
            />
            <FormControlLabel
              value="salaryHighToLow"
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: '14px', fontWeight: sortBy === 'salaryHighToLow' ? 'bold' : 'normal' }}>
                  Salary - High to low
                </Typography>
              }
            />

            <FormControlLabel
              value="datePostedNewToOld"
              control={<Radio />}
              label={
                <Typography sx={{ fontSize: '14px', fontWeight: sortBy === 'datePostedNewToOld' ? 'bold' : 'normal' }}>
                  Date posted - New to Old
                </Typography>
              }
            />
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default SortByComponent;
