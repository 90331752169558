import React from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  IconButton,
  Divider,
  Tooltip,
  Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LockIcon from '@mui/icons-material/Lock';
import DownloadIcon from '@mui/icons-material/Download';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

const DatabaseCreditsInfo = () => {
  return (
    <Box
      sx={{
        maxWidth: 350, // Adjust this value to control the width
        width: '100%',
        margin: '0 auto', // Center the component
      }}
    >
      <Accordion defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography variant="h6">
            <span style={{ display: 'flex', alignItems: 'center' }}>
              <LocalOfferIcon style={{ marginRight: 8 }} />
              <Typography variant="subtitle2" fontWeight="bold" fontSize="18px">
                How Database Credits work?
              </Typography>
            </span>
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography variant="body1" style={{ display: 'flex', alignItems: 'center' }}>
            <LockIcon style={{ marginRight: 8 }} />
            <Typography variant="body2">1 Profile Unlock</Typography>
            <Tooltip title="Explanation for profile unlock">
              <IconButton size="small">
                <span>?</span>
              </IconButton>
            </Tooltip>
            <Typography variant="body2"> = 1 Database Credit</Typography>
          </Typography>
          <Typography
            variant="body1"
            style={{ display: 'flex', alignItems: 'center', marginTop: 8 }}
          >
            <DownloadIcon style={{ marginRight: 8 }} />
            <Typography variant="body2">1 Excel Download = 2 Database Credits*</Typography>
          </Typography>
          <Divider sx={{ marginTop: 2}}/>
          <Typography variant="caption" color="textSecondary" style={{ marginTop: 18 }} textAlign="center">
            * For already unlocked profiles, 1 Database Credit is used for Excel Download
          </Typography>
          {/* <Box
            sx={{
              marginTop: 2,
              backgroundColor: '#f5f5f5',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: 2,
            }}
          >
            <Button startIcon={<LocalOfferIcon />} sx={{ marginBottom: 1 , color:'green'}}>
              <Typography sx={{ textTransform: 'none', fontWeight:'bold', color:'black'}} variant="body2">Explore Plans & Offers</Typography>
            </Button>
            <Button
              sx={{
                width: '80%',
                backgroundColor: '#ffffff',
              }}
              variant="outlined"
              endIcon={<ExpandMoreIcon />}
            >
              <Typography sx={{ fontWeight:'bold', color:'black', textTransform: 'none'}}>Buy credits</Typography>
            </Button>
          </Box> */}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default DatabaseCreditsInfo;
