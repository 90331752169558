import React from 'react';
import JobSeekerProfileTabs from '../../../components/EmployerComponents/JobSeekerProfile/JobSeekerProfile'; // Adjust the path as necessary

const EmployeeProfile = () => {
    return (

        <JobSeekerProfileTabs />

    );
};

export default EmployeeProfile;
