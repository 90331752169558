import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import NonRegisteredAppBar from '../../../components/NavBarNonRegistered/NonRegisteredNavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import { Container, Box, Stepper, Step, StepButton, Button, Typography, Paper, useMediaQuery } from '@mui/material';
import EmployerCaseZero from '../../../components/EmployerComponents/EmployerRegistrationCases/EmployerCaseZero/EmployerCaseZero';
import EmployerCaseOne from '../../../components/EmployerComponents/EmployerRegistrationCases/EmployerCaseOne/EmployerCaseOne';
import EmployerCaseTwo from '../../../components/EmployerComponents/EmployerRegistrationCases/EmployerCaseTwo/EmployerCaseTwo';
import EmployerCaseThree from '../../../components/EmployerComponents/EmployerRegistrationCases/EmployerCaseThree/EmployerCaseThree';
import EmployerSubscriptionPayment from '../../../components/EmployerComponents/EmployerRegistrationCases/EmployerPayments/EmployerSubscriptionPayment';

const steps = ['Job Details', 'Candidate Requirements', 'Interview Information', 'Job Preview', 'Payment'];

const EmployersRegistrationProfile = () => {
  const location = useLocation();
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState({});
  const [formData, setFormData] = useState({
    jobTitle: '',
    jobType: '',
    isNightShift: false,
    workLocation: '',
    payType: '',
    perks: [],
    joiningFee: '',
    minFixedSalary: '',
    maxFixedSalary: '',
    showSalary: false,
    joiningFeeDetails: {
      feeAmount: '',
      feeReason: '',
      otherReason: '',
      feePaymentTime: ''
    }
  });

  useEffect(() => {
    if (location.state?.jobData) {
      setFormData((prevData) => ({
        ...prevData,
        ...location.state.jobData,
      }));
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleOptionChange = (field, value) => {
    setFormData({ ...formData, [field]: value });
  };

  const handleComplete = () => {
    setCompleted((prevCompleted) => ({ ...prevCompleted, [activeStep]: true }));
    handleNext();
  };

  const handleNext = () => {
    const newActiveStep = isLastStep() && !allStepsCompleted()
      ? steps.findIndex((step, i) => !(i in completed))
      : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  // const handleReset = () => {
  //   setActiveStep(0);
  //   setCompleted({});
  // };

  const totalSteps = () => steps.length;
  const completedSteps = () => Object.keys(completed).length;
  const isLastStep = () => activeStep === totalSteps() - 1;
  const allStepsCompleted = () => completedSteps() === totalSteps();
  

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <EmployerCaseZero formData={formData} handleChange={handleChange} handleOptionChange={handleOptionChange} handleBack={handleBack} handleNext={handleNext} />;
      case 1:
        return <EmployerCaseOne formData={formData} handleChange={handleChange} handleOptionChange={handleOptionChange}   handleNext={handleNext} handleBack={handleBack}/>;
      case 2:
        return <EmployerCaseTwo formData={formData} handleChange={handleChange} handleOptionChange={handleOptionChange} handleNext={handleNext} handleBack={handleBack}/>;
      case 3:
        return <EmployerCaseThree formData={formData} handleChange={handleChange} handleOptionChange={handleOptionChange} handleNext={handleNext} handleBack={handleBack}/>;
      case 4:
        return <EmployerSubscriptionPayment formData={formData}/>;
      default:
        return 'Unknown step';
    }
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));

  return (
    <>
      <NonRegisteredAppBar />
      <Box sx={{ backgroundColor: '#e5e7eb', minHeight: '100vh', py: 5 }}>
        <Container maxWidth="xl">
          <Paper elevation={0} sx={{ p: 2, backgroundColor: '#ffffff', mb: 4 }}>
            <Box sx={{ width: '100%' }}>
              <Stepper nonLinear activeStep={activeStep} orientation={isMobile ? 'vertical' : 'horizontal'}>
                {steps.map((label, index) => (
                  <Step key={label} completed={completed[index]}>
                    <StepButton color="inherit" onClick={handleStep(index)}>
                      {label}
                    </StepButton>
                  </Step>
                ))}
              </Stepper>
              <div>
               
                  <React.Fragment>
                    {getStepContent(activeStep)}
                   
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                      <Box sx={{ flex: '1 1 auto' }} />
                      {activeStep !== steps.length &&
                        (completed[activeStep] ? (
                          <Typography variant="caption" sx={{ display: 'inline-block' }}>
                            Step {activeStep + 1} already completed
                          </Typography>
                        ) : (
                          <Button onClick={handleComplete}>
                            {completedSteps() === totalSteps() - 1 ? 'Finish' : null}
                          </Button>
                        ))}
                    </Box>
                  </React.Fragment>
              </div>
            </Box>
          </Paper>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default EmployersRegistrationProfile;
