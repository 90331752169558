import React, { useState } from 'react';
import { Box, TextField, Typography, Grid, Button } from '@mui/material';
import baseURL from '../../../../api/baseUrl';

const EmployerCaseTwo = ({ formData, handleChange, handleOptionChange, handleNext, handleBack }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleButtonChange = (field, value) => {
    handleOptionChange(field, value);
  };

  const handleSubmit = async () => {
    setLoading(true);
    setError(null);
    
    try {
      const response = await fetch(`${baseURL}/jobpost/update/page`, { 
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          jobId: formData.jobId,
          organizationId: formData.organizationId,
          page: 3,  // Adjust this if needed
          interviewType: formData.interviewType,
          communicationPref: formData.contactPreference === 'Yes, to other recruiter' ? 'yesToRecruiter' : 'yesToMyself',
          whichCandCanCall: formData.isWalkInInterview === 'Yes' ? 'match60' : 'all',
          alertFromVacanzi: formData.notificationPreference === 'Yes, to myself' ? 'yes' : 'summary',
        }),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // const data = await response.json();
    } catch (err) {
      setError(err.message || 'An error occurred');
      console.error('Error:', err);
    } finally {
      setLoading(false);
      handleNext();
    }
  };

  return (
    <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, p: 2 }} noValidate autoComplete="off">
      <Grid container spacing={2} sx={{ marginTop: 2 }}>
        {/* Interview Method and Address */}
        <Grid item xs={12}>
          <Typography component="legend" variant="h6" sx={{ fontWeight: 'bold' }}>
            Interview Method and Address
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Let candidates know how the interview will be conducted for this job.
          </Typography>
        </Grid>

        {/* Type of Interview */}
        <Grid item xs={12}>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
            Type of Interview
          </Typography>
          <Grid container spacing={1}>
            {[{label:'In-Person / Walk-In Interview', value: "inPerson"}, {label: 'Telephonic/Online Interview', value: "online"}].map((option) => (
              <Grid item key={option.value}>
                <Button
                  variant={formData.interviewType === option.value ? 'contained' : 'outlined'}
                  onClick={() => handleButtonChange('interviewType', option.value)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.interviewType === option.value ? '#423BFA' : 'transparent',
                    border: '1px solid #ccc',
                    color: formData.interviewType === option.value ? '#fff' : '#000',
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {option.label}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>

        {/* Interview Address */}
        <Grid item xs={12}>
          <TextField
            required
            id="interview-address"
            label="Interview Address"
            variant="outlined"
            fullWidth
            value={formData.interviewAddress}
            onChange={handleChange}
            name="interviewAddress"
            size="small"
            sx={{ marginBottom: 2 }}
          />
          <Typography variant="body2" color="textSecondary">
            + Add Floor / Plot no. / Shop no. (optional)
          </Typography>
        </Grid>

        {/* Walk-In Interview */}
        {/* <Grid item xs={12}>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }}>
            Is this a walk-in interview?
          </Typography>
          <Grid container spacing={1}>
            {['Yes', 'No'].map((option) => (
              <Grid item key={option}>
                <Button
                  variant={formData.isWalkInInterview === option ? 'contained' : 'outlined'}
                  onClick={() => handleButtonChange('isWalkInInterview', option)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.isWalkInInterview === option ? '#423BFA' : 'transparent',
                    border: '1px solid #ccc',
                    color: formData.isWalkInInterview === option ? '#fff' : '#000',
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {option}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid> */}

        {/* Date Range */}
        {/* <Grid item xs={6}>
          <TextField
            required
            id="start-date"
            label="Start Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.startDate}
            onChange={handleChange}
            name="startDate"
            size="small"
            sx={{ marginBottom: 2 }}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            required
            id="end-date"
            label="End Date"
            type="date"
            fullWidth
            InputLabelProps={{ shrink: true }}
            value={formData.endDate}
            onChange={handleChange}
            name="endDate"
            size="small"
            sx={{ marginBottom: 2 }}
          />
        </Grid> */}

        {/* Walk-In Timings */}
        {/* <Grid item xs={12}>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }}>
            Walk-in Timings
          </Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                id="walk-in-start-time"
                label="Start Time"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formData.walkInStartTime}
                onChange={handleChange}
                name="walkInStartTime"
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="walk-in-end-time"
                label="End Time"
                type="time"
                fullWidth
                InputLabelProps={{ shrink: true }}
                value={formData.walkInEndTime}
                onChange={handleChange}
                name="walkInEndTime"
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TextField
            id="other-instructions"
            label="Other Instructions"
            variant="outlined"
            multiline
            rows={4}
            fullWidth
            value={formData.otherInstructions}
            onChange={handleChange}
            name="otherInstructions"
            size="small"
            sx={{ marginBottom: 2 }}
          />
        </Grid> */}

        {/* Communication Preferences */}
        {/* <Grid item xs={12}>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }}>
            Communication Preferences
          </Typography>
          <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
            Leads information will be accessible on Vacanzi portal and can be downloaded in excel format.
          </Typography>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
            Do you want candidates to contact you via Call / Whatsapp after they apply?
          </Typography>
          <Grid container spacing={1}>
            {['Yes, to myself', 'Yes, to other recruiter', 'No, I will contact candidates first'].map((option) => (
              <Grid item key={option}>
                <Button
                  variant={formData.contactPreference === option ? 'contained' : 'outlined'}
                  onClick={() => handleButtonChange('contactPreference', option)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.contactPreference === option ? '#423BFA' : 'transparent',
                    border: '1px solid #ccc',
                    color: formData.contactPreference === option ? '#fff' : '#000',
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {option}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid> */}

        {/* Notification Preferences */}
        {/* <Grid item xs={12}>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }}>
            Notification Preferences
          </Typography>
          <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold' }} gutterBottom>
            Every time you receive a candidate application, do you want Whatsapp Alerts from Vacanzi?
          </Typography>
          <Grid container spacing={1}>
            {['Yes, to myself', 'No, send me summary once a day'].map((option) => (
              <Grid item key={option}>
                <Button
                  variant={formData.notificationPreference === option ? 'contained' : 'outlined'}
                  onClick={() => handleButtonChange('notificationPreference', option)}
                  sx={{
                    borderRadius: 6,
                    backgroundColor: formData.notificationPreference === option ? '#423BFA' : 'transparent',
                    border: '1px solid #ccc',
                    color: formData.notificationPreference === option ? '#fff' : '#000',
                  }}
                >
                  <Typography fontWeight="bold" fontSize="12px">
                    {option}
                  </Typography>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid> */}

        {/* Submit Button */}
        
      </Grid>
      <Box sx={{ display: "flex", justifyContent: "space-between",mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="inherit"

          onClick={handleBack}
          sx={{
            backgroundColor: "#423BFA",
            color: "#fff",
            borderRadius: 6,
            px: 3,
            py: 1.5,
            "&:hover": {
              backgroundColor: "#372FD1",
            },
          }}
        >
          Back
        </Button>
        <Button
            variant="contained"
            sx={{
              backgroundColor: "#423BFA",
              color: "#fff",
              borderRadius: 6,
              px: 3,
              py: 1.5,
              "&:hover": {
                backgroundColor: "#372FD1",
              },
            }}
            onClick={handleSubmit}
            disabled={loading}
          >
            {loading ? 'Submitting...' : 'Submit'}
          </Button>
      </Box>
         
          {error && (
            <Typography color="error" sx={{ marginTop: 2 }}>
              {error}
            </Typography>
          )}
    </Box>
  );
};

export default EmployerCaseTwo;
