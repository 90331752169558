import React, { useState, useEffect } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel,
  FormGroup, TextField, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Grid, IconButton, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const locations = [
  "Delhi / NCR (1088)", "Mumbai (All Areas) (854)", "Pune (826)", "Bengaluru (805)",
  "Mumbai (679)", "Chennai (522)", "Noida (489)", "Hyderabad (457)",
  "Gurugram (437)", "Ahmedabad (412)", "Nashik (311)", "Kolkata (311)",
  "New Delhi (215)", "Jaipur (181)", "Thane (179)", "Navi Mumbai (171)",
  "Surat (149)", "Lucknow (144)", "Kochi (121)", "Coimbatore (109)",
  "Vadodara (105)", "Nagpur (102)", "Chandigarh (94)", "Ghaziabad (81)",
  "Faridabad (81)"
];

const LocationFilterComponent = ({ selectedLocations, setSelectedLocations }) => {
  const [showMore, setShowMore] = useState(false);
  const [checkedLocations, setCheckedLocations] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogSearchQuery, setDialogSearchQuery] = useState('');

  useEffect(() => {
    // Sync checkedLocations with selectedLocations prop
    setCheckedLocations(selectedLocations);
  }, [selectedLocations]);

  const handleShowMoreToggle = () => {
    setShowMore(!showMore);
  };

  const handleCheckboxChange = (event, temp = false) => {
    const location = event.target.name.replace(/ *\([^)]*\) */g, '').trim(); // Clean location
    const newCheckedLocations = temp
      ? checkedLocations.includes(location)
        ? checkedLocations.filter(loc => loc !== location)
        : [...checkedLocations, location]
      : checkedLocations.includes(location)
        ? checkedLocations.filter(loc => loc !== location)
        : [...checkedLocations, location];

    if (temp) {
      setCheckedLocations(newCheckedLocations);
    } else {
      setCheckedLocations(newCheckedLocations);
      setSelectedLocations(newCheckedLocations); // Update selected locations in parent
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDialogSearchChange = (event) => {
    setDialogSearchQuery(event.target.value);
  };

  const handleApply = () => {
    setSelectedLocations(checkedLocations);
    setShowMore(false);
  };

  const filteredLocations = locations.filter(location =>
    location.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredDialogLocations = locations.filter(location =>
    location.toLowerCase().includes(dialogSearchQuery.toLowerCase())
  );

  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Location</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          sx={{ backgroundColor: '#d3d3d3' }}
          placeholder='Search'
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <FormGroup>
          {filteredLocations.slice(0, 4).map((location, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={checkedLocations?.includes(location.replace(/ *\([^)]*\) */g, '').trim())}
                  onChange={(e) => handleCheckboxChange(e)}
                  name={location}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedLocations.includes(location) ? 'bold' : 'normal', fontSize: '14px' }}>{location}</Typography>}
            />
          ))}
        </FormGroup>
        <Button onClick={handleShowMoreToggle}>
          View more
        </Button>
        <Dialog open={showMore} onClose={handleShowMoreToggle} maxWidth="xs" fullWidth>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              Locations
              <IconButton onClick={handleShowMoreToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ height: '400px', overflowX: 'auto' }}>
            <TextField
              sx={{ backgroundColor: '#d3d3d3', marginBottom: '16px' }}
              placeholder="Search"
              variant="outlined"
              fullWidth
              value={dialogSearchQuery}
              onChange={handleDialogSearchChange}
            />
            <FormGroup>
              <Grid container spacing={1}>
                {filteredDialogLocations.map((location, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checkedLocations.includes(location.replace(/ *\([^)]*\) */g, '').trim())}
                          onChange={(e) => handleCheckboxChange(e, true)} // Indicate this is for the dialog
                          name={location}
                          sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                        />
                      }
                      label={<Typography sx={{ fontWeight: checkedLocations.includes(location) ? 'bold' : 'normal', fontSize: '12px' }}>{location}</Typography>}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApply} variant="contained" sx={{ backgroundColor: '#423BFA'}}>Apply</Button>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default LocationFilterComponent;
