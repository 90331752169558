import * as React from 'react';
import { TextField, Autocomplete, MenuItem, Checkbox, ListItemText } from '@mui/material';

const CustomMultiSelect = ({ placeholder, options, selectedOptions, setSelectedOptions }) => {
  const [inputValue, setInputValue] = React.useState('');

  const handleChange = (event, value) => {
    setSelectedOptions(value);
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  const toggleSelection = (option) => {
    setSelectedOptions((prev) =>
      prev.includes(option) ? prev.filter((item) => item !== option) : [...prev, option]
    );
  };

  return (
    <Autocomplete
      multiple
      options={options}
      value={selectedOptions}
      onChange={handleChange}
      inputValue={inputValue}
      onInputChange={handleInputChange}
      disableCloseOnSelect
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          fullWidth
          placeholder={placeholder}
          variant="outlined"
        />
      )}
      renderOption={(props, option) => (
        <MenuItem
          {...props}
          key={option}
          selected={selectedOptions.includes(option)}
          onClick={() => toggleSelection(option)}
        >
          <Checkbox
            checked={selectedOptions.includes(option)}
            onClick={(event) => event.stopPropagation()} // Prevent closing dropdown on checkbox click
          />
          <ListItemText primary={option} />
        </MenuItem>
      )}
    />
  );
};

export default CustomMultiSelect;
