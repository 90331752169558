import React, { useState, useEffect } from 'react';
import { Container, Typography } from '@mui/material';
import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import JobsSaved from './jobSaved';
import { jwtDecode } from 'jwt-decode';
import baseURL from '../../../api/baseUrl';

const JobSeekerSavedJobs = () => {
    const [jobs, setJobs] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchSavedJobs = async () => {
            try {
                const authToken = localStorage.getItem('authToken');
                if (!authToken) throw new Error('User not authenticated');

                const decodedToken = jwtDecode(authToken);
                const userId = decodedToken.id;

                const response = await fetch(`${baseURL}/jobs/savedJobs/${userId}`, {
                    headers: {
                        Authorization: `Bearer ${authToken}`,
                    },
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setJobs(data);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchSavedJobs();
    }, []);

    
    return (
        <>
            <NavBar />
            <Container maxWidth="sm" sx={{ p: 10 }}>
                <Typography variant='h5' fontWeight='bold'>Saved Jobs</Typography>
                <JobsSaved jobs={jobs} loading={loading} error={error} />
            </Container>
            <Footer />
        </>
    );
};

export default JobSeekerSavedJobs;
