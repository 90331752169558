import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Slider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const ExperienceComponent = ({ experience, onExperienceChange }) => (
  <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Experience</Typography>
    </AccordionSummary>
    <AccordionDetails sx={{ backgroundColor: '#f5f5f5' }}>
      <Slider
        value={experience}
        onChange={onExperienceChange}
        valueLabelDisplay="auto"
        min={0}
        max={30}
        marks={[
          { value: 0, label: '0 years' },
          { value: 30, label: '30 years' }
        ]}
        sx={{
          width: 'calc(100% - 24px)', // Adjust width to keep the marks within the card
          marginLeft: '12px', // Center the slider
          marginRight: '12px', // Center the slider
          color: 'black', // Set slider color to black
          '& .MuiSlider-thumb': {
            borderRadius: '50%',
            border: '2px solid black'
          },
          '& .MuiSlider-track': {
            border: 'none',
          },
          '& .MuiSlider-rail': {
            opacity: 0.5,
            backgroundColor: 'black',
          },
          '& .MuiSlider-mark': {
            backgroundColor: 'black',
            height: '8px',
            width: '1px',
          },
          '& .MuiSlider-markLabel': {
            color: 'black',
          },
        }}
      />
    </AccordionDetails>
  </Accordion>
);

export default ExperienceComponent;
