import React from 'react';
import { Typography, Box, Grid, Paper, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

const EmployerCaseZeroDetails = ({ CaseZeroJobData }) => {


    return (
        <Accordion defaultExpanded>
            <AccordionSummary>
                <Paper variant="outlined" sx={{ padding: 0.5, backgroundColor: '#f0f7ff', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <InfoIcon color="primary" sx={{ marginRight: 1 }} />
                            <Typography>Job Details</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <IconButton>
                                <EditIcon />
                            </IconButton>
                            <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }} />
                        </Box>
                    </Box>
                </Paper>
            </AccordionSummary>
            <AccordionDetails>
                <Paper variant="outlined" sx={{ padding: 2 }}>
                    <Box sx={{ marginTop: 2 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Company name</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.hiringFor}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Job title</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.jobTitle}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Job role/category</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.jobRoleCategory}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Job type</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.jobType}</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Work type</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.jobMode
                                    }</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Job location</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.jobCity
                                    } India</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Monthly Salary | Pay Type</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">₹ {CaseZeroJobData?.jobPosting?.minFixedSalary
                                    } - ₹ {CaseZeroJobData?.jobPosting?.maxFixedSalary} per month {CaseZeroJobData?.jobPosting?.compensation
                                        }</Typography>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Additional perks</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.additionalPerks[0]
                                    }</Typography>
                                </Grid>
                            </Grid>
                            {/* <Grid item xs={12} container>
                                <Grid item xs={4}>
                                    <Typography variant="subtitle1">Joining Fee</Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography variant="body2">{CaseZeroJobData?.jobPosting?.joiningFee ? CaseZeroJobData?.jobPosting?.joiningFee : '-'}</Typography>
                                </Grid>
                            </Grid> */}
                        </Grid>
                    </Box>
                </Paper>
            </AccordionDetails>
        </Accordion>
    );
};

export default EmployerCaseZeroDetails;
