import React, { useState } from 'react';
import { Box, IconButton, Drawer, useMediaQuery, useTheme, Card } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CandidateAge from './CandidateFilterComponents/CandidateAge';
import CandidateAnnualSalary from './CandidateFilterComponents/CandidateAnnualSalary';
import CandidateExperience from './CandidateFilterComponents/CandidateExpierence';
import CandidateIndustries from './CandidateFilterComponents/CandidateIndustries';
import CandidateCity from './CandidateFilterComponents/CandidateCity';
import CandidateSpecialization from './CandidateFilterComponents/CandidateSpecialization';
import CandidateLanguages from './CandidateFilterComponents/CandidateLanguages';
import CandidateCompany from './CandidateFilterComponents/CandidateCompany';
import CandidateDepartments from './CandidateFilterComponents/CandidateDepartments';
import CandidateHide from './CandidateFilterComponents/CandidateHide';
import CandidateShow from './CandidateFilterComponents/CandidateShow';
import CandidateEducation from './CandidateFilterComponents/CandidateEducation';
import CandidateEnglishFluency from './CandidateFilterComponents/CandidateEnglishFluency';
import CandidatePreference from './CandidateFilterComponents/CandidatePreference';
import CandidateNoticePeriod from './CandidateFilterComponents/CandidateNoticePeriod';
import CandidateGender from './CandidateFilterComponents/CandidateGender';

const CandidateSearchFilters = ({ formData, handleChange, setFormData }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [open, setOpen] = useState(false);

  const toggleDrawer = (open) => () => {
    setOpen(open);
  };

  const FilterContent = () => (
    <Box p={1}>
      <CandidateHide />
      <CandidateShow />
      <CandidateAge />
      <CandidateAnnualSalary formData={formData} handleChange={handleChange}/>
      <CandidateExperience formData={formData} handleChange={handleChange}/>
      <CandidateGender />
      <CandidateIndustries />
      <CandidateCity formData={formData} setFormData={setFormData}/>
      <CandidateSpecialization />
      <CandidateEducation />
      <CandidateLanguages />
      <CandidateEnglishFluency />
      <CandidateCompany />
      <CandidateNoticePeriod />
      <CandidateDepartments />
      <CandidatePreference />
    </Box>
  );

  return (
    <Box>
      {isMobile ? (
        <>
          <IconButton onClick={toggleDrawer(true)} aria-label="open filters">
            <FilterAltIcon />
          </IconButton>
          <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
            <FilterContent />
          </Drawer>
        </>
      ) : (
        <Card variant="outlined">
          <Box p={2}>
            <FilterContent />
          </Box>
        </Card>
      )}
    </Box>
  );
};

export default CandidateSearchFilters;
