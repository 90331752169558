import React, { useState } from 'react';
import { useNavigate} from 'react-router-dom';
import { Box, Container, Grid, Divider, CardContent, Typography, Button } from '@mui/material';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import signupVideo from '../../../assets/videos/videoplayback2.mp4';
import NonRegisteredNavBar from '../../../components/NavBarNonRegistered/NonRegisteredNavBar';
import JobSeekerCaseZero from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseZero/JobSeekerCaseZero';
import JobSeekerCaseOne from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseOne/JobSeekerCaseOne';
import JobSeekerCaseTwo from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseTwo/JobSeekerCaseTwo';
import JobSeekerCaseThree from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseThree/JobSeekerCaseThree';
import JobSeekerCaseFour from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseFour/JobSeekerCaseFour';
import JobSeekerCaseFive from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseFive/JobSeekerCaseFive';
import JobSeekerCaseSix from '../../../components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseSix/JobSeekerCaseSix';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import CustomSnackbar from '../../../components/AdvancedComponents/CustomSnackbar';
import baseUrl from '../../../api/baseUrl';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#423BFA' : '#308fe8',
  },
}));

export default function Job() {
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('');
  const navigate = useNavigate();

  const theme = createTheme();

  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    highestQualification: '',
    passoutYear: '',
    dob: '',
    currentCompany: '',
    dateOfJoining: '',
    dateOfExit: null,
    noticePeriod: '',
    gender: '',
    totalExp: 0,
    videoCV: '',
    audioCV: '',
    resume: '',
    totalExpInMonths: 0,
    currentCtc: '',
    skills: [],
    description: '',
    currentDesignation: '',
    educations: [
      {
        degree: '',
        institution: '',
        fieldOfStudy: '',
        startDate: '',
        endDate: '',
        grade: '',
        activitiesAndSocieties: '',
        description: '',
        graduationYear: ''
      }
    ],
    experiences: [
      {
        company: '',
        designation: '',
        jobRole: [],
        industry: '',
        currentlyWorking: true,
        startDate: '',
        endDate: '',
        jobDescription: '',
        location: '',
        responsibilities: '',
        technologiesUsed: [],
        achievements: '',
        ctc: '',
        expectedCtc: ''
      }
    ],
    pursuingEducation: false,
    currentlyPursuing: 'NA',
    profilePicture: null,
    preferredJobType: [], // Initialize as array
    preferredShift: [],   // Initialize as array
    preferredWorkplace: [], // Initialize as array
    preferredEmploymentType: '',
    preferredLocation: '',
    currentLocation: '',
    graduationYear: null,
    hasWorkExperience: true
  });

  const steps = [
    'Basic Details',
    'Work Experience',
    'Job Details',
    'Education Qualification',
    'Profile Photo',
    'Resume Upload',
    'Job Preferences',
  ];

  const handleNext = () => {
    if (activeStep === 1 && formData.hasWorkExperience === 'no') {
      setActiveStep(3);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
    if (activeStep === 6) {
      setCompleted(true);
    }
  };

  const handleBack = () => {
    if (activeStep === 3 && formData.hasWorkExperience === 'no') {
      setActiveStep(1);
    } else if (activeStep === 3 && formData.hasWorkExperience === 'yes') {
      setActiveStep(2);
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };
  const handleChange = (event) => {
    const { name, value } = event.target;

    // If the value is an array (for Autocomplete), update state directly
    if (Array.isArray(value)) {
      setFormData(prevData => ({ ...prevData, [name]: value }));
    } else {
      // Handle other types like string values for Select
      setFormData(prevData => ({ ...prevData, [name]: value }));
    }
  };


  const handleGenderChange = (gender) => {
    setFormData({
      ...formData,
      gender: gender,
    });
  };

  const handleJobRoleChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences: [
        {
          ...prevFormData.experiences[0],
          jobRole: newValue,
        },
      ],
    }));
  };

  const handleExperienceChange = (experience) => {
    handleChange({
      target: {
        name: 'hasWorkExperience',
        value: experience,
      },
    });
  };

  const handlePursuingEducationChange = (value) => {
    handleChange({
      target: {
        name: 'pursuingEducation',
        value: value,
      },
    });
  };

  const handleCurrentlyPursuingChange = (value) => {
    handleChange({
      target: {
        name: 'currentlyPursuing',
        value: value,
      },
    });
  };

  const handleIndustryChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      experiences: [
        {
          ...prevFormData.experiences[0],
          industry: newValue,
        },
      ],
    }));
  };

  const handleCurrentlyWorkingChange = (workingStatus) => {
    handleChange({
      target: {
        name: 'currentlyWorking',
        value: workingStatus,
      },
    });
  };

  const handleSkillsChange = (event, newValue) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      skills: newValue,
    }));
  };

  const handleProceed = () => {
    setCompleted(false);
    setActiveStep(0);
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      highestQualification: '',
      passoutYear: '',
      dob: '',
      currentCompany: '',
      dateOfJoining: '',
      dateOfExit: null,
      noticePeriod: '',
      gender: '',
      totalExp: 0,
      totalExpInMonths: 0,
      currentCtc: '',
      videoCV: '',
      audioCV: '',
      resume: '',
      skills: [],
      description: '',
      currentDesignation: '',
      educations: [
        {
          degree: '',
          institution: '',
          fieldOfStudy: '',
          startDate: '',
          endDate: '',
          grade: '',
          activitiesAndSocieties: '',
          description: '',
          graduationYear: ''
        }
      ],
      experiences: [
        {
          company: '',
          designation: '',
          jobRole: [],
          industry: '',
          currentlyWorking: true,
          startDate: '',
          endDate: null,
          jobDescription: '',
          location: '',
          responsibilities: '',
          technologiesUsed: [],
          achievements: '',
          ctc: '',
          expectedCtc: ''
        }
      ],
      pursuingEducation: false,
      currentlyPursuing: 'NA',
      profilePicture: null,
      preferredJobType: [], // Initialize as array
      preferredShift: [],   // Initialize as array
      preferredWorkplace: [], // Initialize as array
      preferredEmploymentType: '',
      preferredLocation: '',
      currentLocation: '',
      graduationYear: null,
      hasWorkExperience: true
    });
    navigate('/JobSeekerFilter');
  };


  const handleSubmit = async () => {
    try {
      const apiEndpoint = `${baseUrl}/jobseeker/register`;

      // Convert formData to JSON
      const jsonBody = {};

      Object.keys(formData).forEach(key => {
        if (formData[key] instanceof File) {
          // You may need to handle file uploads differently or upload files separately
          console.warn(`File fields are currently not supported in JSON requests: ${key}`);
        } else {
          jsonBody[key] = formData[key];
        }
      });

      const response = await fetch(apiEndpoint, {
        method: 'POST',
        body: JSON.stringify(jsonBody),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        console.log('Form submitted successfully:', data);
        handleProceed();
        setSnackbarType('success');
        setSnackbarMessage('Profile successfully created!');
        setSnackbarOpen(true);
      } else {
        console.error('Error submitting form:', response.statusText);
        setSnackbarType('error');
        setSnackbarMessage('Failed to create profile.');
        setSnackbarOpen(true);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
      setSnackbarType('error');
      setSnackbarMessage('Failed to create profile.');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <JobSeekerCaseZero formData={formData} handleChange={handleChange} setFormData={setFormData} handleGenderChange={handleGenderChange} />
        );
      case 1:
        return (
          <Box>
            <JobSeekerCaseOne steps={steps} formData={formData} handleChange={handleChange} handleExperienceChange={handleExperienceChange} />
          </Box>
        );
      case 2:
        return (
          <JobSeekerCaseTwo steps={steps} formData={formData} setFormData={setFormData} handleSkillsChange={handleSkillsChange} handleJobRoleChange={handleJobRoleChange} handleIndustryChange={handleIndustryChange} handleChange={handleChange} handleCurrentlyWorkingChange={handleCurrentlyWorkingChange} />
        );
      case 3:
        return (
          <JobSeekerCaseThree formData={formData} handleCurrentlyPursuingChange={handleCurrentlyPursuingChange} handlePursuingEducationChange={handlePursuingEducationChange} handleChange={handleChange} />
        );
      case 4:
        return (
          <JobSeekerCaseFour formData={formData} handleChange={handleChange} handleNext={handleNext} />
        );
      case 5:
        return (
          <ThemeProvider theme={theme}>
            <JobSeekerCaseFive formData={formData} handleChange={handleChange} setFormData={setFormData} />
          </ThemeProvider>
        );
      case 6:
        return (
          <JobSeekerCaseSix formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
        );
      default:
        return 'Unknown step';
    }
  };



  console.log('activestep', activeStep);

  return (
    <Box sx={{ width: '100%', backgroundColor: '#e5e7eb', height: '100vh' }}>
      <NonRegisteredNavBar />
      <Container sx={{ marginTop: '4rem' }}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={7}>
            {completed ? (
              <Grid sx={{ backgroundColor: '#ffffff', padding: 2, borderRadius: 4 }}>
                <CardContent align="center">
                  <Typography variant="h5" gutterBottom>✨ Congratulations! ✨</Typography>
                  <Typography variant="body1" gutterBottom>Your profile is successfully created.</Typography>
                  <Box mt={4}>
                    <Button
                      sx={{
                        backgroundColor: '#4CAF50',
                        padding: '10px',
                        borderRadius: 2,
                        '&:hover': {
                          backgroundColor: '#4CAF50',
                        },
                      }}
                      type="submit"
                      fullWidth
                      onClick={handleProceed}
                    >
                      <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2" color='white'>Proceed</Typography>
                    </Button>
                  </Box>
                </CardContent>
              </Grid>
            ) : (
              <Grid sx={{ backgroundColor: '#ffffff', padding: 2, borderRadius: 4 }}>
                <Grid container alignItems="center">
                  <Grid item xs={6}>
                    <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                      <KeyboardBackspaceIcon
                        disabled={activeStep === steps + 1}
                        onClick={handleBack}
                        sx={{ mr: 2 }}
                      />
                      {steps[activeStep]}
                    </Typography>
                  </Grid>
                  <Grid item xs={6} sx={{ textAlign: 'right', mb: 2 }}>
                    <Box sx={{ width: '100%' }}>
                      <BorderLinearProgress variant="determinate" value={(activeStep / (steps.length - 1)) * 100} />
                    </Box>
                  </Grid>
                </Grid>
                <Divider />
                {renderStepContent(activeStep)}
                <Divider />
                {activeStep === 4 && (
                  <Box mt={2}>
                    <Button
                      sx={{
                        backgroundColor: 'transparent',
                        padding: '8px',
                        borderRadius: 2,
                        border: '1px solid #423BFA',
                        '&:hover': {
                          backgroundColor: '#4CAF50',
                          borderColor: '#4CAF50',
                        },
                      }}
                      type="submit"
                      fullWidth
                      onClick={handleNext}
                    >
                      <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2" color="#423BFA">
                        Skip
                      </Typography>
                    </Button>
                  </Box>
                )}
                <Box mt={2}>
                  <Button
                    sx={{
                      backgroundColor: '#423BFA',
                      padding: '8px',
                      borderRadius: 2,
                      '&:hover': {
                        backgroundColor: '#4CAF50',
                      },
                    }}
                    type="submit"
                    fullWidth
                    onClick={activeStep === 6 ? handleSubmit : handleNext}
                  >
                    <Typography sx={{ fontWeight: 'bold' }} variant="subtitle2" color='white'>
                      {activeStep === 6 ? 'Submit' : 'Next'}
                    </Typography>
                  </Button>
                </Box>
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} md={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', borderRadius: 1 }}>
            <video autoPlay loop muted
              style={{
                width: '100%',
                height: '100%',
                objectFit: 'cover',
                borderRadius: '1rem'
              }}
            >
              <source src={signupVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </Grid>
        </Grid>
      </Container>
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        handleClose={handleCloseSnackbar}
      />
    </Box>
  );

}
