import React, { useState, useEffect } from 'react';
import { Drawer, Button, TextField, RadioGroup, FormControlLabel, Radio, MenuItem, Grid, Select, Avatar, InputLabel, FormControl, IconButton, Box, Typography } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import baseURL from '../../../api/baseUrl';

const EditProfileDrawer = ({ open, onClose, profileData }) => {
  const [avatarImage, setAvatarImage] = useState(null);
  const [isEditingAvatar, setIsEditingAvatar] = useState(false);
  const [hasWorkExperience, setHasWorkExperience] = useState(true);
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    designation: '',
    currentLocation: '',
    noticePeriod: '',
  });

  useEffect(() => {
    if (profileData) {
      setHasWorkExperience(profileData.hasWorkExperience || true);
      setFormData({
        fullName: profileData?.user?.fullName || '',
        email: profileData?.user?.email || '',
        phone: profileData?.user?.phone || '',
        designation: (profileData?.experiences && profileData.experiences.length > 0) ? profileData.experiences[0]?.designation || '' : '',
        currentLocation: profileData?.currentLocation || '',
        noticePeriod: profileData?.noticePeriod || '',
      });
    }
  }, [profileData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setAvatarImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const startEditAvatar = () => {
    setIsEditingAvatar(true);
  };

  const handleSaveDetails = async () => {
    try {
      const response = await fetch(`${baseURL}/jobseeker/profile/${profileData.profile._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          hasWorkExperience,
          avatarImage, // Handle this if necessary
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to update profile');
      }

      const result = await response.json();
      console.log('Profile updated successfully:', result);
      onClose(); // Close the drawer after successful update
    } catch (error) {
      console.error('Error updating profile:', error);
    }
  };

  return (
    <div>
            <Button variant="contained" onClick={() => open(true)}>Edit Profile</Button>

      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box sx={{ width: { xs: 300, sm: 600 }, p: 3 }}>
          <Typography variant="h5" textAlign="center" gutterBottom>Edit Basic Details</Typography>
          <Box sx={{ display: 'flex', justifyContent: 'center', mb: 2 }}>
            <Avatar sx={{ width: 80, height: 80 }}>
              {avatarImage ? (
                <img src={avatarImage} alt="Avatar" style={{ width: '100%', height: '100%', borderRadius: '50%' }} />
              ) : (
                <Typography variant="body1" component="span">No Avatar</Typography>
              )}
            </Avatar>
            <Box>
              {!isEditingAvatar && (
                <IconButton onClick={startEditAvatar}>
                  <EditIcon />
                </IconButton>
              )}
            </Box>
            {isEditingAvatar && (
              <>
                <input
                  type="file"
                  accept="image/*"
                  onChange={handleFileChange}
                  style={{ display: 'none' }}
                  id="avatar-input"
                />
                <label htmlFor="avatar-input">
                  <IconButton component="span">
                    <EditIcon />
                  </IconButton>
                </label>
              </>
            )}
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                label="Full Name"
                name="fullName"
                fullWidth
                margin="normal"
                value={formData.fullName}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                disabled
                label="Email"
                name="email"
                fullWidth
                margin="normal"
                value={formData.email}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <TextField
                disabled
                label="Mobile"
                name="phone"
                fullWidth
                margin="normal"
                value={formData.phone}
                onChange={handleInputChange}
              />
            </Grid>
          </Grid>
          <TextField
            disabled
            label="Designation"
            name="designation"
            fullWidth
            margin="normal"
            value={formData.designation}
            onChange={handleInputChange}
          />

          <FormControl component="fieldset" margin="normal" fullWidth>
            <RadioGroup
              row
              aria-label="candidate type"
              name="hasWorkExperience"
              value={hasWorkExperience ? "experienced" : "fresher"}
              onChange={(event) => setHasWorkExperience(event.target.value === "experienced")}
            >
              <FormControlLabel value="fresher" control={<Radio />} label="Fresher" />
              <FormControlLabel value="experienced" control={<Radio />} label="Experienced" />
            </RadioGroup>
          </FormControl>

          <TextField
            label="Current city"
            name="currentLocation"
            fullWidth
            margin="normal"
            value={formData.currentLocation}
            onChange={handleInputChange}
          />

          <FormControl fullWidth margin="normal">
            <InputLabel id="notice-period-label">Notice period</InputLabel>
            <Select
              labelId="notice-period-label"
              id="notice-period"
              name="noticePeriod"
              label="Notice period"
              value={formData.noticePeriod}
              onChange={handleInputChange}
            >
              <MenuItem value={1}>1 month</MenuItem>
              <MenuItem value={2}>2 months</MenuItem>
              <MenuItem value={3}>3 months</MenuItem>
              <MenuItem value={4}>4 months</MenuItem>
              <MenuItem value={5}>5 months</MenuItem>
              <MenuItem value={6}>6 months</MenuItem>
            </Select>
          </FormControl>

          <Button onClick={handleSaveDetails} sx={{ mt: '20px', backgroundColor: '#423BFA' }} variant="contained" fullWidth>
            Edit Details
          </Button>
        </Box>
      </Drawer>
    </div>
  );
};

export default EditProfileDrawer;
