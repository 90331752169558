import React from 'react';
import { Box, Button } from '@mui/material';

const JobFilterButtons = ({ activeFilter, onFilterClick }) => {
  return (
    <Box sx={{ borderRadius: 5, display: 'flex', gap: 2 }}>
      {['All', 'Active', 'Expired', 'Select Plan'].map((label, index) => (
        <Button 
          key={label}
          onClick={() => onFilterClick(index)}
          sx={{ 
            borderRadius: 10, 
            border: '1px solid black',
            backgroundColor: activeFilter === index ? '#423bfa' : 'transparent',
            color: activeFilter === index ? '#ffffff' : '#000000',
            textTransform: 'none', 
            fontWeight: 'bold',
            padding: '4px 8px',
            '&:hover': {
              backgroundColor: '#423bfa',
              color: '#ffffff',
            },
          }} 
        >
          {label}
        </Button>
      ))}
    </Box>
  );
};

export default JobFilterButtons;
