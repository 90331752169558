import React from 'react';
import Slider from 'react-slick';
import { Box, Button, styled } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: '#ffebe3',
  padding: theme.spacing(2),
  borderRadius: theme.spacing(2),
  position: 'relative',
  marginBottom: theme.spacing(3),
}));

const CategoryButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#ffffff',
  borderRadius: theme.shape.borderRadius,
  width: 'auto',
  minWidth: 150,
  height: 50,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  margin: theme.spacing(1),
  '&:hover': {
    backgroundColor: '#e0f7fa',
    transform: 'scale(1.05)',
    boxShadow: theme.shadows[3],
  },
  color: theme.palette.text.primary,
  textTransform: 'none',
  transition: 'all 0.3s ease',
}));

const ArrowButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#000000',
  borderRadius: '50%',
  minWidth: 'auto',
  padding: theme.spacing(0.5),
  position: 'absolute',
  top: '50%',
  transform: 'translateY(-50%)',
  zIndex: 1,
  '&:hover': {
    backgroundColor: '#000000',
  },
  color: '#ffffff',
  fontSize: '16px',
  width: '24px',
  height: '24px',
}));

const NextArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowButton style={{ right: '-24px' }} onClick={onClick}>
      <ArrowForwardIosIcon fontSize='2px'/>
    </ArrowButton>
  );
};

const PrevArrow = (props) => {
  const { onClick } = props;
  return (
    <ArrowButton style={{ left: '-24px' }} onClick={onClick}>
      <ArrowBackIosIcon fontSize='2px'/>
    </ArrowButton>
  );
};

const JobCategories = () => {
  const categories = [
    'Analytics & BI Jobs',
    'IT Jobs',
    'Remote Jobs',
    'Banking & Finance Jobs',
    'HR Jobs',
    'Foreign MNCs Jobs',
    'Work from home Jobs',
  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Container>
      <Slider {...settings}>
        {categories.map((category) => (
          <Box key={category} sx={{ display: 'flex', justifyContent: 'center' }}>
            <CategoryButton>
              {category}
            </CategoryButton>
          </Box>
        ))}
      </Slider>
    </Container>
  );
};

export default JobCategories;
