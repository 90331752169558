import React from 'react';
import { Box, FormControl, Typography, TextField, Autocomplete, Select, MenuItem } from '@mui/material';

const options = {
  preferredJobTypes: ['fullTime', 'partTime', 'contract', 'internship'],
  preferredShifts: ['day', 'night', 'flexible'],
  preferredWorkplaces: ['onSite', 'remote', 'hybrid'],
};

const JobSeekerCaseSix = ({ formData, handleChange }) => {

  // Function to handle changes for Autocomplete fields
  const handleAutocompleteChange = (name) => (event, newValue) => {
    console.log('handleAutocompleteChange:', { name, newValue }); // Debugging line

    // Ensure the value is valid before updating formData
    const validOptions = options[name] || [];
    if (Array.isArray(newValue)) {
      // Filter out invalid values
      const validValues = newValue.filter(value => validOptions.includes(value));
      handleChange({ target: { name, value: validValues } });
    } else {
      handleChange({ target: { name, value: [] } });
    }
  };

  // Function to handle changes for Select field
  const handleSelectChange = (event) => {
    handleChange({ target: { name: 'preferredEmploymentType', value: event.target.value } });
  };

  return (
    <Box sx={{ backgroundColor: '#ffffff', padding: 2, borderRadius: 4 }}>
      <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
        Preferred Job Type
      </Typography>
      <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: 2 }}>
        <Autocomplete
          multiple
          id="preferred-job-type"
          options={options.preferredJobTypes}
          value={formData.preferredJobTypes || []}
          onChange={handleAutocompleteChange('preferredJobTypes')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Preferred Job Type"
              size="small"
              sx={{ marginBottom: 2 }}
            />
          )}
        />
      </FormControl>

      <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
        Preferred Shift
      </Typography>
      <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: 2 }}>
        <Autocomplete
          multiple
          id="preferred-shift"
          options={options.preferredShifts}
          value={formData.preferredShifts || []}
          onChange={handleAutocompleteChange('preferredShifts')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Preferred Shift"
              size="small"
              sx={{ marginBottom: 2 }}
            />
          )}
        />
      </FormControl>

      <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
        Preferred Workplace
      </Typography>
      <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: 2 }}>
        <Autocomplete
          multiple
          id="preferred-workplace"
          options={options.preferredWorkplaces}
          value={formData.preferredWorkplaces || []}
          onChange={handleAutocompleteChange('preferredWorkplaces')}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Preferred Workplace"
              size="small"
              sx={{ marginBottom: 2 }}
            />
          )}
        />
      </FormControl>

      <Typography component="legend" variant="body1" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
        Preferred Employment Type
      </Typography>
      <FormControl fullWidth variant="outlined" size="small" sx={{ marginBottom: 2 }}>
        <Select
          name="preferredEmploymentType"
          value={formData.preferredEmploymentType || ''}
          onChange={handleSelectChange}
          displayEmpty
          renderValue={(selected) => selected || 'Preferred Employment Type'}
        >
          <MenuItem value="permanent">Permanent</MenuItem>
          <MenuItem value="temporary">Temporary</MenuItem>
        </Select>
      </FormControl>
    </Box>
  );
};

export default JobSeekerCaseSix;
