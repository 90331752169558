import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomCheckbox from '../../CustomCheckbox'; // Adjust the import path as necessary

const EducationFilter = ({ formData, handleChange }) => {
  const [checkedItems, setCheckedItems] = useState({
    tenth: false,
    twelfth: false,
    diploma: false,
    undergraduate: false,
    graduate: false,
    postGraduate: false,
    it: false,
  });

  const handleCheckboxChange = (event) => {
    setCheckedItems({
      ...checkedItems,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>
          Education Level
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={0.5}>
          {/* <Grid item xs={12}>
            <CustomCheckbox
              label="10th Only"
              checked={checkedItems.tenth}
              onChange={handleCheckboxChange}
              name="tenth"
             
            />
          </Grid> */}
          {/* <Grid item xs={12}>
            <CustomCheckbox
              label="12th/PUC Only"
              checked={checkedItems.twelfth}
              onChange={handleCheckboxChange}
              name="twelfth"
             
            />
          </Grid> */}
          <Grid item xs={12}>
            <CustomCheckbox
              label="Under Graduate"
              checked={checkedItems.diploma}
              onChange={handleCheckboxChange}
              name="diploma"
             
            />
          </Grid>
      
          <Grid item xs={12}>
            <CustomCheckbox
              label="Graduate"
              checked={checkedItems.graduate}
              onChange={handleCheckboxChange}
              name="graduate"
             
            />
          </Grid>
          <Grid item xs={12}>
            <CustomCheckbox
              label="Post Graduate Only"
              checked={checkedItems.postGraduate}
              onChange={handleCheckboxChange}
              name="postGraduate"
             
            />
          </Grid>
          {/* <Grid item xs={12}>
            <CustomCheckbox
              label="ITI Only"
              checked={checkedItems.iti}
              onChange={handleCheckboxChange}
              name="iti"
              
            />
          </Grid> */}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default EducationFilter;
