import React, { useState, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  IconButton,
  Box,
  TextField,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddIcon from '@mui/icons-material/Add';
import LinearProgress, { linearProgressClasses } from '@mui/material/LinearProgress';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/system';
import PersonRoundedIcon from '@mui/icons-material/PersonRounded';
import DownhillSkiingTwoToneIcon from '@mui/icons-material/DownhillSkiingTwoTone';
import Diversity3TwoToneIcon from '@mui/icons-material/Diversity3TwoTone';
import SchoolTwoToneIcon from '@mui/icons-material/SchoolTwoTone';
import CollectionsBookmarkTwoToneIcon from '@mui/icons-material/CollectionsBookmarkTwoTone';
import AdUnitsIcon from '@mui/icons-material/AdUnits';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FacebookIcon from '@mui/icons-material/Facebook';
import baseURL from '../../../api/baseUrl';
import WorkExpierenceModal from './WorkExpierenceModal';
import EducationModal from './EductionModal';
import SkillsModal from './SkillsModal';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? '#423BFA' : '#308fe8',
  },
}));

const ProfileSection = ({ profileData, setProfileData }) => {
  const [coverImage, setCoverImage] = useState("https://via.placeholder.com/600x200");
  const [showPhoneNumberField, setShowPhoneNumberField] = useState(false);
  const [showSocialMediaField, setShowSocialMediaField] = useState(false);

  const [educationOpen, setEducationOpen] = useState(false);
  const [selectedEducation, setSelectedEducation] = useState(null);

  const [openSkillsModal, setOpenSkillsModal] = useState(false);
  const [selectedSkill, setSelectedSkill] = useState([]);


  const handleOpenSkillsModal = () => setOpenSkillsModal(true);
  const handleCloseSkillsModal = () => setOpenSkillsModal(false);

  const [open, setOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(null);

  useEffect(() => {
    console.log('Profile data:', profileData);
    console.log('Profile skills:', profileData?.profile?.skills);
  
    if (profileData?.profile?.skills?.length) {
      setSelectedSkill(profileData?.profile?.skills);
    } else {
      setSelectedSkill(['']);
    }
  }, [profileData]);
  
  

  

  useEffect(() => {
    // Calculate progress based on available data
    const totalSections = 5;
    let sectionsWithData = 0;

    if (profileData?.about) sectionsWithData += 1; // Assuming "About" section is present
    if (profileData?.profile?.skills?.length) sectionsWithData += 1; // Assuming "Skills" section has data
    if (profileData?.experiences?.length) sectionsWithData += 1; // Assuming "Work Experience" section has data
    if (profileData?.education?.length) sectionsWithData += 1; // Assuming "Education" section has data
    if (profileData?.projects?.length) sectionsWithData += 1; // Assuming "Projects" section has data

    const progress = (sectionsWithData / totalSections) * 100;
    setProgress(progress);
  }, [profileData]);

  const [progress, setProgress] = useState(0);

  const handleCoverImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const img = new Image();
        img.src = e.target.result;
        img.onload = () => {
          const canvas = document.createElement('canvas');
          const ctx = canvas.getContext('2d');
          const width = 600; // desired width
          const height = 200; // desired height
          canvas.width = width;
          canvas.height = height;
          ctx.drawImage(img, 0, 0, width, height);
          const resizedImage = canvas.toDataURL('image/png');
          setCoverImage(resizedImage);
        };
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOpen = (experience) => {
    setSelectedExperience(experience);
    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  const handleEditChange = (e) => {
    setSelectedExperience({
      ...selectedExperience,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = () => {
    const url = `${baseURL}/jobseeker/experience/${selectedExperience._id}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedExperience),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        const updatedExperiences = profileData.experiences.map(exp =>
          exp._id === selectedExperience._id ? selectedExperience : exp
        );
        setProfileData({ ...profileData, experiences: updatedExperiences });

        handleClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleEducationOpen = (education) => {
    setSelectedEducation(education);
    setEducationOpen(true);
  };

  const handleEducationClose = () => setEducationOpen(false);

  const handleEducationEditChange = (e) => {
    setSelectedEducation({
      ...selectedEducation,
      [e.target.name]: e.target.value,
    });
  };

  const handleEducationSave = () => {
    const url = `${baseURL}/jobseeker/education/${selectedEducation._id}`;
    fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(selectedEducation),
    })
      .then((response) => response.json())
      .then((data) => {
        console.log('Success:', data);

        const updatedEducation = profileData.education.map(ed =>
          ed._id === selectedEducation._id ? selectedEducation : ed
        );
        setProfileData({ ...profileData, education: updatedEducation });

        handleEducationClose();
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };

  const handleSaveSkill = async (newSkill) => {
    try {
      // Update local state
      setSelectedSkill(newSkill);

      // Prepare the updated profile data
      const updatedProfileData = {
        ...profileData,
        profile: {
          ...profileData.profile,
          skills: [newSkill], // Update skills array
        },
      };

      // API call to update the profile
      const response = await fetch(`${baseURL}/jobseeker/profile/${profileData.profile._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedProfileData),
      });

      if (!response.ok) {
        throw new Error('Failed to update skill');
      }

      // Optionally update local state with the response data
      const result = await response.json();
      setProfileData(result);

    } catch (error) {
      console.error('Error updating skill:', error);
    } finally {
      handleCloseSkillsModal();
    }
  };
  console.log('profileData in skills', profileData?.profile?.skills);
  

  return (
    <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2, maxWidth: '100%', mx: 'auto', boxShadow: 3 }}>
      <Box sx={{ position: 'relative', mb: 3 }}>
        <img
          src={coverImage}
          alt="cover"
          style={{ width: '100%', borderRadius: '10px' }}
        />
        <IconButton
          component="label"
          sx={{ position: 'absolute', top: 8, right: 8, bgcolor: 'background.paper' }}
          aria-label="edit cover image"
        >
          <EditIcon />
          <input
            type="file"
            accept="image/*"
            hidden
            onChange={handleCoverImageChange}
          />
        </IconButton>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        <Typography variant="body2" color="error" sx={{ flex: 1 }}>
          {`${progress}% completed`}
        </Typography>
      </Box>
      <BorderLinearProgress variant="determinate" value={progress} sx={{ mb: 2, boxShadow: 2 }} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography><AdUnitsIcon sx={{ fontSize: '20px' }} />Add Mobile Number</Typography>
        <Button variant="text" onClick={() => setShowPhoneNumberField(!showPhoneNumberField)} aria-label="add phone number">
          <AddIcon />
        </Button>
      </Box>
      {showPhoneNumberField && (
        <Box sx={{ mb: 2 }}>
          <TextField label="Phone Number" variant="outlined" size="small" sx={{ mr: 2 }} />
          <Button variant="contained" sx={{ mr: 2, backgroundColor: '#423BFA', borderRadius: '1rem' }}>Send OTP</Button>
          <Button variant="text" color="error" onClick={() => setShowPhoneNumberField(false)} sx={{ backgroundColor: 'grey', color: 'red' }}>
            Cancel
          </Button>
        </Box>
      )}

      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Typography><FacebookIcon />Add Social Media Links</Typography>
        <Button variant="text" onClick={() => setShowSocialMediaField(!showSocialMediaField)} aria-label="add social media link">
          <AddIcon />
        </Button>
      </Box>
      {showSocialMediaField && (
        <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
          <LinkedInIcon /><TextField label="Social Media Link" variant="outlined" size="small" sx={{ mr: 2 }} />
          <Button variant="contained" sx={{ mr: 2, backgroundColor: '#423BFA', borderRadius: '1rem' }}>Add Link</Button>
          <Button variant="text" color="error" onClick={() => setShowSocialMediaField(false)} sx={{ backgroundColor: 'grey', color: 'red' }}>
            Cancel
          </Button>
        </Box>
      )}

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          sx={{ display: 'flex', alignItems: 'center' }}
        >
          <PersonRoundedIcon sx={{ mr: 2, fontSize: '2rem' }} />
          <Typography variant='h6'>About</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* Your content for the "About" section goes here */}
          </Typography>
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', alignItems: 'center' }}>
          <DownhillSkiingTwoToneIcon sx={{ mr: 2, fontSize: '2rem' }} />
          <Typography variant='h6'>Skills</Typography>
        </AccordionSummary>
        <AccordionDetails>
        {profileData?.profile.skills && profileData?.profile.skills.length > 0 ? (
            profileData?.profile.skills.map((skills) => (
              <><Typography>{skills}</Typography><Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>

                <Button startIcon={<EditIcon />}
                  onClick={handleOpenSkillsModal} variant="contained" color="primary" sx={{ px: 2, boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)', borderRadius: '20px', background: '#423bfa' }}
                >
                  Edit
                </Button>
              </Box></>
            ))
          ) : (
            <Typography>No Skills available</Typography>
          )}
        </AccordionDetails>
      </Accordion>

      <SkillsModal
        open={openSkillsModal}
        handleClose={handleCloseSkillsModal}
        skill={selectedSkill}
        handleSave={handleSaveSkill}
      />

      
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', alignItems: 'center' }}>
          <Diversity3TwoToneIcon sx={{ mr: 2, fontSize: '2rem' }} />
          <Typography variant="h6">Work Experience</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {profileData?.experiences && profileData?.experiences.length > 0 ? (
            profileData?.experiences.map((experience) => (
              <div key={experience._id} style={{ marginBottom: '1rem' }}>
                <Typography variant="subtitle1">
                  <strong>Company:</strong> {experience.company}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Designation:</strong> {experience.designation}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Job Role:</strong> {experience.jobRole.join(', ')}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Industry:</strong> {experience.industry}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>CTC:</strong> {experience.ctc} LPA
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    variant="contained"
                    sx={{ px: 2, boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)', borderRadius: '20px', background: '#423bfa' }}

                    startIcon={<EditIcon />}
                    onClick={() => handleOpen(experience)}
                  >
                    Edit
                  </Button>
                </Box>
              </div>
            ))
          ) : (
            <Typography>No experiences available</Typography>
          )}
        </AccordionDetails>
      </Accordion>


      {/* Modal for editing work experience */}
      {selectedExperience && (
        <WorkExpierenceModal open={open} handleClose={handleClose} handleEditChange={handleEditChange} selectedExperience={selectedExperience} handleSave={handleSave} />
      )}

      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', alignItems: 'center' }}>
          <SchoolTwoToneIcon sx={{ mr: 2, fontSize: '2rem' }} />
          <Typography variant='h6'>Education</Typography>
        </AccordionSummary>
        <AccordionDetails>
          {profileData?.educations && profileData?.educations.length > 0 ? (
            profileData?.educations.map((education) => (
              <div key={education._id} style={{ marginBottom: '1rem' }}>
                <Typography variant="subtitle1">
                  <strong>Degree:</strong> {education.degree}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Institution:</strong> {education.institution}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Field of Study:</strong> {education.fieldOfStudy}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Graduation Year:</strong> {education.graduationYear}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Start Date:</strong> {education.startDate}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>End Date:</strong> {education.endDate}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Grade:</strong> {education.grade}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Activities and Societies:</strong> {education.activitiesAndSocieties}
                </Typography>
                <Typography variant="subtitle1">
                  <strong>Description:</strong> {education.description}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
                  <Button
                    variant="contained"
                    sx={{ px: 2, boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)', borderRadius: '20px', background: '#423bfa' }}
                    startIcon={<EditIcon />}
                    onClick={() => handleEducationOpen(education)}
                  >
                    Edit
                  </Button>
                </Box>
              </div>
            ))
          ) : (
            <Typography>No education data available</Typography>
          )}
        </AccordionDetails>
      </Accordion>


      {selectedEducation && (
        <EducationModal
          open={educationOpen}
          handleClose={handleEducationClose}
          handleEditChange={handleEducationEditChange}
          selectedEducation={selectedEducation}
          handleSave={handleEducationSave}
        />
      )}


      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ display: 'flex', alignItems: 'center' }}>
          <CollectionsBookmarkTwoToneIcon sx={{ mr: 2, fontSize: '2rem' }} />
          <Typography variant='h6'>Project</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
            {/* Your content for the "Project" section goes here */}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default ProfileSection;
