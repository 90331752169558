import React, { useCallback, useState } from 'react';
import { Box, Typography, Divider, IconButton, Drawer, useMediaQuery, useTheme, Card } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import SortByComponent from '../../SortByFilters';
import SalaryFilter from '../../SalaryFilter';
import DatePostedComponent from '../../DatePosted';
import HighestEducationFilter from '../../HigherEducationFilter';
import WorkModeFilter from '../../WorkModeFilter';
import WorkTypeFilter from '../../WorkTypeFilter';
import WorkShiftFilter from '../../WorkShiftFilter';
import ExperienceComponent from '../../ExperienceComponent';
import DepartmentFilterComponent from '../../DepartmentFilterComponent';
import CompanyTypeFilterComponent from '../../CompanyFilterComponent';
import LocationFilterComponent from '../../LocationFilter';

const Filters = ({ onSortByChange, onExperienceChange, experience, onSalaryChange, salary, selectedLocations, setSelectedLocations, onDatePostedChange, }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const [open, setOpen] = useState(false);
    const [sortBy, setSortBy] = useState('relevant');
    const [datePosted, setDatePosted] = useState('all');
    const [highestEducation, setHighestEducation] = useState('graduate'); // Ensure this is set to your default


    const toggleDrawer = useCallback((open) => () => {
        setOpen(open);
        console.log(`Drawer ${open ? 'opened' : 'closed'}`);
    }, []);

    const handleSortByChange = useCallback((event) => {
        const value = event.target.value;
        setSortBy(value);
        onSortByChange(value);
        console.log(`Sort By Filter Changed: ${value}`);
    }, [onSortByChange]);

    const handleDatePostedChange = useCallback((event) => {
        const value = event.target.value;
        setDatePosted(value);
        console.log(`Date Posted Filter Changed: ${value}`);
        onDatePostedChange(value);
    }, [onDatePostedChange]);

    const handleExperienceChange = useCallback((event, newValue) => {
        onExperienceChange(newValue);
        console.log(`Experience Filter Changed: ${newValue}`);
    }, [onExperienceChange]);

    const handleSalaryChange = useCallback((newSalaryRanges) => {
        onSalaryChange(newSalaryRanges);
        console.log(`Salary Filter Changed: ${JSON.stringify(newSalaryRanges)}`);
    }, [onSalaryChange]);

    const handleHighestEducationChange = (event) => {
        const newValue = event.target.value;
        setHighestEducation(newValue);
    };


    const filterContent = (
        <Box p={1}>
            <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ mb: 2 }}>
                <Typography fontWeight="bold" fontSize="16px">All Filters</Typography>
                <Typography color="primary" fontSize="14px">Applied(3)</Typography>
            </Box>
            <Divider />
            <SortByComponent sortBy={sortBy} onSortByChange={handleSortByChange} />
            <Divider />
            <DepartmentFilterComponent />
            <Divider />
            <ExperienceComponent experience={experience} onExperienceChange={handleExperienceChange} />
            <Divider />
            <SalaryFilter salary={salary} onSalaryChange={handleSalaryChange} />
            <Divider />
            <CompanyTypeFilterComponent />
            <Divider />
            <LocationFilterComponent selectedLocations={selectedLocations} setSelectedLocations={setSelectedLocations} />
            <Divider />
            <DatePostedComponent datePosted={datePosted} onDatePostedChange={handleDatePostedChange} />
            <Divider />
            <HighestEducationFilter highestEducation={highestEducation}
                onHighestEducationChange={handleHighestEducationChange} />
            <Divider />
            <WorkModeFilter />
            <Divider />
            <WorkTypeFilter />
            <Divider />
            <WorkShiftFilter />
            <Divider />
        </Box>
    );

    return (
        <Box>
            {isMobile ? (
                <>
                    <Typography variant="h6">Filters</Typography>
                    <IconButton onClick={toggleDrawer(true)}>
                        <FilterAltIcon />
                    </IconButton>
                    <Drawer anchor="left" open={open} onClose={toggleDrawer(false)}>
                        {filterContent}
                    </Drawer>
                </>
            ) : (
                <Card variant="outlined">{filterContent}</Card>
            )}
        </Box>
    );
};

export default Filters;
