import React from 'react';
import { Typography, Box, Grid, Paper, Accordion, AccordionSummary, AccordionDetails, IconButton, Divider } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

const EmployerCaseOneDetails = ({ CaseOneJobData }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        sx={{ padding: 0 }}
      >
        <Paper variant="outlined" sx={{ padding: 0.5, backgroundColor: '#f0f7ff', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography>Candidate Requirements</Typography>
            </Box>
            <IconButton>
              <EditIcon />
            </IconButton>
          </Box>
        </Paper>
      </AccordionSummary>
      <AccordionDetails>
        <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
          <Box sx={{ marginTop: 2 }}>
            <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, backgroundColor: '#f0f7ff' }}>
              <Box display="flex" alignItems="center">
                <InfoIcon color="primary" sx={{ marginRight: 1 }} />
                <Box>
                  <Typography>Eligible requirements</Typography>
                  <Typography variant="body2">Your job will only be visible to the candidates who meet these requirements.</Typography>
                </Box>
              </Box>
            </Paper>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Minimum Education</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.minEducation}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Experience Required</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.experienceType}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">English</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.englishLevel}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Gender</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.gender}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Candidate Titles</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.otherJobRoleApply}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Candidate job role / category</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.otherJobTitleApply}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Industry</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">None</Typography>
              </Grid>
            </Grid>
            <Divider sx={{ width: '100%', marginY: 2 }} />
          </Box>
          <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2, backgroundColor: '#f0f7ff' }}>
            <Box display="flex" alignItems="center">
              <InfoIcon color="primary" sx={{ marginRight: 1 }} />
              <Box>
                <Typography>Preferred requirements</Typography>
                <Typography variant="body2">Your job will be promoted to the candidates meeting below requirements, but others can still apply.</Typography>
              </Box>
            </Box>
          </Paper>
          <Box sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Age</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">18 - 60 yrs</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Gender</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.gender}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Candidate Titles</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.jobTitle}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Candidate job role / category</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.otherJobRoleApply}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography variant="subtitle1">Industry</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">None</Typography>
              </Grid>
              <Divider sx={{ width: '100%', marginY: 2 }} />
              <Grid item xs={4}>
                <Typography variant="subtitle1">Job Description</Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2">{CaseOneJobData?.jobPosting.jobDescription}</Typography>
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </AccordionDetails>
    </Accordion>
  );
};

export default EmployerCaseOneDetails;
