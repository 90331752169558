import React from 'react';
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";

import Typography from "@mui/material/Typography";

import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";

import VacanziLogo from '../../assets/icons/logos/vacanzi_30*40.png';
import { ThemeProvider, createTheme } from '@mui/material/styles';



function NonRegisteredAppBar() {



  const lightTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#EDEDFC',
      },
    },
  });



  return (
    <ThemeProvider theme={lightTheme}>
      <AppBar position="static">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Avatar src={VacanziLogo} alt="Vacanzi Logo" sx={{ width: 56, height: 56, display: { xs: "none", md: "flex" } }} />

            <Typography
              variant="h4"
              noWrap
              component="a"
              // href="/"
              sx={{
                mr: 1,
                display: { xs: "none", md: "flex" },
                fontFamily: "GlanceSansBold",
                color: "#423BFA",
                textDecoration: "none",
              }}
            >
              Vacanzi
            </Typography>
            <Avatar src={VacanziLogo} alt="Vacanzi Logo" sx={{
              width: 46, height: 46,
              display: { xs: "block", md: "none" },
            }} />

            <Typography
              variant="h6"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                display: { xs: "flex", md: "none" },
                flexGrow: 1,
                fontFamily: "GlanceSansBold",
                fontWeight: 700,
                color: "#423BFA",
                textDecoration: "none",
              }}
            >
              Vacanzi
            </Typography>









          </Toolbar>
        </Container>
      </AppBar>
    </ThemeProvider>
  );
}

export default NonRegisteredAppBar;
