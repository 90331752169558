import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Typography,
  Container,
  Grid
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import EmployerDrawer from '../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer';
import { jwtDecode } from 'jwt-decode';

const EmployerProfile = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [showCompanyDetails, setShowCompanyDetails] = useState(false);
  const [userData, setUserData] = useState({}); // Initialize as empty object

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      try {
        const decodedToken = jwtDecode(token);
        setUserData(decodedToken); // Set user data from decoded token
      } catch (error) {
        console.error('Error decoding token:', error);
      }
    }
  }, []);

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleSaveClick = () => {
    setIsEditing(false);
    setSnackbarOpen(true);
  };

  const handleVerifyClick = () => {
    setShowCompanyDetails(true);
    setSnackbarOpen(true);
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <EmployerDrawer>
      <Container>
        <Box sx={{ p: 3, bgcolor: 'background.paper', borderRadius: 2, boxShadow: 3 }}>
          <Grid container justifyContent="space-between" alignItems="center" mb={2}>
            <Grid item xs={12} sm={6}>
              <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                Profile
              </Typography>
            </Grid>
            <Grid item xs={12} sm="auto">
              {isEditing ? (
                <Button
                  variant="contained"
                  onClick={handleSaveClick}
                  sx={{ bgcolor: '#423bfa', '&:hover': { bgcolor: '#423bfa' } }}
                >
                  Save
                </Button>
              ) : (
                <Button
                  variant="contained"
                  onClick={handleEditClick}
                  sx={{ bgcolor: '#423bfa', '&:hover': { bgcolor: '#423bfa' } }}
                >
                  Edit
                </Button>
              )}
            </Grid>
          </Grid>

          <Box mb={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>Basic Details</Typography>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                  value={userData?.fullName || ''}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                  value={userData?.email || ''}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TextField
                  label="Mobile"
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                  defaultValue={userData.mobile || "9776170950"}
                />
              </Grid>
            </Grid>
          </Box>

          <Box mb={2}>
            <Typography variant="h6" sx={{ fontWeight: 'bold' }}>GST Details</Typography>
            <Grid container spacing={2} mt={1}>
              <Grid item xs={12} sm={6}>
                <TextField
                  label="GST No."
                  variant="outlined"
                  fullWidth
                  disabled={!isEditing}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                <Button
                  variant="outlined"
                  onClick={handleVerifyClick}
                  disabled={!isEditing}
                  sx={{ alignSelf: 'center' }}
                >
                  Verify
                </Button>
              </Grid>
            </Grid>
            {showCompanyDetails && (
              <Box sx={{ mt: 2, p: 2, bgcolor: 'background.default', borderRadius: 1, boxShadow: 1 }}>
                <Typography variant="body1"><strong>Company name:</strong> MILLION TALENTS INDIA PRIVATE LIMITED</Typography>
                <Typography variant="body1"><strong>Address:</strong> 10/1, V K PLAZA, RAMAMURTHY NAGAR MAIN ROAD, BANASWADI, BENGALURU URBAN, Bengaluru Urban, Karnataka 560043</Typography>
                <Typography variant="body2" sx={{ mt: 1 }}>
                  I verify my company details and understand that the invoices would be generated using the same information.
                </Typography>
              </Box>
            )}
          </Box>

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleCloseSnackbar}
            message={showCompanyDetails ? "Company details verified" : "Profile updated successfully"}
            action={
              <IconButton size="small" aria-label="close" color="inherit" onClick={handleCloseSnackbar}>
                <CloseIcon fontSize="small" />
              </IconButton>
            }
          />
        </Box>
      </Container>
    </EmployerDrawer>
  );
};

export default EmployerProfile;
