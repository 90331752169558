import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControl, FormGroup, FormControlLabel, Checkbox, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const WorkModeFilter = ({ workModes, onWorkModeChange }) => {
  const [checkedWorkModes, setCheckedWorkModes] = useState(workModes || []);

  const handleCheckboxChange = (event) => {
    const { name } = event.target;
    const newCheckedWorkModes = checkedWorkModes.includes(name)
      ? checkedWorkModes.filter((mode) => mode !== name)
      : [...checkedWorkModes, name];
    setCheckedWorkModes(newCheckedWorkModes);
    onWorkModeChange(newCheckedWorkModes);
  };

  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Work Mode</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" fullWidth>
          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox
                  name="workFromHome"
                  checked={checkedWorkModes.includes('workFromHome')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkModes.includes('workFromHome') ? 'bold' : 'normal', fontSize: '14px' }}>Work from home</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="workFromOffice"
                  checked={checkedWorkModes.includes('workFromOffice')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkModes.includes('workFromOffice') ? 'bold' : 'normal', fontSize: '14px' }}>Work from office</Typography>}
            />
            <FormControlLabel
              control={
                <Checkbox
                  name="workFromField"
                  checked={checkedWorkModes.includes('workFromField')}
                  onChange={handleCheckboxChange}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedWorkModes.includes('workFromField') ? 'bold' : 'normal', fontSize: '14px' }}>Work from field</Typography>}
            />
          </FormGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default WorkModeFilter;
