import React, { useState } from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomMultiSelect from '../../CustomMultiSelect';

// Define options for companies
const companyOptions = [
  'Google',
  'Microsoft',
  'Apple',
  'Amazon',
  'Facebook',
  'IBM',
  'Intel',
  'Oracle',
  'Salesforce',
  'SAP',
  'Adobe',
  'Netflix',
  'Twitter',
  'Cisco',
  'Qualcomm',
  'Dell',
  'HP',
  'Nvidia',
  'PayPal',
  'Uber',
  'Airbnb',
];

const CandidateCompany = () => {
  const [selectedCompanies, setSelectedCompanies] = useState([]);

  return (
    <Accordion >
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Previous Companies</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <CustomMultiSelect
          placeholder="Select previous companies"
          options={companyOptions}
          selectedOptions={selectedCompanies}
          setSelectedOptions={setSelectedCompanies}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateCompany;
