import React from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Typography, Grid
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAutocomplete from '../../CustomAutoComplete';

const ageOptions = ['18', '20', '25', '30', '35', '40', '45', '50', '55', '60'];

const CandidateAge = ({ formData, handleChange }) => {
  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Age</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CustomAutocomplete
              placeholder="Min age"
              options={ageOptions}
            //   value={formData.minAge}
              onChange={(newValue) => handleChange({ target: { name: 'minAge', value: newValue } })}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              placeholder="Max age"
              options={ageOptions}
            //   value={formData.maxAge}
              onChange={(newValue) => handleChange({ target: { name: 'maxAge', value: newValue } })}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateAge;
