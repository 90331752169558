import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Box, Chip, Grid, Divider, IconButton, Button, useMediaQuery, Container } from '@mui/material';
import { ChevronLeft, Star } from '@mui/icons-material';
import NavBar from '../../../JobSeekerComponents/NavBar/NavBar';
import PropTypes from 'prop-types';
import Footer from '../../Footer/Footer';
import companieslogo from '../../../../assets/icons/logos/vacanzi_30*40.png'; // Adjust the path as needed
import CustomSnackbar from '../../../AdvancedComponents/CustomSnackbar'; // Adjust the path as needed
import baseURL from '../../../../api/baseUrl';

const JobDetailComponent = ({ job }) => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)');

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarType, setSnackbarType] = useState('success');

  if (!job) return <Typography variant="h6">Job not found</Typography>;

  const handleApplyJob = async () => {
    try {
      const authToken = localStorage.getItem('authToken');
      if (!authToken) {
        setSnackbarMessage('User not authenticated');
        setSnackbarType('error');
        setSnackbarOpen(true);
        return;
      }

      const response = await fetch(`${baseURL}/jobs/${job._id}/apply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        }
      });

      if (!response.ok) {
        throw new Error('Failed to apply for the job');
      }

      setSnackbarMessage('Job application successful!');
      setSnackbarType('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error applying for the job:', error);
      setSnackbarMessage('Error applying for the job');
      setSnackbarType('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  return (
    <>
      <NavBar />
      <Box sx={{ p: 2 }}>
        <IconButton color="primary" onClick={() => navigate('/JobSeekerFilter')} sx={{ mb: 2 }}>
          <ChevronLeft />
        </IconButton>
        <Container>
          <Card variant="outlined" sx={{ mb: 2, p: isMobile ? 1 : 2 }}>
            <CardContent>
              <Grid container spacing={isMobile ? 1 : 2}>
                <Grid item xs={12}>
                  <Box display="flex" flexDirection={isMobile ? 'column' : 'row'} justifyContent="space-between" alignItems="flex-start">
                    <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                      <Typography variant="h5" sx={{ fontWeight: 'bold' }}>
                        {job.jobTitle}
                      </Typography>
                      <Box display="flex" alignItems="center" sx={{ marginBottom: isMobile ? 1 : 0 }}>
                        <Typography variant="subtitle1" gutterBottom>
                          {job?.hiringFor}
                        </Typography>
                        {job.rating && (
                          <>
                            <Star sx={{ ml: 1, mr: 0.5, fontSize: '15px' }} color="warning" />
                            <Typography variant="body2" color="textSecondary">
                              {job.rating} ({job.reviews} Reviews)
                            </Typography>
                          </>
                        )}
                      </Box>
                    </Box>
                    <Box sx={{ marginBottom: isMobile ? 1 : 0 }}>
                      <img height={isMobile ? 30 : 40} width={isMobile ? 30 : 40} src={companieslogo} alt="company logo" />
                    </Box>
                  </Box>
                  <Divider sx={{ my: isMobile ? 1 : 2 }} />
                  <Grid container spacing={isMobile ? 1 : 2}>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Experience Type
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {job.experienceType} ({job.minExperience} - {job.totalExpRequired} years)
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Salary
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        ₹ {job.minFixedSalary} - {job.maxFixedSalary} per annum
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Location
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        {job.jobCity}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                        Skills
                      </Typography>
                      <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {Array.isArray(job.skillPreferences) && job.skillPreferences.map((skill, index) => (
                          <Chip key={index} label={skill} />
                        ))}
                      </Box>
                    </Grid>
                  </Grid>
                  <Box sx={{ mt: 2 }}>
                    <Typography variant="body1" sx={{ fontWeight: 'bold', mb: 1 }}>
                      Job Description
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }}>
                      {job.jobDescription}
                    </Typography>
                  </Box>
                  <Box display="flex" justifyContent="space-between" mt={2}>
                    <Typography variant="body2" color="textSecondary">
                      Posted on: {new Date(job.postedDate).toDateString()}
                    </Typography>
                    <Box>
                     
                      <Button variant="contained" color="primary" onClick={handleApplyJob}>
                        Apply Job
                      </Button>
                    </Box>
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Container>
      </Box>
      <Footer />
      <CustomSnackbar
        open={snackbarOpen}
        message={snackbarMessage}
        type={snackbarType}
        handleClose={handleCloseSnackbar}
      />
    </>
  );
};

JobDetailComponent.propTypes = {
  job: PropTypes.shape({
    _id: PropTypes.string.isRequired,
    jobTitle: PropTypes.string.isRequired,
    hiringFor: PropTypes.string.isRequired,
    jobCity: PropTypes.string.isRequired,
    minFixedSalary: PropTypes.number.isRequired,
    maxFixedSalary: PropTypes.number.isRequired,
    experienceType: PropTypes.string.isRequired,
    minExperience: PropTypes.number.isRequired,
    totalExpRequired: PropTypes.number.isRequired,
    skillPreferences: PropTypes.arrayOf(PropTypes.string).isRequired,
    jobDescription: PropTypes.string.isRequired,
    postedDate: PropTypes.string.isRequired,
    rating: PropTypes.number,
    reviews: PropTypes.number,
  }).isRequired,
};

export default JobDetailComponent;
