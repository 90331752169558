import React from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MuiOtpInput } from 'mui-one-time-password-input';
import VacanziLogo from '../../assets/icons/logos/vacanzi_30*40.png';

const OTPPage = ({ handleBack, loginPhone, signupPhone, handleFieldChange, handleResendOtp, canResend, timer, otp, errors, handleOtpSubmit }) => {
  


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                height: '100vh',
                p: 2
            }}
        >
            <IconButton onClick={handleBack} sx={{ position: 'absolute', top: 16, left: 16 }}>
                <ArrowBackIcon />
            </IconButton>
            <img
                src={VacanziLogo}
                alt="Vacanzi Logo"
                style={{ width: 80, height: 80, marginBottom: 60, }}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                <Typography variant="h6" gutterBottom>
                    You have received an OTP on {loginPhone || signupPhone}
                </Typography>
                <IconButton
                    onClick={handleBack}
                    sx={{ ml: 1, p: 0, color: 'black', borderRadius: '50%' }}
                >
                    <EditOutlinedIcon />
                </IconButton>
            </Box>
            <MuiOtpInput
                length={6}
                value={otp}
                onChange={(value) => handleFieldChange('otp', value)}
                error={!!errors.otp}

                sx={{ '& .MuiInputBase-root': { backgroundColor: 'white', width: '2.5rem', height: '2.5rem', margin: '0 0.2rem' } }}
                inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
            />

            <Typography color="error" sx={{ mt: 1 }}>{errors.otp}</Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2 }}>
                {canResend ? (
                    <>
                        <Typography>Didn't receive it?</Typography>
                        <Button color="primary" onClick={handleResendOtp} sx={{ ml: 1 }}>Resend OTP</Button>
                    </>
                ) : (
                    <Typography>Resend OTP in {timer}s</Typography>
                )}
            </Box>
            <Button
                fullWidth
                variant="contained"
                sx={{ mt: 2, backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold', padding: '0.5rem' }}
                onClick={handleOtpSubmit}
            >
                Submit OTP
            </Button>
        </Box>
    );
}

export default OTPPage;
