import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControlLabel,
  Checkbox,
  Grid,
  Box,
  Divider,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Snackbar,
  Alert,
} from "@mui/material";
import baseURL from "../../../api/baseUrl";

const RecruiterModal = ({ open, handleClose, user }) => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    email: "",
    password: "",
    role: "manager",
    phone: "",
    reportingManagerId: "",
    permissions: {
      reviewCandidates: false,
      jobPosting: false,
      databaseSearch: false,
    },
  });
  const [managers, setManagers] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");

  useEffect(() => {
    const fetchManagers = async () => {
      try {
        const organizationId = localStorage.getItem("organizationId");
        const token = localStorage.getItem("token");
        const apiUrl = `${baseURL}/organization/${organizationId}/getallusers?roles=recruiter_admin,manager`;

        const response = await fetch(apiUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        const data = await response.json();
        setManagers(Array.isArray(data) ? data : []);
      } catch (error) {
        console.error("Failed to fetch managers", error);
      }
    };

    fetchManagers();
  }, []);

  useEffect(() => {
    if (user) {
      setFormValues({
        fullName: user.fullName || "",
        email: user.email || "",
        password: "", // You might want to keep the password empty for updates
        role: user.role || "manager",
        phone: user.phone || "",
        reportingManagerId: user.reportingManagerId || "",
        permissions: {
          reviewCandidates: user.permissions?.reviewCandidates || false,
          jobPosting: user.permissions?.jobPosting || false,
          databaseSearch: user.permissions?.databaseSearch || false,
        },
      });
    }
  }, [user]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormValues({
      ...formValues,
      permissions: {
        ...formValues.permissions,
        [name]: checked,
      },
    });
  };

  const handleSelectChange = (e) => {
    setFormValues({
      ...formValues,
      reportingManagerId: e.target.value,
    });
  };

  const handleSubmit = async () => {
    try {
      const organizationId = localStorage.getItem("organizationId");
      const token = localStorage.getItem("token");
      const apiUrl = user
        ? `${baseURL}/organization/recruiters/${user._id}`
        : `${baseURL}/organization/recruiters/${organizationId}`;

      const method = user ? "PUT" : "POST";

      const response = await fetch(apiUrl, {
        method: method,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(formValues),
      });

      if (!response.ok) {
        throw new Error("Failed to save recruiter");
      }

      setSuccessMessage(user ? "Recruiter updated successfully!" : "Recruiter created successfully!");
      handleClose();
    } catch (error) {
      console.error("Error saving recruiter:", error);
    }
  };

  const handleCloseSnackbar = () => {
    setSuccessMessage("");
  };

  console.log('user', user);

  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{user ? "Edit Recruiter" : "Add Recruiter"}</DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                autoFocus
                margin="dense"
                name="fullName"
                label="Full Name"
                type="text"
                fullWidth
                variant="outlined"
                value={formValues.fullName}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="email"
                label="Email"
                type="email"
                fullWidth
                variant="outlined"
                value={formValues.email}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            {!user && (
              <Grid item xs={12} sm={6}>
                <TextField
                  margin="dense"
                  name="password"
                  label="Password"
                  type="password"
                  fullWidth
                  variant="outlined"
                  value={formValues.password}
                  onChange={handleInputChange}
                  size="small"
                />
              </Grid>
            )}
            <Grid item xs={12} sm={6}>
              <TextField
                margin="dense"
                name="phone"
                label="Phone"
                type="tel"
                fullWidth
                variant="outlined"
                value={formValues.phone}
                onChange={handleInputChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth size="small">
                <InputLabel>Reporting Manager</InputLabel>
                <Select
                  name="reportingManagerId"
                  value={formValues.reportingManagerId}
                  onChange={handleSelectChange}
                  label="Reporting Manager"
                >
                  {managers.map((manager) => (
                    <MenuItem key={manager._id} value={manager._id}>
                      <Box>{manager.fullName}</Box>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="reviewCandidates"
                      checked={formValues.permissions.reviewCandidates}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Review Candidates"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="jobPosting"
                      checked={formValues.permissions.jobPosting}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Job Posting"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      name="databaseSearch"
                      checked={formValues.permissions.databaseSearch}
                      onChange={handleCheckboxChange}
                    />
                  }
                  label="Database Search"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleSubmit} variant="contained" color="primary">
            {user ? "Update" : "Submit"}
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={!!successMessage}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity="success"
          sx={{ width: "100%" }}
        >
          {successMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default RecruiterModal;
