import React, { useState } from "react";
import {
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Collapse,
} from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";

const EmployeeDrawerList = ({
  Drawer,
  DrawerHeader,
  open,
  selectedIndex,
  handleDrawerToggle,
  theme,
  handleNavigation,
  userData,
}) => {
  const [openDatabases, setOpenDatabases] = useState(false);

  const handleClickDatabases = () => {
    setOpenDatabases(!openDatabases);
  };

  console.log("userData", userData);

  return (
    <Drawer variant="permanent" open={open}>
      <DrawerHeader>
        <IconButton onClick={handleDrawerToggle}>
          {theme.direction === "rtl" ? (
            <ChevronRightIcon />
          ) : (
            <ChevronLeftIcon />
          )}
        </IconButton>
      </DrawerHeader>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 0}
            onClick={() => handleNavigation("/employerJobListings", 0)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Jobs" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={handleClickDatabases}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <MailIcon />
            </ListItemIcon>
            <ListItemText primary="Databases" sx={{ opacity: open ? 1 : 0 }} />
            {openDatabases ? <ExpandLess /> : <ExpandMore />}
          </ListItemButton>
          <Collapse in={openDatabases} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              <ListItemButton
                sx={{ pl: 8 }}
                onClick={() => handleNavigation("/search-candidates", 6)}
              >
                <ListItemText primary="Search Candidates" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 8 }}
                onClick={() => handleNavigation("/saved-searches", 7)}
              >
                <ListItemText primary="Saved Searches" />
              </ListItemButton>
              <ListItemButton
                sx={{ pl: 8 }}
                onClick={() => handleNavigation("/unlock-candidates", 8)}
              >
                <ListItemText primary="Unlock Candidates" />
              </ListItemButton>
            </List>
          </Collapse>
        </ListItem>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 2}
            onClick={() => handleNavigation("/reports", 2)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <InboxIcon />
            </ListItemIcon>
            <ListItemText primary="Reports" sx={{ opacity: open ? 1 : 0 }} />
          </ListItemButton>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem disablePadding sx={{ display: "block" }}>
          <ListItemButton
            selected={selectedIndex === 3}
            onClick={() => handleNavigation("/team-members", 3)}
            sx={{
              minHeight: 48,
              justifyContent: open ? "initial" : "center",
              px: 2.5,
            }}
          >
            <ListItemIcon
              sx={{
                minWidth: 0,
                mr: open ? 3 : "auto",
                justifyContent: "center",
              }}
            >
              <MailIcon />
            </ListItemIcon>
            <ListItemText
              primary="Team Members"
              sx={{ opacity: open ? 1 : 0 }}
            />
          </ListItemButton>
        </ListItem>
        {userData?.role !== "recruiter" && (
          <>
            {/* <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === 4}
                onClick={() => handleNavigation("/credits-usage", 4)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <InboxIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Credits & Usage"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem>
            <ListItem disablePadding sx={{ display: "block" }}>
              <ListItemButton
                selected={selectedIndex === 5}
                onClick={() => handleNavigation("/billing", 5)}
                sx={{
                  minHeight: 48,
                  justifyContent: open ? "initial" : "center",
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : "auto",
                    justifyContent: "center",
                  }}
                >
                  <MailIcon />
                </ListItemIcon>
                <ListItemText
                  primary="Billing"
                  sx={{ opacity: open ? 1 : 0 }}
                />
              </ListItemButton>
            </ListItem> */}
          </>
        )}
      </List>
    </Drawer>
  );
};

export default EmployeeDrawerList;
