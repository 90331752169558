import React, { useState, useEffect } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel,
  FormGroup, TextField, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Grid, IconButton, Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const salaryRanges = [
  { label: "0-3 Lakhs", min: 0, max: 300000 },
  { label: "3-6 Lakhs", min: 300001, max: 600000 },
  { label: "6-10 Lakhs", min: 600001, max: 1000000 },
  { label: "10-15 Lakhs", min: 1000001, max: 1500000 },
  { label: "15-25 Lakhs", min: 1500001, max: 2500000 },
  { label: "25-50 Lakhs", min: 2500001, max: 5000000 },
  { label: "50-75 Lakhs", min: 5000001, max: 7500000 },
  { label: "75-100 Lakhs", min: 7500001, max: 10000000 },
  { label: "1-5 Cr", min: 10000001, max: 50000000 }
];

const SalaryFilterComponent = ({ salary, onSalaryChange }) => {
  const [showMore, setShowMore] = useState(false);
  const [checkedSalaries, setCheckedSalaries] = useState(salary || []);
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogSearchQuery, setDialogSearchQuery] = useState('');
  const [tempCheckedSalaries, setTempCheckedSalaries] = useState([...checkedSalaries]);

  useEffect(() => {
    onSalaryChange(checkedSalaries);
  }, [checkedSalaries, onSalaryChange]);

  const handleShowMoreToggle = () => {
    setTempCheckedSalaries([...checkedSalaries]);
    setShowMore(!showMore);
  };

  const handleCheckboxChange = (event, isTemp = false) => {
    const salaryRange = salaryRanges.find(range => range.label === event.target.name);
    if (isTemp) {
      setTempCheckedSalaries((prev) =>
        prev.includes(salaryRange)
          ? prev.filter((range) => range !== salaryRange)
          : [...prev, salaryRange]
      );
    } else {
      setCheckedSalaries((prev) =>
        prev.includes(salaryRange)
          ? prev.filter((range) => range !== salaryRange)
          : [...prev, salaryRange]
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDialogSearchChange = (event) => {
    setDialogSearchQuery(event.target.value);
  };

  const handleApply = () => {
    setCheckedSalaries([...tempCheckedSalaries]);
    setShowMore(false);
  };

  const filteredSalaries = salaryRanges.filter(range =>
    range.label.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredDialogSalaries = salaryRanges.filter(range =>
    range.label.toLowerCase().includes(dialogSearchQuery.toLowerCase())
  );

  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Salary</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          sx={{ backgroundColor: '#d3d3d3' }}
          placeholder='Search'
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <FormGroup>
          {filteredSalaries.slice(0, 4).map((range, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={checkedSalaries.includes(range)}
                  onChange={(e) => handleCheckboxChange(e, false)}
                  name={range.label}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedSalaries.includes(range) ? 'bold' : 'normal' }}>{range.label}</Typography>}
            />
          ))}
          {filteredSalaries.length > 4 && (
            <Box>
              <Button 
                onClick={handleShowMoreToggle} 
                fullWidth 
                sx={{ mt: 1, fontSize: '14px', textTransform: 'capitalize' }}
              >
                {showMore ? 'SHOW LESS' : 'SHOW MORE'}
              </Button>
              <Dialog open={showMore} onClose={handleShowMoreToggle} fullWidth maxWidth="sm">
                <DialogTitle>
                  <Grid container justifyContent="space-between" alignItems="center">
                    <Grid item>
                      <Typography variant="h6">Salary Range</Typography>
                    </Grid>
                    <Grid item>
                      <IconButton onClick={handleShowMoreToggle}>
                        <CloseIcon />
                      </IconButton>
                    </Grid>
                  </Grid>
                </DialogTitle>
                <DialogContent>
                  <TextField
                    placeholder="Search"
                    variant="outlined"
                    fullWidth
                    value={dialogSearchQuery}
                    onChange={handleDialogSearchChange}
                    sx={{ mb: 2 }} // Add margin bottom for spacing
                  />
                  <Grid container spacing={2}> {/* Use Grid to create 2 columns */}
                    {filteredDialogSalaries.map((range, index) => (
                      <Grid item xs={6} key={index}> {/* Set each item to take half the width */}
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={tempCheckedSalaries.includes(range)}
                              onChange={(e) => handleCheckboxChange(e, true)}
                              name={range.label}
                              sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                            />
                          }
                          label={<Typography sx={{ fontWeight: tempCheckedSalaries.includes(range) ? 'bold' : 'normal' }}>{range.label}</Typography>}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button variant="contained" color="primary" onClick={handleApply}>Apply</Button>
                </DialogActions>
              </Dialog>
            </Box>
          )}
        </FormGroup>
      </AccordionDetails>
    </Accordion>
  );
};

export default SalaryFilterComponent;
