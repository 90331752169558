import React from 'react';
import { Button, Grid, Typography, TextField, Autocomplete, Box } from '@mui/material';

const JobSeekerCaseTwo = ({ formData, handleCurrentlyWorkingChange, handleJobRoleChange, handleIndustryChange, handleSkillsChange, setFormData }) => {
  const jobRoles = ['Developer', 'Manager', 'Designer'];
  const industries = ['Tech', 'Finance', 'Education'];
  const skills = ['JavaScript', 'React', 'Node.js', 'Python', 'Java'];

  const handleTotalExpChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExp: e.target.value,
    }));
  };

  const handleTotalExpInMonthsChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      totalExpInMonths: e.target.value,
    }));
  };

  const handleDesignationChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      experiences: [{
        ...prevData.experiences[0],
        designation: e.target.value,
      }]
    }));
  };

  const handleCompanyChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      experiences: [{
        ...prevData.experiences[0],
        company: e.target.value,
      }]
    }));
  };

  const handleStartDateChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      experiences: [{
        ...prevData.experiences[0],
        startDate: e.target.value,
      }]
    }));
  };

  const handleCurrentCtcChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      experiences: [{
        ...prevData.experiences[0],
        ctc: e.target.value,
      }]
    }));
  };

  console.log('formData in casetwo', formData);

  return (
    <Box sx={{ backgroundColor: '#ffffff', padding: 2, borderRadius: 4 }}>
      {formData.hasWorkExperience && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Total Years of Experience
              </Typography>
              <TextField
                fullWidth
                placeholder="Years"
                variant="outlined"
                value={formData.totalExp}
                onChange={handleTotalExpChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Total Months of Experience
              </Typography>
              <TextField
                fullWidth
                placeholder="Months"
                variant="outlined"
                value={formData.totalExpInMonths}
                onChange={handleTotalExpInMonthsChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Job Title
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter job title"
                variant="outlined"
                value={formData.experiences[0]?.designation || ''}
                onChange={handleDesignationChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Job Role
              </Typography>
              <Autocomplete
                multiple
                options={jobRoles}
                value={formData.experiences[0]?.jobRole || []}
                onChange={handleJobRoleChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Job Role"
                    size="small"
                    sx={{ marginBottom: 2 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Company Name
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter company name"
                variant="outlined"
                value={formData.experiences[0]?.company || ''}
                onChange={handleCompanyChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Industry
              </Typography>
              <Autocomplete
                options={industries}
                value={formData.experiences[0]?.industry || ''}
                onChange={handleIndustryChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Industry"
                    size="small"
                    sx={{ marginBottom: 2 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Skills
              </Typography>
              <Autocomplete
                multiple
                options={skills}
                value={formData.skills}
                onChange={handleSkillsChange}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    placeholder="Select Skills"
                    size="small"
                    sx={{ marginBottom: 2 }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Are you currently working in this company?
              </Typography>
              <Grid container spacing={2}>
                <Grid item>
                  <Button
                    variant={formData.experiences[0]?.currentlyWorking ? 'contained' : 'outlined'}
                    onClick={() => handleCurrentlyWorkingChange(true)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: formData.experiences[0]?.currentlyWorking ? '#423BFA' : 'transparent',
                      border: '1px solid #ccc',
                      color: formData.experiences[0]?.currentlyWorking ? '#fff' : '#000',
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">Yes</Typography>
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant={!formData.experiences[0]?.currentlyWorking ? 'contained' : 'outlined'}
                    onClick={() => handleCurrentlyWorkingChange(false)}
                    sx={{
                      borderRadius: 6,
                      backgroundColor: !formData.experiences[0]?.currentlyWorking ? '#423BFA' : 'transparent',
                      border: '1px solid #ccc',
                      color: !formData.experiences[0]?.currentlyWorking ? '#fff' : '#000',
                    }}
                  >
                    <Typography fontWeight="bold" fontSize="12px">No</Typography>
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Current CTC
              </Typography>
              <TextField
                fullWidth
                placeholder="Enter current CTC"
                variant="outlined"
                name='ctc'
                value={formData.experiences[0]?.ctc}
                onChange={handleCurrentCtcChange}
                size="small"
                sx={{ marginBottom: 2 }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography component="legend" variant="bold" sx={{ fontWeight: 'bold', marginBottom: 1.5 }}>
                Start Date
              </Typography>
              <TextField
                fullWidth
                type="date"
                variant="outlined"
                value={formData.experiences[0]?.startDate || ''}
                onChange={handleStartDateChange}
                size="small"
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{ marginBottom: 2 }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
};

export default JobSeekerCaseTwo;
