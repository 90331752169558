import React, { useState } from 'react';
import { Container, Grid, Typography, Box, Button, Snackbar, Alert } from '@mui/material';
import JobSelectionCard from './JobSelectionCard';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import baseURL from '../../../../api/baseUrl';

const jobOptions = [
  {
    title: 'Classic job',
    price: '₹0',
    originalPrice: '₹600',
    credits: '1 Job Credit required',
    features: [
      { text: 'Job valid for 15 days', included: true },
      { text: 'Job branding', included: true },
      { text: 'Detailed job description', included: true },
      { text: 'Smart boost via WhatsApp', included: false, icon: <WhatsAppIcon /> },
      { text: 'Higher visibility to candidates', included: false },
      { text: 'Urgently hiring tag', included: false },
    ],
    limitedOffer: true,
  },
  {
    title: 'Premium job',
    price: '₹900',
    originalPrice: '₹1200',
    credits: '2 Job Credits required',
    features: [
      { text: 'Job valid for 15 days', included: true },
      { text: 'Job branding', included: true },
      { text: 'Detailed job description', included: true },
      { text: 'Smart boost via WhatsApp', included: true, icon: <WhatsAppIcon /> },
      { text: 'Higher visibility to candidates', included: true },
      { text: 'Urgently hiring tag', included: true },
    ],
    recommended: true,
    limitedOffer: true,
  },
];

const EmployerSubscriptionPayment = ({ formData }) => {
  const [selectedJob, setSelectedJob] = useState('Classic job');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const navigate = useNavigate(); // Use useNavigate for navigation

  const handlePostJob = async () => {
    const token = localStorage.getItem('token'); // Replace 'yourTokenKey' with the key used to store the token in localStorage
    const makePayment = selectedJob === 'Premium job';
    const postData = {
      jobId: formData.jobId,
      organizationId: formData.organizationId,
      makePayment: makePayment,
      startDate: "2024-07-28",
      endDate: "2024-08-26",
    };

    try {
      const response = await fetch(`${baseURL}/jobpost/update/page5`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`, // Include the token in the Authorization header
        },
        body: JSON.stringify(postData),
      });

      if (response.ok) {
        setSnackbarMessage('Job posted successfully');
        setSnackbarSeverity('success');
      } else {
        console.error('Error posting job:', response.statusText);
        setSnackbarMessage('Job posting failed');
        setSnackbarSeverity('error');
      }
    } catch (error) {
      console.error('Error posting job:', error);
      setSnackbarMessage('Job posting failed');
      setSnackbarSeverity('error');
    }

    setSnackbarOpen(true);

    setTimeout(() => {
      navigate('/employerJobListings'); // Navigate to employerJobListings after 2 seconds
    }, 2000); // 2-second delay
  };

  return (
    <Container>
      <Typography variant="h5" sx={{ mb: 3 }}>Post a job</Typography>
      <Grid container spacing={3}>
        {jobOptions.map((job) => (
          <Grid item xs={12} sm={4} key={job.title}>
            <JobSelectionCard
              title={job.title}
              price={job.price}
              originalPrice={job.originalPrice}
              credits={job.credits}
              features={job.features}
              selected={selectedJob === job.title}
              onClick={() => setSelectedJob(job.title)}
              recommended={job.recommended}
              limitedOffer={job.limitedOffer}
            />
          </Grid>
        ))}
      </Grid>
      <Box mt={4} display="flex" justifyContent="flex-end" alignItems="center">
        <Button variant="contained" color="primary" onClick={handlePostJob} sx={{
          backgroundColor: "#423BFA",
          color: "#fff",
          borderRadius: 6,
          px: 3,
          py: 1.5,
          "&:hover": {
            backgroundColor: "#372FD1",
          },
        }}>
          Submit & Post Job
        </Button>
      </Box>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Container>
  );
};

export default EmployerSubscriptionPayment;
