import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, Grid, Card, CardContent, CardHeader, Container, useMediaQuery, useTheme } from '@mui/material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import baseURL from '../../../api/baseUrl';

const LatestJobOpenings = () => {
  const [jobOpenings, setJobOpenings] = useState([]);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const navigate = useNavigate();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const authToken = localStorage.getItem('authToken');
        const response = await fetch(`${baseURL}/jobs/all`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authToken}`,
          },
        });

        if (!response.ok) {
          throw new Error('Failed to fetch jobs');
        }

        const data = await response.json();
        setJobOpenings(data?.jobs);
      } catch (error) {
        console.error('Error fetching jobs:', error);
      }
    };

    fetchJobs();
  }, []);

  const formatDate = (dateString) => {
    if (!dateString) return 'TBD';

    const date = new Date(dateString);
    const day = date.getDate();
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();

    const ordinalSuffix = (day) => {
      if (day > 3 && day < 21) return 'th';
      switch (day % 10) {
        case 1: return 'st';
        case 2: return 'nd';
        case 3: return 'rd';
        default: return 'th';
      }
    };

    return `${day}${ordinalSuffix(day)} ${month} ${year}`;
  };

  const handleCardClick = (jobId) => {
    navigate(`/JobSeekerFilter/job/${jobId}`);
  };

  return (
    <Box sx={{ backgroundColor: '#EDEDFC', py: 4 }}>
      <Container maxWidth="xl">
        <Box
          display="flex"
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent="space-between"
          alignItems="center"
          sx={{ mb: 4 }}
        >
          <Typography
            variant={isSmallScreen ? 'h6' : 'h4'}
            component="h1"
            gutterBottom
            sx={{ color: '#000', fontWeight: 'bold', textAlign: { xs: 'center', md: 'left' } }}
          >
            Explore the latest job openings
          </Typography>
        </Box>

        <Grid container spacing={isSmallScreen ? 2 : 3}>
          {jobOpenings?.map((job, index) => (
            <Grid item xs={12} sm={6} md={4} lg={3} key={index}>
              <Card
                sx={{
                  borderRadius: 6,
                  boxShadow: 4,
                  cursor: 'pointer',
                  height: '200px',  // Reduced height
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
                onClick={() => handleCardClick(job._id)}
              >
                <CardHeader
                  title={job.hiringFor}
                  subheader={formatDate(job.postedDate)}
                  titleTypographyProps={{ fontSize: isSmallScreen ? '1rem' : '1.1rem' }}
                  subheaderTypographyProps={{ fontSize: isSmallScreen ? '0.8rem' : '0.9rem' }}
                  sx={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                />
                <CardContent sx={{ flexGrow: 1 }}>
                  <Typography
                    variant="subtitle1"
                    sx={{ fontWeight: 'bold', fontSize: isSmallScreen ? '0.9rem' : '1rem' }}
                    noWrap
                  >
                    {job.jobTitle}
                  </Typography>
                  <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mt: 1 }}>
                    <LocationOnOutlinedIcon sx={{ mr: 1 }} />
                    <Typography color="text.secondary" sx={{ fontSize: isSmallScreen ? '0.7rem' : '0.9rem', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                      {job.organizationId.organizationAddress}
                    </Typography>
                    <TimerOutlinedIcon sx={{ ml: 1, mr: 1 }} />
                    <Typography color="text.secondary" sx={{ fontSize: isSmallScreen ? '0.7rem' : '0.9rem' ,overflow: 'hidden', textOverflow: 'ellipsis', }}>
                      {formatDate(job.endDate)}
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default LatestJobOpenings;
