import React from 'react';
import { Box, Typography, Grid, Card, CardContent, CardActionArea, Container, useMediaQuery, useTheme, Avatar } from '@mui/material';
import { red } from '@mui/material/colors';
import CardHeader from '@mui/material/CardHeader';
import mobileVideo from '../../../assets/videos/bikevideo.mp4';

const jobSeekers = [
    { name: "Jenifer Fernandis", role: "System Administrator" },
    { name: "Lara Haifa", role: "Creative Head" },
    { name: "Stiven Ceron", role: "System Administrator" },
    { name: "Kim Soha", role: "Document Head" },
    { name: "Kim Saas", role: "Frontend Developer" },
    { name: "Stiven Ced", role: "System Manager" },
];

const FeaturedJobs = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Box sx={{ backgroundColor: '#d6d6d6', py: 4 }}>
            <Container maxWidth="xl">
                <Box>
                    <Typography
                        variant={isSmallScreen ? 'h6' : 'h4'}
                        component="h1"
                        gutterBottom
                        sx={{ color: '#000', fontWeight: 'bold' }}
                    >
                        Featured job seeker
                    </Typography>
                    <Grid container spacing={2}>
                        {jobSeekers.map((jobSeeker) => (
                            <Grid item xs={6} sm={6} md={2} key={jobSeeker.name}>
                                <Card sx={{ height: '100%', backgroundColor: '#fff', borderRadius: '1rem' }}>
                                    <CardActionArea sx={{ height: '100%' }}>
                                        <CardHeader
                                            avatar={
                                                <Avatar sx={{ bgcolor: red[500] }} aria-label="recipe">
                                                    {jobSeeker.name.charAt(0)}
                                                </Avatar>
                                            }
                                            title={jobSeeker.name}
                                            subheader={jobSeeker.role}
                                        />
                                        <Box sx={{ position: 'relative', height: '350px', overflow: 'hidden' }}>
                                            <video width='100%' height='100%' controls style={{ objectFit: 'cover' }}>
                                                <source src={mobileVideo} type="video/mp4" />
                                                <source src={mobileVideo} type="video/ogg" />
                                                Your browser does not support HTML video.
                                            </video>
                                        </Box>
                                        <CardContent>
                                            <Typography variant="h6" component="div">
                                                {jobSeeker.name}
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                {jobSeeker.role}
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Container>
        </Box>
    );
};

export default FeaturedJobs;
