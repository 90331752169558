import React from 'react';
import { Container, Typography } from '@mui/material';
import NavBar from '../../../components/JobSeekerComponents/NavBar/NavBar';
import Footer from '../../../components/JobSeekerComponents/Footer/Footer';
import JobsApplied from './JobsApplied';

const JobSeekerAppliedJobs = () => {
  return (
    <>
      <NavBar />
      <Container sx={{ p: 10 }} maxWidth="sm">
        <Typography variant="h5" fontWeight="bold">
          Applied Jobs
        </Typography>
        <JobsApplied />
      </Container>
      <Footer />
    </>
  );
};

export default JobSeekerAppliedJobs;
