import React, { useState } from 'react';
import {
  Accordion, AccordionSummary, AccordionDetails, Checkbox, FormControlLabel,
  FormGroup, TextField, Typography, Button, Dialog, DialogTitle,
  DialogContent, DialogActions, Grid, IconButton,Box
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CloseIcon from '@mui/icons-material/Close';

const departments = [
  "Admin / Back Office / Computer Operator", "Advertising / Communication", "Aviation & Aerospace",
  "Banking / Insurance / Financial Services", "Beauty, Fitness & Personal Care",
  "Construction & Site Engineering", "Consulting", "Content, Editorial & Journalism",
  "CSR & Social Service", "Customer Support", "Data Science & Analytics",
  "Delivery / Driver / Logistics", "Domestic Worker", "Energy & Mining",
  "Engineering - Hardware & Networks", "Environment Health & Safety",
  "Facility Management", "Finance & Accounting", "Healthcare / Doctor / Hospital Staff",
  "Human Resources", "IT & Information Security", "Legal & Regulatory",
  "Maintenance Services", "Marketing / Brand / Digital Marketing",
  "Media Production & Entertainment", "Operations", "Production / Manufacturing / Engineering",
  "Product Management", "Project & Program Management", "Purchase & Supply Chain",
  "Quality Assurance", "Research & Development", "Restaurant / Hospitality / Tourism",
  "Retail & eCommerce", "Risk Management & Compliance", "Sales & BD",
  "Security Services", "Shipping & Maritime", "Software Engineering",
  "Strategic & Top Management", "Tailoring, Apparel & Home Furnishing", "Teaching & Training",
  "UX, Design & Architecture"
];

const DepartmentFilterComponent = () => {
  const [showMore, setShowMore] = useState(false);
  const [checkedDepartments, setCheckedDepartments] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [dialogSearchQuery, setDialogSearchQuery] = useState('');
  const [tempCheckedDepartments, setTempCheckedDepartments] = useState([]);

  const handleShowMoreToggle = () => {
    setTempCheckedDepartments([...checkedDepartments]);
    setShowMore(!showMore);
  };

  const handleCheckboxChange = (event, isTemp = false) => {
    const department = event.target.name;
    if (isTemp) {
      setTempCheckedDepartments(prev =>
        prev.includes(department)
          ? prev.filter(dep => dep !== department)
          : [...prev, department]
      );
    } else {
      setCheckedDepartments(prev =>
        prev.includes(department)
          ? prev.filter(dep => dep !== department)
          : [...prev, department]
      );
    }
  };

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const handleDialogSearchChange = (event) => {
    setDialogSearchQuery(event.target.value);
  };

  const handleApply = () => {
    setCheckedDepartments([...tempCheckedDepartments]);
    setShowMore(false);
  };

  const filteredDepartments = departments.filter(department =>
    department.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const filteredDialogDepartments = departments.filter(department =>
    department.toLowerCase().includes(dialogSearchQuery.toLowerCase())
  );

  return (
    <Accordion sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Department</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <TextField
          sx={{ backgroundColor: '#d3d3d3' }}
         placeholder='Search'
          variant="outlined"
          fullWidth
          value={searchQuery}
          onChange={handleSearchChange}
        />
        <FormGroup>
          {filteredDepartments.slice(0, 4).map((department, index) => (
            <FormControlLabel
              key={index}
              control={
                <Checkbox
                  checked={checkedDepartments.includes(department)}
                  onChange={(e) => handleCheckboxChange(e, false)}
                  name={department}
                  sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                />
              }
              label={<Typography sx={{ fontWeight: checkedDepartments.includes(department) ? 'bold' : 'normal', fontSize: '14px' }}>{department}</Typography>}
            />
          ))}
        </FormGroup>
        <Button onClick={handleShowMoreToggle}>
          View more
        </Button>
        <Dialog open={showMore} onClose={handleShowMoreToggle} maxWidth="md" fullWidth>
          <DialogTitle>
            <Box display="flex" justifyContent="space-between" alignItems="center">
              Departments
              <IconButton onClick={handleShowMoreToggle}>
                <CloseIcon />
              </IconButton>
            </Box>
          </DialogTitle>
          <DialogContent sx={{ height: '400px', overflowX: 'auto' }}>
            <TextField
              sx={{ backgroundColor: '#d3d3d3', marginBottom: '16px' }}
              placeholder="Search"
              variant="outlined"
              fullWidth
              value={dialogSearchQuery}
              onChange={handleDialogSearchChange}
            />
            <FormGroup>
              <Grid container spacing={1}>
                {filteredDialogDepartments.map((department, index) => (
                  <Grid item xs={12} sm={6} md={4} key={index}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={tempCheckedDepartments.includes(department)}
                          onChange={(e) => handleCheckboxChange(e, true)}
                          name={department}
                          sx={{ '&.Mui-checked': { fontWeight: 'bold' } }}
                        />
                      }
                      label={<Typography sx={{ fontWeight: tempCheckedDepartments.includes(department) ? 'bold' : 'normal', fontSize: '12px' }}>{department}</Typography>}
                    />
                  </Grid>
                ))}
              </Grid>
            </FormGroup>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleApply} variant="contained" sx={{ backgroundColor: '#423BFA'}}>Apply</Button>
          </DialogActions>
        </Dialog>
      </AccordionDetails>
    </Accordion>
  );
};

export default DepartmentFilterComponent;
