import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HighestEducationFilter = ({ highestEducation, onHighestEducationChange }) => {
  return (
    <Accordion sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Highest Education</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup value={highestEducation} onChange={onHighestEducationChange}>
            <FormControlLabel
              value="underGraduate"
              control={<Radio />}
              label={<Typography sx={{ fontWeight: highestEducation === 'underGraduate' ? 'bold' : 'normal', fontSize: '14px' }}>Under Graduate</Typography>}
            />
            <FormControlLabel
              value="graduate"
              control={<Radio />}
              label={<Typography sx={{ fontWeight: highestEducation === 'graduate' ? 'bold' : 'normal', fontSize: '14px' }}>Graduate</Typography>}
            />
            <FormControlLabel
              value="postGraduate"
              control={<Radio />}
              label={<Typography sx={{ fontWeight: highestEducation === 'postGraduate' ? 'bold' : 'normal', fontSize: '14px' }}>Post Graduate</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};

export default HighestEducationFilter;
