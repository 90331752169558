import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Grid,
  Button,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormControl,
  FormLabel,
  Paper,
  useMediaQuery,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import EmployerDrawer from '../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer';
import MultiSelect from '../../../components/AdvancedComponents/AdvancedMultiSelect';
import CheckBoxSelect from '../../../components/AdvancedComponents/CheckBoxSelect';
import SingleSelect from '../../../components/AdvancedComponents/AdvancedSingleSelect';
import SearchIcon from '@mui/icons-material/Search';
import DatabaseCreditsInfo from './DatabaseCreditsInfo';
import RecentSearches from './RecentSearches';

const SearchCandidate = () => {
  const navigate = useNavigate();
  const [selectedKeywords, setSelectedKeywords] = useState([]);
  const [experienceType, setExperienceType] = useState('Both');
  const [selectedCities, setSelectedCities] = useState([]);
  const [formData, setFormData] = useState({
    minimumEducation: '',
    activeIn: '',
    minExperience: null,
    maxExperience: null,
    minSalary: null,
    maxSalary: null,
  });

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({ ...prevFormData, [name]: value }));
  };

  const handleCitySelectionChange = (cities) => {
    setSelectedCities(cities.map(city => city.value).join(','));
  };

  const handleSearch = () => {
    const queryParams = new URLSearchParams({
      experienceType,
      keywords: selectedKeywords.join(','),
      currentCity: selectedCities,
      minimumEducation: formData.minimumEducation,
      activeIn: formData.activeIn,
      minExperience: formData.minExperience ? formData.minExperience.value : '',
      maxExperience: formData.maxExperience ? formData.maxExperience.value : '',
      minSalary: formData.minSalary ? formData.minSalary.value : '',
      maxSalary: formData.maxSalary ? formData.maxSalary.value : '',
    }).toString();

    navigate(`/candidate-searchList?${queryParams}`);
  };

  return (
    <EmployerDrawer>
      <Typography variant="h5" fontWeight="bold" gutterBottom>
        Search Candidates
      </Typography>
      <Box
        sx={{
          display: 'flex',
          flexDirection: isMobile ? 'column' : 'row',
          justifyContent: isMobile ? 'center' : 'space-around',
          alignItems: isMobile ? 'stretch' : 'flex-start',
          height: '100vh',
          backgroundColor: '#f5f5f5',
          padding: isMobile ? 1 : 3,
        }}
      >
        <Paper
          sx={{
            padding: 3,
            maxWidth: 600,
            height: '80vh',
            width: '100%',
            borderRadius: 5,
            backgroundColor: '#ffffff',
            mb: isMobile ? 2 : 0,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
          }}
          elevation={3}
        >
          <Box component="form" sx={{ mt: 3 }}>
            <FormControl component="fieldset" sx={{ mb: 3 }}>
              <FormLabel component="legend">Searching for</FormLabel>
              <RadioGroup
                row
                value={experienceType}
                onChange={(e) => setExperienceType(e.target.value)}
              >
                <FormControlLabel value="Freshers" control={<Radio />} label="Freshers only" />
                <FormControlLabel value="Experienced" control={<Radio />} label="Experienced only" />
                <FormControlLabel value="Both" control={<Radio />} label="Any" />
              </RadioGroup>
            </FormControl>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography component="legend" sx={{ fontWeight: 'bold' }} gutterBottom>
                  Keywords
                </Typography>
                <MultiSelect
                  placeholder="Type keywords and separate them with commas"
                  selectedOptions={selectedKeywords}
                  setSelectedOptions={setSelectedKeywords}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography component="legend" sx={{ fontWeight: 'bold' }} gutterBottom>
                  Current city
                </Typography>
                <CheckBoxSelect onSelectionChange={handleCitySelectionChange} />
              </Grid>
              <Grid item xs={12}>
                <SingleSelect formData={formData} handleChange={handleChange} />
              </Grid>
            </Grid>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'flex-end', gap: 1 }}>
            <Button
              onClick={handleSearch}
              variant="contained"
              color="primary"
              sx={{
                backgroundColor: '#423bfa',
                '&:hover': {
                  backgroundColor: '#423bfa',
                },
                display: 'flex',
                alignItems: 'center',
                gap: 1,
              }}
            >
              <SearchIcon />
              Search candidates
            </Button>
          </Box>
        </Paper>
        <Box
          sx={{
            width: isMobile ? '100%' : 'auto',
            mt: isMobile ? 2 : 0,
            padding: 2
          }}
        >
          <DatabaseCreditsInfo />
          <Box sx={{ mt: 2 }}>
            <RecentSearches />
          </Box>
        </Box>
      </Box>
    </EmployerDrawer>
  );
};

export default SearchCandidate;
