import React from 'react';
import {
  Paper,
  Typography,
  Button,
  List,
  ListItem,
  ListItemText,
  Box,
  Grid,
} from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';

const recentSearches = [
  'Sales & Marketing Executive | Anywhere in India',
  'sal | Anywhere in India | <= 6 lacs',
  'Experienced only | Business Development Executive (BDE) | Anywhere in India | 3...',
  'Business Development Executive (BDE) | Business Development Manager |...',
  'Hiring for Customer Support Representative in Bangalore with good...',
];

const RecentSearches = () => {
  return (
    <Paper elevation={3} style={{ padding: 16, maxWidth: 350, borderRadius: 10 }}>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: 8 }}>
        <HistoryIcon style={{ marginRight: 8 }} />
        <Typography variant="subtitle2" fontWeight="bold" fontSize="18px">
          Recent searches
        </Typography>
      </div>
      <List style={{ maxHeight: 340, overflow: 'auto' }}>
        {recentSearches.map((search, index) => (
          <ListItem key={index} alignItems="flex-start">
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <ListItemText
                  primary={<Typography variant="body2">{search}</Typography>}
                />
              </Grid>
              <Grid item>
                <Box style={{ display: 'flex', gap: 8 }}>
                  <Button
                    size="small"
                    style={{
                      textTransform: 'none',
                      color: 'green',
                    }}
                  >
                    <Typography variant="body2">Fill search</Typography>
                  </Button>
                  <Button
                    size="small"
                    style={{
                      textTransform: 'none',
                      
                      color: 'green',
                    }}
                   
                  >
                    <Typography variant="body2">Search candidates</Typography>
                  </Button>
                </Box>
              </Grid>
            </Grid>
          </ListItem>
        ))}
      </List>
    </Paper>
  );
};

export default RecentSearches;
