import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, Typography, Grid } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CustomAutocomplete from '../../CustomAutoComplete';

// Function to generate experience options
const generateExperienceOptions = () => {
  const experienceOptions = [];
  for (let i = 0; i <= 30; i++) {
    if (i === 0) {
      experienceOptions.push('0 months');
    } else if (i === 1) {
      experienceOptions.push('1 year');
    } else if (i === 30) {
      experienceOptions.push('30+ years');
    } else {
      experienceOptions.push(`${i} years`);
    }
  }
  return experienceOptions;
};

const experienceOptions = generateExperienceOptions();

const textToNumeric = (text) => {
  if (!text) return '';
  if (text === '30+ years') return 30;
  const match = text.match(/(\d+)/);
  return match ? parseInt(match[1], 10) : '';
};

const numericToText = (number) => {
  if (number === 0) return '0 months';
  if (number === 1) return '1 year';
  if (number === 30) return '30+ years';
  return `${number} years`;
};

const CandidateExperience = ({ formData, handleChange }) => {
  const handleAutocompleteChange = (name, newValue) => {
    handleChange({ target: { name, value: textToNumeric(newValue) } });
  };

  return (
    <Accordion defaultExpanded>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Experience</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1}>
          <Grid item xs={6}>
            <CustomAutocomplete
              placeholder="Min experience"
              options={experienceOptions}
              value={numericToText(formData?.minExperience)}
              onChange={(newValue) => handleAutocompleteChange('minExperience', newValue)}
            />
          </Grid>
          <Grid item xs={6}>
            <CustomAutocomplete
              placeholder="Max experience"
              options={experienceOptions}
              value={numericToText(formData?.maxExperience)}
              onChange={(newValue) => handleAutocompleteChange('maxExperience', newValue)}
            />
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default CandidateExperience;
