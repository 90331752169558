import React, { useState } from 'react';
import PropTypes from 'prop-types'; // Import PropTypes for validation
import {
  Typography,
  Button,
  Box,
  TextField,
  Modal,
} from '@mui/material';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

const SkillsModal = ({ open, handleClose, skill, handleSave }) => {
  // Initialize the state with the skill prop
  const [editedSkill, setEditedSkill] = useState(skill || '');

  const handleChange = (event) => {
    setEditedSkill(event.target.value);
  };

  const handleSaveClick = () => {
    if (editedSkill.trim()) { // Ensure skill is not empty
      handleSave(editedSkill);
      handleClose();
    } else {
      // Optionally handle empty skill case
      alert('Skill cannot be empty');
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
    >
      <Box sx={modalStyle}>
        <Typography id="modal-title" variant="h6" component="h2">
          Edit Skill
        </Typography>
        <TextField
          label="Skill"
          variant="outlined"
          fullWidth
          margin="normal"
          value={editedSkill}
          onChange={handleChange}
        />
        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button variant="contained" onClick={handleSaveClick}>
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

// PropTypes for type checking
SkillsModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  skill: PropTypes.string,
  handleSave: PropTypes.func.isRequired,
};

SkillsModal.defaultProps = {
  skill: '',
};

export default SkillsModal;
