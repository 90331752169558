import React, { useRef } from 'react';
import { Box, Typography, Button, IconButton } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const JobSeekerCaseFour = ({ handleChange, formData, handleNext }) => {
  const fileInputRef = useRef(null);

  const handleDelete = () => {
    handleChange({ target: { name: 'profilePicture', value: null } });
  };

  const handleEdit = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    handleChange({ target: { name: 'profilePicture', value: file } });
  };

  console.log('formData', formData);

  return (
    <Box sx={{ padding: 3, borderRadius: 4, backgroundColor: '#ffffff', textAlign: 'center', maxWidth: 400, margin: 'auto' }}>
      <Typography gutterBottom>
        Let's add your photo to
      </Typography>
      <Typography sx={{ fontWeight: 'bold', marginBottom: 2 }}>
        Profile
      </Typography>
      <Typography sx={{ fontWeight: 'bold', marginBottom: 2, color: 'orange' }}>
        Recommended
      </Typography>
      <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 2 }}>
        Profiles with photos are more likely to be noticed by HRs
      </Typography>

      <Box sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        marginBottom: 2
      }}>
        <Box sx={{
          width: 100,
          height: 100,
          borderRadius: '50%',
          backgroundColor: '#d6d6d6',
          position: 'relative',
          marginBottom: 2
        }}>
          {formData.profilePicture ? (
            <img
              src={URL.createObjectURL(formData.profilePicture)}
              alt="Profile"
              style={{ width: '100%', height: '100%', borderRadius: '50%' }}
            />
          ) : (
            <Button
              variant="contained"
              component="label"
              sx={{
                borderRadius: '50%',
                minWidth: 90,
                minHeight: 90,
                backgroundColor: '#d6d6d6',
                boxShadow: 'none',
                '&:hover': {
                  backgroundColor: '#d6d6d6',
                  boxShadow: 'none',
                },
              }}
            >
              <AddIcon sx={{ fontSize: 40, color: 'green' }} />
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                name="profilePicture"
                onChange={handleFileChange}
                hidden
              />
            </Button>
          )}
        </Box>

        {formData.profilePicture && (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
            <Typography variant="body2" gutterBottom>
              {formData.profilePicture.name}
            </Typography>
            <Box sx={{ marginTop: 1 }}>
              <IconButton
                onClick={handleDelete}
                size="small"
                aria-label="delete"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <DeleteIcon />
              </IconButton>
              <IconButton
                onClick={handleEdit}
                size="small"
                aria-label="edit"
                sx={{ backgroundColor: '#ffffff', boxShadow: 'none' }}
              >
                <EditIcon />
              </IconButton>
              <input
                ref={fileInputRef}
                type="file"
                accept="image/*"
                name="profilePicture"
                onChange={handleFileChange}
                hidden
              />
            </Box>
          </Box>
        )}
        <Typography variant="body2" color="error" sx={{ marginTop: 2 }}>
          File size should be less than 10 MB
        </Typography>
      </Box>
    </Box>
  );
};

export default JobSeekerCaseFour;
