import React from 'react';
import { Typography, Box, Grid, Paper, Accordion, AccordionSummary, AccordionDetails, IconButton } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';

const EmployerCaseTwoDetails = ({ CaseTwoJobData }) => {
  return (
    <Accordion defaultExpanded>
      <AccordionSummary>
        <Paper variant="outlined" sx={{ padding: 0.5, backgroundColor: '#f0f7ff', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <InfoIcon color="primary" sx={{ marginRight: 1 }} />
              <Typography>Interview Information</Typography>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <IconButton>
                <EditIcon />
              </IconButton>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} sx={{ padding: 0 }} />
            </Box>
          </Box>
        </Paper>
      </AccordionSummary>
      <AccordionDetails>
        <Paper variant="outlined" sx={{ padding: 2, marginBottom: 2 }}>
          <Box sx={{ marginTop: 2 }}>
            <Grid container spacing={2}>
              {/* <Grid item xs={12} container>
                <Grid item xs={6} sm={3}>
                  <Typography variant="subtitle1">Communication Preference</Typography>
                </Grid>
                <Grid item xs={6} sm={9}>
                  <Typography variant="body2">{CaseTwoJobData?.jobPosting
                    ?.communicationPref}</Typography>
                </Grid>
              </Grid> */}
              <Grid item xs={12} container>
                <Grid item xs={6} sm={3}>
                  <Typography variant="subtitle1">Type of Interview</Typography>
                </Grid>
                <Grid item xs={6} sm={9}>
                  <Typography variant="body2">{CaseTwoJobData?.jobPosting?.interviewType
                  }</Typography>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Paper>


      </AccordionDetails>
    </Accordion>
  );
};

export default EmployerCaseTwoDetails;
