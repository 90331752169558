import React from 'react';
import { Accordion, AccordionSummary, AccordionDetails, FormControl, RadioGroup, FormControlLabel, Radio, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DatePostedComponent = ({ datePosted, onDatePostedChange }) => {
  return (
    <Accordion defaultExpanded sx={{ backgroundColor: '#f5f5f5' }}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography sx={{ fontSize: '16px', fontWeight: 'bold' }}>Date Posted</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <FormControl component="fieldset" fullWidth>
          <RadioGroup value={datePosted} onChange={onDatePostedChange}>
            <FormControlLabel
              value="all"
              control={<Radio checked={datePosted === 'all'} />}
              label={<Typography sx={{ fontWeight: datePosted === 'all' ? 'bold' : 'normal', fontSize: '14px' }}>All</Typography>}
            />
            <FormControlLabel
              value="last24Hours"
              control={<Radio checked={datePosted === 'last24Hours'} />}
              label={<Typography sx={{ fontWeight: datePosted === 'last24Hours' ? 'bold' : 'normal', fontSize: '14px' }}>Last 24 hours</Typography>}
            />
            <FormControlLabel
              value="last3Days"
              control={<Radio checked={datePosted === 'last3Days'} />}
              label={<Typography sx={{ fontWeight: datePosted === 'last3Days' ? 'bold' : 'normal', fontSize: '14px' }}>Last 3 days</Typography>}
            />
            <FormControlLabel
              value="last7Days"
              control={<Radio checked={datePosted === 'last7Days'} />}
              label={<Typography sx={{ fontWeight: datePosted === 'last7Days' ? 'bold' : 'normal', fontSize: '14px' }}>Last 7 days</Typography>}
            />
          </RadioGroup>
        </FormControl>
      </AccordionDetails>
    </Accordion>
  );
};


export default DatePostedComponent;
