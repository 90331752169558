import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Tabs, Tab, Box, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import OTPPage from '../../../components/OTPBox/OTPPage';
import EmployerLoginSignUp from '../../../components/EmployerComponents/EmployerLoginSignup/EmployerLoginSignUp';
import signupVideo from '../../../assets/videos/videoplayback2.mp4';
import { signupEmployer, loginEmployer, verifySignupOtp, verifyOtp, resendOtp } from '../../../api/employerAuth';

const initialErrors = {
  loginEmail: '',
  loginPassword: '',
  signupName: '',
  signupEmail: '',
  signupPhone: '',
  signupCompany: '',
  otp: ''
};

export default function EmployerSignIn() {
  const navigate = useNavigate();
  const [value, setValue] = useState(0);
  const [formData, setFormData] = useState({
    loginEmail: '',
    loginPassword: '',
    signupName: '',
    signupEmail: '',
    signupPhone: '',
    signupCompany: '',
    otp: ''
  });
  const [errors, setErrors] = useState(initialErrors);
  const [showOtpBox, setShowOtpBox] = useState(false);
  const [timer, setTimer] = useState(30);
  const [canResend, setCanResend] = useState(false);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  useEffect(() => {
    let countdown;
    if (showOtpBox) {
      countdown = timer > 0 ? setInterval(() => setTimer(prev => prev - 1), 1000) :
        (setCanResend(true), setTimer(30));
    } else {
      setTimer(30);
      setCanResend(false);
    }
    return () => clearInterval(countdown);
  }, [showOtpBox, timer]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setErrors(initialErrors);
    setShowOtpBox(false);
  };

  const validateField = (name, value) => {
    const validationRules = {
      loginEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      signupEmail: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
      loginPassword: /.+/,
      signupName: /.+/,
      signupCompany: /.+/
    };
    const errorMessages = {
      loginEmail: 'Invalid email format',
      signupEmail: 'Invalid email format',
      loginPassword: 'Password is required',
      signupName: 'Full Name is required',
      signupCompany: 'Company Name is required'
    };
    return !value.match(validationRules[name]) ? errorMessages[name] : '';
  };

  const handleFieldChange = (name, value) => {
    setFormData(prev => ({ ...prev, [name]: value }));
    setErrors(prevErrors => ({ ...prevErrors, [name]: validateField(name, value) }));
  };

  const validateForm = (fields) => {
    const newErrors = fields.reduce((acc, field) => {
      acc[field] = validateField(field, formData[field]);
      return acc;
    }, {});
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  };

  const handleLoginSubmit = async () => {
    const fields = ['loginEmail', 'loginPassword'];
    if (validateForm(fields)) {
      try {
        const loginData = await loginEmployer(formData.loginEmail, formData.loginPassword);
        setFormData(prev => ({ ...prev, signupPhone: loginData.phone }));
        setShowOtpBox(true);
      } catch (error) {
        console.error('Login error:', error);
        setErrors(prev => ({ ...prev, loginEmail: 'Invalid credentials' }));
      }
    }
  };

  const handleSignUpSubmit = async () => {
    const fields = ['signupName', 'signupEmail', 'signupPhone'];

    if (validateForm(fields)) {
      try {
        const signupData = await signupEmployer(formData.signupName, formData.signupEmail, formData.signupPhone, formData.signupCompany);

        setFormData(prev => ({ ...prev, otp: signupData.otp }));
        setShowOtpBox(true);
      } catch (error) {
        console.error('Signup error:', error);
        setErrors(prev => ({ ...prev, signupEmail: 'Signup failed. Please try again.' }));
      }
    }
  };

  const handleOtpSubmit = async () => {
    if (formData.otp.length === 6) {
      try {
        if (value === 0) { // Login OTP verification
          const otpData = await verifyOtp(formData.loginEmail, formData.otp);
          const { organizationId, role, token } = otpData;

          localStorage.setItem('organizationId', organizationId);
          localStorage.setItem('role', role);
          localStorage.setItem('token', token);

          navigate('/employerJobListings');
        } else { // Signup OTP verification
          const otpData = await verifySignupOtp(formData.signupPhone, formData.otp);
          const { role, token } = otpData;

          localStorage.setItem('role', role);
          localStorage.setItem('token', token);

          navigate('/employerRegistration', {
            state: {
              signupEmail: formData.signupEmail,
              signupName: formData.signupName,
              signupPhone: formData.signupPhone
            }
          });
        }
      } catch (error) {
        console.error('OTP verification error:', error);
        setErrors(prev => ({ ...prev, otp: 'Invalid OTP' }));
      }
    } else {
      setErrors(prev => ({ ...prev, otp: 'OTP must be 6 digits' }));
    }
  };

  const handleResendOtp = async () => {
    try {
      await resendOtp(formData.signupPhone);
      setCanResend(false);
      setTimer(30);
    } catch (error) {
      console.error('Resend OTP error:', error);
    }
  };

  return (
    <Grid container sx={{ height: '100vh' }}>
      <Grid item xs={12} md={isSmallScreen ? 6 : 6}>
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
          <video autoPlay muted loop style={{ width: '100%', height: '100%', objectFit: 'cover' }}>
            <source src={signupVideo} type="video/mp4" />
          </video>
        </Box>
      </Grid>
      <Grid item xs={12} md={6}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height: '100%', justifyContent: 'center', p: 4 }}>
          {!showOtpBox ? (
            <>
              <Tabs value={value} onChange={handleChange} variant="fullWidth">
                <Tab label="Login" />
                <Tab label="Register" />
              </Tabs>
              <EmployerLoginSignUp
                handleFieldChange={handleFieldChange}
                errors={errors}
                value={value}
                loginEmail={formData.loginEmail}
                loginPassword={formData.loginPassword}
                handleLoginSubmit={handleLoginSubmit}
                handleSignupSubmit={handleSignUpSubmit}
                setValue={setValue}
                signupEmail={formData.signupEmail}
                signupName={formData.signupName}
                signupCompany={formData.signupCompany}
                signupPhone={formData.signupPhone}
              />
            </>
          ) : (
            <OTPPage
              handleBack={() => setShowOtpBox(false)}
              loginPhone={formData.loginPhone}
              signupPhone={formData.signupPhone}
              handleFieldChange={handleFieldChange}
              handleResendOtp={handleResendOtp}
              canResend={canResend}
              timer={timer}
              otp={formData.otp}
              errors={errors}
              handleOtpSubmit={handleOtpSubmit}
            />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
