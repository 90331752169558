// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./glanssans-regular.otf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./glanssans-bold.otf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* src/index.css */
@font-face {
    font-family: 'GlanceSansRegular';
    src: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) format('opentype');
    font-weight: normal;
    font-style: normal;
  }
  
  body {
    font-family: 'GlanceSansRegular', sans-serif;
  }
  /* src/index.css */
@font-face {
  font-family: 'GlanceSansBold';
  src: url(${___CSS_LOADER_URL_REPLACEMENT_1___}) format('opentype');
  font-weight: 600;
  font-style: normal;
}

body {
  font-family: 'GlanceSansBold', sans-serif;
}`, "",{"version":3,"sources":["webpack://./src/assets/fonts/fonts.css"],"names":[],"mappings":"AAAA,kBAAkB;AAClB;IACI,gCAAgC;IAChC,+DAAsD;IACtD,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,4CAA4C;EAC9C;EACA,kBAAkB;AACpB;EACE,6BAA6B;EAC7B,+DAAmD;EACnD,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yCAAyC;AAC3C","sourcesContent":["/* src/index.css */\n@font-face {\n    font-family: 'GlanceSansRegular';\n    src: url('./glanssans-regular.otf') format('opentype');\n    font-weight: normal;\n    font-style: normal;\n  }\n  \n  body {\n    font-family: 'GlanceSansRegular', sans-serif;\n  }\n  /* src/index.css */\n@font-face {\n  font-family: 'GlanceSansBold';\n  src: url('./glanssans-bold.otf') format('opentype');\n  font-weight: 600;\n  font-style: normal;\n}\n\nbody {\n  font-family: 'GlanceSansBold', sans-serif;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
