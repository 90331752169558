import React from 'react';
import { Box, Card, CardContent, Typography, Radio, Button } from '@mui/material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';
import offers from '../../../../assets/icons/logos/discount.png'


const JobSelectionCard = ({ title, price, originalPrice, credits, features = [], selected, onClick, recommended, limitedOffer }) => {
    return (
        <Card variant="outlined" sx={{ borderColor: selected ? 'primary.main' : 'grey.300', position: 'relative' }}>
            <CardContent>
                {recommended && (
                    <Button
                        color="primary"
                        size="small"
                        sx={{ position: 'relative' }}
                    >
                        Recommended
                    </Button>
                )}
                <Button
                    color="primary"
                    size="small"
                    sx={{ position: 'relative' }}
                >

                </Button>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1} mb={1}>
                    <Typography variant="h6">{title}</Typography>
                    <Radio
                        checked={selected}
                        onChange={onClick}
                        color="primary"
                    />
                </Box>
                <Typography variant="h4" mt={1}>{price}</Typography>
                {originalPrice && (
                    <Typography variant="body2" color="textSecondary" sx={{ textDecoration: 'line-through' }}>{originalPrice}</Typography>
                )}
                <Typography sx={{ borderBottom: '2px dashed', }} variant="body2" color="textSecondary" mt={1}>{credits}</Typography>
                <Box mt={2}>
                    {features.map((feature, index) => (
                        <Box display="flex" alignItems="center" key={index} mb={1}>
                            {feature.included ? (
                                <CheckCircleOutlineIcon color="success" sx={{ mr: 1 }} />
                            ) : (
                                <CancelIcon color="error" sx={{ mr: 1 }} />
                            )}
                            <Typography variant="body2" color={feature.included ? 'textPrimary' : 'textSecondary'}>
                                {feature.text} {feature?.icon}
                            </Typography>
                        </Box>
                    ))}
                </Box>
                {limitedOffer && (
                    <Box mt={2} sx={{ backgroundColor: '#e5e7eb', borderRadius: '4px' }}>
                        <Box display="flex" alignItems="center">
                            <img src={offers} height="30px" alt="offer icon" />
                            <Typography sx={{ borderBottom: '2px dashed', }} variant="body2" ml={1}><b>10 free</b> Database Credits</Typography>
                            
                        </Box>
                        <Typography variant="body2" color="error" mt={1}>Limited time offer</Typography>
                    </Box>
                )}
            </CardContent>
        </Card>
    );
};

export default JobSelectionCard;
