import {jwtDecode} from 'jwt-decode';

export const getUserIdFromToken = () => {
  // Retrieve the JWT token from local storage
  const authToken = localStorage.getItem('authToken');
  
  // Check if the token exists
  if (!authToken) {
    console.error('No auth token found in local storage');
    return null;
  }

  try {
    // Decode the JWT token
    const decoded = jwtDecode(authToken);
    
    // Extract userId from the decoded token
    const userId = decoded.id;

    // Store the userId in local storage
    localStorage.setItem('userId', userId);

    console.log('User ID stored in local storage:', userId);
    return userId;
  } catch (error) {
    console.error('Failed to decode JWT token or extract userId:', error);
    return null;
  }
}
