import React from 'react';
import { Box, TextField, Button, Typography } from '@mui/material';
import GoogleIconImg from '../../../assets/icons/logos/google-icon.png';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`tabpanel-${index}`}
            aria-labelledby={`tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

const EmployerLoginSignUp = ({ handleFieldChange, errors, value, loginEmail, loginPassword, handleLoginSubmit, handleSignupSubmit, setValue, signupEmail, signupName, signupCompany, signupPhone }) => {
    return (
        <>
            <TabPanel value={value} index={0}>
                <Typography variant="h4" gutterBottom>Hi, Welcome Back</Typography>
                <Box sx={{ maxWidth: 400, width: '100%' }}>
                    <TextField
                        fullWidth
                        placeholder="Email"
                        margin="normal"
                        sx={{ backgroundColor: 'white' }}
                        value={loginEmail}
                        onChange={(e) => handleFieldChange('loginEmail', e.target.value)}
                        error={!!errors.loginEmail}
                        helperText={errors.loginEmail}
                    />
                    <TextField
                        fullWidth
                        placeholder="Password"
                        type="password"
                        margin="normal"
                        sx={{ backgroundColor: 'white' }}
                        value={loginPassword}
                        onChange={(e) => handleFieldChange('loginPassword', e.target.value)}
                        error={!!errors.loginPassword}
                        helperText={errors.loginPassword}
                    />
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold', padding: '0.5rem' }}
                        onClick={handleLoginSubmit}
                    >
                        Login
                    </Button>
                </Box>
                <Typography align="center" variant="body2" sx={{ mt: 2 }}>or continue with</Typography>
                <Button
                    fullWidth
                    color="primary"
                    sx={{ mt: 1 }}
                >
                    <img src={GoogleIconImg} alt="google-logo" />
                </Button>
                <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                    Don't have an account? <Button onClick={() => setValue(1)}>Register here</Button>
                </Typography>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Typography variant="h4" gutterBottom>Register</Typography>
                <Box sx={{ maxWidth: 400, width: '100%' }}>
                    <TextField
                        fullWidth
                        placeholder="Full Name"
                        margin="normal"
                        sx={{ backgroundColor: 'white' }}
                        value={signupName}
                        onChange={(e) => handleFieldChange('signupName', e.target.value)}
                        error={!!errors.signupName}
                        helperText={errors.signupName}
                    />
                    <TextField
                        fullWidth
                        placeholder="Email"
                        margin="normal"
                        sx={{ backgroundColor: 'white' }}
                        value={signupEmail}
                        onChange={(e) => handleFieldChange('signupEmail', e.target.value)}
                        error={!!errors.signupEmail}
                        helperText={errors.signupEmail}
                    />
                    <TextField
                        fullWidth
                        placeholder="Phone"
                        margin="normal"
                        sx={{ backgroundColor: 'white' }}
                        value={signupPhone}
                        onChange={(e) => handleFieldChange('signupPhone', e.target.value)}
                        error={!!errors.signupPhone}
                        helperText={errors.signupPhone}
                    />
                   
                    <Button
                        fullWidth
                        variant="contained"
                        sx={{ backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold', padding: '0.5rem' }}
                        onClick={handleSignupSubmit}
                    >
                        Sign Up
                    </Button>
                </Box>
                <Typography align="center" variant="body2" sx={{ mt: 2 }}>or continue with</Typography>
                <Button
                    fullWidth
                    color="primary"
                    sx={{ mt: 1 }}
                >
                    <img src={GoogleIconImg} alt="google-logo" />
                </Button>
                <Typography align="center" variant="body2" sx={{ mt: 2 }}>
                    Already have an account? <Button onClick={() => setValue(0)}>Login here</Button>
                </Typography>
            </TabPanel>
        </>
    );
};

export default EmployerLoginSignUp;
