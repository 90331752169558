import React from 'react';
import EmployerDrawer from '../../../components/EmployerComponents/EmployerJobListingComponents/EmployerDrawer/EmployerDrawer';


const savedSearches = () => {
  return (
    <><EmployerDrawer /></>
  )
}

export default savedSearches