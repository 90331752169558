import React, { useState } from 'react';
import { Box, TextField, Typography, Grid, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const MultiSelect = ({ label, placeholder, selectedOptions, setSelectedOptions }) => {
  const [inputValue, setInputValue] = useState('');

  const handleAddOption = (option) => {
    if (!selectedOptions.includes(option) && option) {
      setSelectedOptions(prev => [...prev, option]);
      setInputValue('');
    }
  };

  const handleRemoveOption = (option) => {
    setSelectedOptions(prev => prev.filter(item => item !== option));
  };

  const handleInputChange = (event) => {
    const value = event.target.value;
    setInputValue(value);
    
    // Check if the input contains a comma
    if (value.includes(',')) {
      const newOptions = value.split(',').map(opt => opt.trim()).filter(opt => opt !== '');
      newOptions.forEach(opt => handleAddOption(opt));
      setInputValue('');
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>{label}</Typography>
      <TextField
        fullWidth
        placeholder={placeholder}
        value={inputValue}
        onChange={handleInputChange}
        variant="outlined"
        size="small"
        sx={{ marginBottom: 2 }}
        InputProps={{
          startAdornment: selectedOptions.map(option => (
            <Box
              key={option}
              sx={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 1,
                padding: '0 8px',
                borderRadius: 4,
                backgroundColor: '#e0e0e0',
                fontSize: '0.75rem'
              }}
            >
              {option}
              <IconButton
                size="small"
                onClick={() => handleRemoveOption(option)}
                sx={{ marginLeft: 0.5 }}
              >
                <CloseIcon fontSize="xs" />
              </IconButton>
            </Box>
          ))
        }}
      />
    </Box>
  );
};

export default MultiSelect;
