import React from 'react';
import { FormControlLabel, Checkbox } from '@mui/material';

const CustomCheckbox = ({ label, checked, onChange, name }) => {
  return (
    <FormControlLabel
      control={<Checkbox checked={checked} onChange={onChange} name={name} />}
      label={label}
      labelPlacement="end" // Label comes after the checkbox
      sx={{ display: 'block', }} // Reduce space below each checkbox
    />
  );
};

export default CustomCheckbox;
