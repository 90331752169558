import React from 'react';
import { Box, Button, Typography, IconButton } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { MuiOtpInput } from 'mui-one-time-password-input';

const JobSeekerOTPpage = ({
  handleBack,
  phoneNumber,
  handleFieldChange,
 
  otp,
  errors,
  handleOtpSubmit
}) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        p: 2,
        maxWidth: 400,
        margin: 'auto',
      }}
    >
      <IconButton onClick={handleBack} sx={{ alignSelf: 'flex-start', mb: 2 }}>
        <ArrowBackIcon />
      </IconButton>
      <Typography variant="h5" gutterBottom>
        Enter OTP
      </Typography>
      <Typography variant="body1" sx={{ mb: 2 }}>
        An OTP has been sent to {phoneNumber}
      </Typography>
      <MuiOtpInput
        length={6}
        value={otp}
        onChange={(value) => handleFieldChange('otp', value)}
        error={!!errors.otp}
        sx={{
          '& .MuiInputBase-root': {
            backgroundColor: 'white',
            width: { xs: '2.5rem', sm: '3rem' }, // Adjust width for different screen sizes
            height: { xs: '2.5rem', sm: '3rem' }, // Adjust height for different screen sizes
            margin: '0 0.2rem',
          }
        }}
        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
      />
      <Typography color="error" sx={{ mt: 1 }}>
        {errors.otp}
      </Typography>
      
      <Button
        fullWidth
        variant="contained"
        sx={{ mt: 2, backgroundColor: '#423bfa', color: 'white', fontWeight: 'bold' }}
        onClick={handleOtpSubmit}
      >
        Submit OTP
      </Button>
    </Box>
  );
};

export default JobSeekerOTPpage;
