import React, { useState } from 'react';
import { Card, CardContent, Typography, IconButton, Button, Box, Grid, Divider, Checkbox, Snackbar } from '@mui/material';
import { LocationOn, Work, Language, Phone, CopyAll } from '@mui/icons-material';
import DownhillSkiingIcon from '@mui/icons-material/DownhillSkiing';
import SchoolIcon from '@mui/icons-material/School';
import { styled } from '@mui/material/styles';
import avatarmen from '../../../assets/images/avatarmen.jpg';
import CandidateProfileDrawer from './CandidateProfileDrawer';
import { CopyToClipboard } from 'react-copy-to-clipboard';

const StyledCard = styled(Card)({
  maxWidth: 800,
  margin: 'auto',
  marginBottom: '20px', // To add some space between the cards
});

const StyledButton = styled(Button)({
  backgroundColor: '#4CAF50',
  '&:hover': {
    backgroundColor: '#45a049',
  },
});

const SkillChip = styled('span')(({ theme }) => ({
  backgroundColor: theme.palette.grey[200],
  padding: '2px 4px',
  margin: '0 2px',
  borderRadius: 4,
}));

const CandidateProfileCard = ({ profile }) => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [showContactDetails, setShowContactDetails] = useState(false);
  const [copySuccess, setCopySuccess] = useState(false);
  const [copiedText, setCopiedText] = useState('');

  const handleViewDetails = () => {
    setDrawerOpen(true);
  };

  const handleCloseDrawer = () => {
    setDrawerOpen(false);
  };

  const handleViewContactDetails = () => {
    setShowContactDetails(true);
  };

  const handleCopy = (text) => {
    setCopiedText(text);
    setCopySuccess(true);
  };

  const handleCloseSnackbar = () => {
    setCopySuccess(false);
    setCopiedText('');
  };

  return (
    <>
      <StyledCard variant="outlined">
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={1}>
              <Checkbox checked={profile.isSelected} />
            </Grid>
            <Grid item xs={2}>
              <IconButton>
                <img src={avatarmen} alt="Profile" style={{ width: '60%', borderRadius: '50%' }} />
              </IconButton>
            </Grid>
            <Grid item xs={9}>
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                  {profile.name}
                </Typography>
                <Typography
                  onClick={handleViewDetails}
                  sx={{ color: 'green', fontWeight: 'bold', cursor: 'pointer' }}
                  color="success"
                  variant="body2"
                >
                  View full Profile
                </Typography>
                <Typography variant="body2">{profile.experience} years</Typography>
              </Box>
              <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                <Typography variant="body2">{profile.workExperience[0].company}</Typography>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                  <LocationOn fontSize="small" /> {profile.location}
                </Typography>
              </Box>
              <Divider />
              <Box mt={2} mb={2}>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Work fontSize="small" /> <strong>Current / Latest: </strong>
                  <span style={{ color: 'blue' }}>{profile.workExperience[0].position}</span>
                </Typography>
                <Typography variant="body2" mb={2}>
                  <SchoolIcon fontSize="small" /> <strong>Education: </strong> {profile.education.map((edu, index) => (
                    <span key={index}>{edu.degree} {edu.institution && `at ${edu.institution}`}</span>
                  ))}
                </Typography>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <LocationOn fontSize="small" /> <strong>Pref. Location: </strong> {profile.preferredLocations.join(', ')}
                </Typography>
                <Typography variant="body2" mb={2}>
                  <DownhillSkiingIcon fontSize="small" /> <strong>Skills: </strong>
                  {profile.skills.map((skill, index) => (
                    <SkillChip key={index}>{skill}</SkillChip>
                  ))}
                </Typography>
                <Typography variant="body2" sx={{ display: 'flex', alignItems: 'center' }}>
                  <Language fontSize="small" /> <strong>Languages: </strong> {profile.languages.join(', ')}
                </Typography>
              </Box>
              <Box mt={2} display="flex" alignItems="center">
                {!showContactDetails ? (
                  <StyledButton variant="contained" startIcon={<Phone />} onClick={handleViewContactDetails}>
                    View Contact Details
                  </StyledButton>
                ) : (
                  <>
                    <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{profile.phone}</Typography>
                    <CopyToClipboard text={profile.phone} onCopy={() => handleCopy('Phone number copied')}>
                      <IconButton sx={{ ml: 1 }}>
                        <CopyAll />
                      </IconButton>
                    </CopyToClipboard>
                    <Typography sx={{ color: 'green', fontWeight: 'bold' }}>{profile.email}</Typography>
                    <CopyToClipboard text={profile.email} onCopy={() => handleCopy('Email id copied')}>
                      <IconButton sx={{ ml: 1 }}>
                        <CopyAll />
                      </IconButton>
                    </CopyToClipboard>
                  </>
                )}
              </Box>
              <Typography variant="body2" color="textSecondary" mt={1}>
                No credits will be used for viewing phone number
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
        <Divider />
        <Box display="flex" justifyContent="space-between" alignItems="center" p={2} bgcolor="#f5f5f5">
          <Typography variant="body2">67 unlocks</Typography>
          <Typography variant="body2">Profile unlocked by me, yesterday</Typography>
          <Typography variant="body2">Active on 22 Jul '24</Typography>
        </Box>
      </StyledCard>
      <CandidateProfileDrawer 
        profile={profile} 
        open={drawerOpen} 
        onClose={handleCloseDrawer} 
        showContactDetails={showContactDetails}
        handleViewContactDetails={handleViewContactDetails}
        handleCopy={handleCopy}
      />
      <Snackbar
        open={copySuccess}
        autoHideDuration={2000}
        onClose={handleCloseSnackbar}
        message={copiedText}
      />
    </>
  );
};

export default CandidateProfileCard;
