import React from 'react';
import { Container, Box} from '@mui/material';
import EmployerCaseZeroDetails from '../EmployerCaseZero/EmployerCaseZeroDetails';
import EmployerCaseOneDetails from '../EmployerCaseOne/EmployerCaseOneDetails';
import EmployerCaseTwoDetails from '../EmployerCaseTwo/EmployerCaseTwoDetails';

const EmployerCaseThreeDetails = ({ jobData, handleNext }) => {
  return (
    <Container>
      <Box sx={{ paddingTop: 2 }}>
        <EmployerCaseZeroDetails CaseZeroJobData={jobData}/>
        <EmployerCaseOneDetails CaseOneJobData={jobData}/>
        <EmployerCaseTwoDetails CaseTwoJobData={jobData}/>
      
        
      </Box>
    </Container>
  );
};

export default EmployerCaseThreeDetails;
