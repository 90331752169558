import React, { useState, useRef } from 'react';
import { Container, Box, Typography, Grid, Tabs, Tab, Card, CardContent, CardActionArea, Avatar, Button, useMediaQuery, useTheme, IconButton } from '@mui/material';
import { red } from '@mui/material/colors';
import CardHeader from '@mui/material/CardHeader';
import mobileVideo from '../../../assets/videos/videoplayback2.mp4';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import dummyProfilePhoto from '../../../assets/images/avatarmen.jpg';
import dummyProfilePhotoWomen from '../../../assets/images/avatarwomen.jpg';

import dummyVoice from '../../../assets/audios/introduction.mp3';
import dummyCV from '../../../assets/docs/dummyPdf.pdf';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

import Stack from '@mui/material/Stack';


import EmployerDrawer from '../EmployerJobListingComponents/EmployerDrawer/EmployerDrawer';

const jobSeekers = [
    { name: "Jenifer Fernandis", role: "System Administrator", type: "video" },
    { name: "Lara Haifa", role: "Creative Head", type: "voice" },
    { name: "Stiven Ceron", role: "System Administrator", type: "cv" },
    { name: "Kim Soha", role: "Document Head", type: "video" },
    { name: "Kim Saas", role: "Frontend Developer", type: "voice" },
    { name: "Stiven Ced", role: "System Manager", type: "cv" },
    { name: "Maria Bowman", role: "Web Designer", type: "video" },
    { name: "Doris Stewart", role: "Web Developer", type: "voice" },
    { name: "Atven Ced", role: "System Admin", type: "cv" },
    { name: "Samantha Blake", role: "Data Scientist", type: "video" },
    { name: "James Smith", role: "Backend Developer", type: "voice" },
    { name: "Emily Davis", role: "Graphic Designer", type: "cv" },
];

const JobSeekerProfile = () => {
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [tabIndex, setTabIndex] = useState(0);
    const [playingVoice, setPlayingVoice] = useState(null);
    const [playingVideo, setPlayingVideo] = useState({});
    const [itemsToShow, setItemsToShow] = useState(4); // Initially show 4 items per tab
    const audioRefs = useRef([]);
    const videoRefs = useRef([]);

    const handleTabChange = (event, newValue) => {
        setTabIndex(newValue);
        setItemsToShow(4); // Reset the number of items to show when changing tabs
    };

    const handlePlayVoice = (index) => {
        if (playingVoice !== null && audioRefs.current[playingVoice]) {
            audioRefs.current[playingVoice].pause();
        }
        audioRefs.current[index].play();
        setPlayingVoice(index);
    };

    const handlePlayVideo = (index) => {
        const video = videoRefs.current[index];
        if (video.paused) {
            video.play();
            setPlayingVideo({ ...playingVideo, [index]: true });
        } else {
            video.pause();
            setPlayingVideo({ ...playingVideo, [index]: false });
        }
    };

    const filteredJobSeekers = jobSeekers.filter((jobSeeker) => {
        if (tabIndex === 0) return jobSeeker.type === 'video';
        if (tabIndex === 1) return jobSeeker.type === 'voice';
        if (tabIndex === 2) return jobSeeker.type === 'cv';
        return true;
    });

    const loadMoreItems = () => {
        setItemsToShow((prev) => prev + 4);
    };

    return (
        <>
<EmployerDrawer>
            <Container maxWidth="xl">
                <Box sx={{ py: 2, width: '100%' }}>
                    <Typography variant={isSmallScreen ? 'h6' : 'h4'} component="h1" gutterBottom sx={{ color: '#000', fontWeight: 'bold', textAlign: 'center' }}>
                        Job Seeker's Profiles
                    </Typography>
                    <Tabs sx={{ mt: 4 }} value={tabIndex} onChange={handleTabChange} centered>
                        <Tab label="Video Profile" />
                        <Tab label="Voice Profile" />
                        <Tab label="CV Profile" />
                    </Tabs>
                    <Container sx={{ backgroundColor: '#d6d6d6'}}>
                        <Grid container spacing={4} sx={{ mt: 2 }}>
                            {filteredJobSeekers.slice(0, itemsToShow).map((jobSeeker, index) => (
                                <Grid item xs={12} sm={6} md={3} key={jobSeeker.name}>
                                    <Card sx={{ height: '100%', backgroundColor: '#f5f5f5', borderRadius: '1rem', boxShadow: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', textAlign: 'center' }}>
                                        <CardActionArea sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                            <CardHeader
                                                avatar={<Avatar sx={{ bgcolor: red[500] }}>{jobSeeker.name.charAt(0)}</Avatar>}
                                                title={jobSeeker.name}
                                                subheader={jobSeeker.role}
                                            />
                                            {tabIndex === 0 && (
                                                <>
                                                    <Box sx={{ position: 'relative', width: '100%', height: 400 }}>
                                                        <video
                                                            ref={(el) => (videoRefs.current[index] = el)}
                                                            src={mobileVideo}
                                                            style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                                            onClick={() => handlePlayVideo(index)}
                                                        />
                                                        <IconButton
                                                            sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', color: 'white', fontSize: 64 }}
                                                            onClick={() => handlePlayVideo(index)}
                                                        >
                                                            {playingVideo[index] ? <PauseCircleOutlineIcon fontSize="inherit" /> : <PlayCircleOutlineIcon fontSize="inherit" />}
                                                        </IconButton>
                                                    </Box>
                                                    <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                                                        <Stack direction="row" spacing={2}>
                                                            <Button
                                                                sx={{ mb: 4 }}
                                                                
                                                                startIcon={<DownloadIcon />}
                                                                href={mobileVideo}
                                                                download="video_profile.mp4"
                                                            >
                                                                Download Video CV
                                                            </Button>
                                                        </Stack>
                                                    </CardContent>
                                                </>
                                            )}

                                            {tabIndex === 1 && (
                                                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 4, height: 400 }}>
                                                    <Avatar src={dummyProfilePhoto} sx={{ width: 232, height: 232, mb: 6 }} />
                                                    <Button
                                                        sx={{ mb: 2 }}
                                                        v
                                                        startIcon={<PlayCircleOutlineIcon />}
                                                        onClick={() => handlePlayVoice(index)}
                                                    >
                                                        Play Voice CV
                                                    </Button>
                                                    <audio
                                                        ref={(el) => (audioRefs.current[index] = el)}
                                                        src={dummyVoice}
                                                        controls
                                                        style={{ display: playingVoice === index ? 'block' : 'none' }}
                                                    />
                                                    <Button
                                                        
                                                        startIcon={<DownloadIcon />}
                                                        href={dummyVoice}
                                                        download="voice_profile.mp3"
                                                    >
                                                        Download Voice CV
                                                    </Button>
                                                </CardContent>
                                            )}
                                            {tabIndex === 2 && (
                                                <CardContent sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', mb: 4, height: 400 }}>
                                                    <Avatar src={dummyProfilePhotoWomen} sx={{ width: 232, height: 232, mb: 6 }} />
                                                    <Button
                                                        sx={{ mb: 4 }}
                                                        v
                                                        startIcon={<RemoveRedEyeIcon />}
                                                        href={dummyCV}
                                                        target="_blank"
                                                    >
                                                        View CV
                                                    </Button>
                                                    <Button
                                                        v
                                                        startIcon={<DownloadIcon />}
                                                        href={dummyCV}
                                                        download="dummy_CV.pdf"
                                                    >
                                                        Download CV
                                                    </Button>
                                                </CardContent>
                                            )}
                                        </CardActionArea>
                                    </Card>
                                </Grid>
                            ))}
                        </Grid>
                        {itemsToShow < filteredJobSeekers.length && (
                            <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
                                <Button variant="contained" onClick={loadMoreItems}>
                                    View More
                                </Button>
                            </Box>
                        )}
                    </Container>
                </Box>
            </Container>
          
            </EmployerDrawer>   
        </>
    );
};

export default JobSeekerProfile;
