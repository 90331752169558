import React from 'react';
import { TextField, Autocomplete } from '@mui/material';

const CustomAutocomplete = ({ placeholder, options, value, onChange }) => {
  // Ensure value is always handled correctly
  const handleChange = (event, newValue) => {
    onChange(newValue);
  };

  return (
    <Autocomplete
      size="small"
      fullWidth
      options={options}
      value={value || null} // Ensure the value is null if it's not available in options
      onChange={handleChange}
      renderInput={(params) => (
        <TextField
          {...params}
          placeholder={placeholder}
          InputProps={{
            ...params.InputProps,
          }}
        />
      )}
    />
  );
};

export default CustomAutocomplete;
