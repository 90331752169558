import React, { useEffect, useState } from 'react';
import { Box, Button } from '@mui/material';
import EmployerCaseThreeDetails from './EmployerCaseThreeDetails';
import baseURL from '../../../../api/baseUrl';

const EmployerCaseThree = ({ handleNext, formData, handleBack }) => {
    const [jobData, setJobData] = useState(null);



    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(`${baseURL}/jobpost/update/page4`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ jobId: formData?.jobId }),
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const data = await response.json();
                setJobData(data);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, [formData?.jobId]);


    return (
        <Box component="form" sx={{ '& .MuiTextField-root': { m: 1, width: '100%' }, p: 2 }} noValidate autoComplete="off">
            <EmployerCaseThreeDetails jobData={jobData} />
            <Box sx={{ display: "flex", justifyContent: "space-between",mt: 3 }}>
        <Button
          type="submit"
          variant="contained"
          color="inherit"

          onClick={handleBack}
          sx={{
            backgroundColor: "#423BFA",
            color: "#fff",
            borderRadius: 6,
            px: 3,
            py: 1.5,
            "&:hover": {
              backgroundColor: "#372FD1",
            },
          }}
        >
          Back
        </Button>
                <Button
                onClick={handleNext}
                    variant="contained"
                    sx={{
                        backgroundColor: "#423BFA",
                        color: "#fff",
                        borderRadius: 6,
                        px: 3,
                        py: 1.5,
                        "&:hover": {
                            backgroundColor: "#372FD1",
                        },
                    }}
                >
                    Save & Next
                </Button>
            </Box>
        </Box>
    );
};

export default EmployerCaseThree;
