// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.profile-form-container {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.MuiInput-root {
  background-color: white;
  border-radius: 8px;
  padding: 10px;
}

.MuiButton-contained {
  background-color: #1976d2;
}

.MuiButton-outlined {
  border-color: #1976d2;
}
`, "",{"version":3,"sources":["webpack://./src/components/JobSeekerComponents/JobSeekerRegistrationCases/JobSeekerCaseZero/JobSeekerCaseZero.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,aAAa;EACb,mBAAmB;EACnB,yCAAyC;AAC3C;;AAEA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,qBAAqB;AACvB","sourcesContent":[".profile-form-container {\n  background-color: #f9f9f9;\n  padding: 20px;\n  border-radius: 10px;\n  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);\n}\n\n.MuiInput-root {\n  background-color: white;\n  border-radius: 8px;\n  padding: 10px;\n}\n\n.MuiButton-contained {\n  background-color: #1976d2;\n}\n\n.MuiButton-outlined {\n  border-color: #1976d2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
